import React from "react";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import Box from "ui/Box";
import Button from "ui/Button";
import Text, { fontSizeStyles } from "ui/Text";
import Modal from "ui/Modal";
import TestConversationModal from "./testConversationModal";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { useAppData } from "components/AppData";
import BotPlanPickerModal from "./botPlanPickerModal";
import PublishModal from "./publishModal";
import IconButton from "ui/IconButton";
import IdeasModal from "./ideasModal";

const Textarea = styled(TextareaAutosize)`
  width: 100%;
  outline: none;
  border: none;
  resize: none;
  appearance: none;

  font-size: ${fontSizeStyles["text_lg"].fontSize}px;
  line-height: ${fontSizeStyles["text_lg"].lineHeight};

  ${(props) => props.theme.mediaQueries.mobile} {
    font-size: ${fontSizeStyles["text_md"].fontSize}px;
    line-height: ${fontSizeStyles["text_md"].lineHeight};
  }
`;

export default function PromptComposer(
  props: RouteComponentProps<{ automatedSequenceId: string }>
) {
  const appData = useAppData();
  const history = useHistory();
  const [ideasModalOpen, setIdeasModalOpen] = React.useState(false);
  const [botPlanPickerModalOpen, setBotPlanPickerModalOpen] =
    React.useState(false);
  const [publishModalOpen, setPublishModalOpen] = React.useState(false);

  const sequence =
    appData.automatedMessagingSequences[props.match.params.automatedSequenceId];

  const sequenceGPTStep = React.useMemo(() => {
    const sequenceStep = sequence?.data().steps[0];
    if (sequenceStep?.type === "gpt") {
      return sequenceStep;
    }

    return null;
  }, [sequence]);

  const [testConversationModalOpen, setTestConversationModalOpen] =
    React.useState(false);

  const [prompt, setPrompt] = React.useState(
    sequenceGPTStep?.promptMessages[0]?.content ?? ""
  );

  if (sequenceGPTStep === null) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <IdeasModal
        open={ideasModalOpen}
        onOpenChange={() => setIdeasModalOpen(false)}
      />
      <TestConversationModal
        open={testConversationModalOpen}
        promptMessage={prompt}
        automatedSequenceId={props.match.params.automatedSequenceId}
        onOpenChange={() => setTestConversationModalOpen(false)}
      />
      <BotPlanPickerModal
        open={botPlanPickerModalOpen}
        onOpenChange={() => setBotPlanPickerModalOpen(false)}
        automatedSequenceId={props.match.params.automatedSequenceId}
      />

      <PublishModal
        open={publishModalOpen}
        onOpenChange={() => setPublishModalOpen(false)}
      />
      <Box cols={1} flex direction="column">
        <Box
          padding="1rem"
          flex
          alignItems="center"
          justifyContent="space-between"
          borderBottom
        >
          <Box flex alignItems="center">
            <IconButton icon="arrowLeft" onClick={() => history.push("/ai")} />
            <Text size="text_xl" weight="semibold" marginLeft="0.5rem">
              {sequence.data().name}
            </Text>
          </Box>

          <Box flex>
            <Button
              size="sm"
              kind="secondary"
              icon="messageChatIcon"
              onClick={() => setTestConversationModalOpen(true)}
            >
              Probar
            </Button>

            <Button
              size="sm"
              kind="secondary"
              icon="rocket"
              // onClick={() => setBotPlanPickerModalOpen(true)}
              onClick={() => setPublishModalOpen(true)}
              style={{ marginLeft: 12 }}
            >
              Publicar
            </Button>
          </Box>
        </Box>

        <Box
          padding="1rem"
          flex
          direction="column"
          cols={1}
          style={{ minHeight: 0, overflow: "auto" }}
        >
          <Textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
          <Button
            kind="secondary"
            size="sm"
            style={{ alignSelf: "flex-end" }}
            icon="lightbulb"
            onClick={() => setIdeasModalOpen(true)}
          >
            Ideas
          </Button>
        </Box>
      </Box>
    </>
  );
}
