import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Box from "./Box";
import Icon from "./Icon";

export const Breadcrumbs = styled(Box)`
  display: flex;
  align-items: center;
`;

export const BreadcrumbLink = styled(Link)`
  color: ${(props) => props.theme.colors.gray600};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.colors.gray800};
  }
`;

export const BreadcrumbCurrentPage = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary700};
  font-size: 14px;
  line-height: 20px;
`;

export const BreadcrumbChevron = () => {
  return (
    <Box marginRight="0.75rem" marginLeft="0.75rem">
      <Icon icon="chevronRight" />
    </Box>
  );
};
