import React from "react";

const FileImage = ({
  file,
  alt,
  ...restProps
}: { file: File } & Exclude<
  React.ImgHTMLAttributes<HTMLImageElement>,
  "src"
>) => {
  const [imageData, setImageData] = React.useState<string>();

  React.useEffect(() => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageData(e.target?.result as string | undefined);
    };
    reader.readAsDataURL(file);
  }, [file]);

  return <img src={imageData ?? ""} alt={alt} {...restProps} />;
};

export default FileImage;
