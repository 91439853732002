import React from "react";
import styled, { css, useTheme } from "styled-components";
import dayjs from "dayjs";
import ProfilePic, { ChannelBadge } from "components/ProfilePic";
import {
  Message as MessageType,
  Contact,
  WhatsappMessage,
  EmailTextMessage,
  TextMessage as TextMessageType,
  StoryMentionMessage as StoryMentionMessageType,
  FileMessage,
  VideoMessage as VideoMessageType,
  WorkspaceMember,
} from "common/src/types";
import Text from "ui/Text";
import FBImage, { FBVideo } from "components/FBImage";
import AudioPlayer from "components/AudioPlayer";
import Icon, { IconKey } from "ui/Icon";
import { storage } from "lib/db";
import Box from "ui/Box";
import { useAppData } from "components/AppData";
import { useImagePreview } from "lib/imagePreview";
import prettyBytes from "pretty-bytes";
import { EmojiRenderer } from "ui/emojis";
import IconButton from "ui/IconButton";
import { useAuth } from "lib/auth";
import ContactFormModal from "components/contacts/ContactFormModal";
import { ReactComponent as LogoSquare } from "assets/logo-square.svg";

const StoryPreviewWrapper = styled.div`
  display: flex;
  margin-bottom: 0.5rem;

  .line {
    width: 3px;
    background-color: ${(props) => props.theme.colors.gray200};
    margin-right: 0.5rem;
    border-radius: 4px;
  }
`;

const StoryImage = styled(FBImage)`
  border-radius: 12px;
  width: 112px;
  height: 199px;
  cursor: pointer;
`;

const StoryVideoWrapper = styled.div`
  border-radius: 12px;
  overflow: hidden;
  width: 112px;
  height: 199px;
`;

const MessageActionsWrapper = styled.div<{
  direction: MessageType["direction"];
}>`
  position: absolute;
  top: 0;
  bottom: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  display: flex;
  align-items: center;

  ${(props) =>
    props.direction === "incoming" &&
    css`
      right: 0;
      transform: translateX(100%);
    `}

  ${(props) =>
    props.direction === "outgoing" &&
    css`
      left: 0;
      transform: translateX(-100%);
    `}
`;

export const MessageBubble = styled.div<{
  isFirstOfGroup?: boolean;
  isLastOfGroup?: boolean;
  direction?: MessageType["direction"];
}>`
  padding: 10px 14px;
  ${(props) => {
    if (props.isFirstOfGroup && props.isLastOfGroup)
      return "border-radius: 18px;";

    if (props.direction === "incoming") {
      if (props.isFirstOfGroup) return "border-radius: 18px 18px 18px 4px;";
      if (props.isLastOfGroup) return "border-radius: 4px 18px 18px 18px;";

      return "border-radius: 4px 18px 18px 4px;";
    }

    if (props.direction === "outgoing") {
      if (props.isFirstOfGroup) return "border-radius: 18px 18px 4px 18px;";
      if (props.isLastOfGroup) return "border-radius: 18px 4px 18px 18px;";

      return "border-radius: 18px 4px 4px 18px;";
    }

    return null;
  }}

  ${(props) => props.theme.mediaQueries.mobile} {
    max-width: 80vw;
  }

  background-color: ${(props) => props.theme.colors.gray100};
  position: relative;

  ${MessageActionsWrapper} {
    opacity: 0;
  }

  &:hover {
    ${MessageActionsWrapper} {
      opacity: 1;
    }
  }
`;

const ImageMessageImage = styled(FBImage)`
  width: 400px;
  height: 400px;
  object-fit: cover;
`;

const StickerImage = styled(FBImage)`
  width: 75px;
  height: 75px;
`;

export const MessageWrapper = styled.div<{
  direction: WhatsappMessage["direction"];
  isFirstOfGroup?: boolean;
  isLastOfGroup?: boolean;
}>`
  display: flex;
  max-width: 35rem;
  margin-bottom: 4px;
  align-self: flex-start;

  ${(props) =>
    props.direction === "outgoing" &&
    css`
      align-self: flex-end;
      flex-direction: row-reverse;

      ${MessageBubble} {
        background-color: ${props.theme.colors.primary100};
        color: ${props.theme.colors.primary800};
      }
    `}

  ${(props) =>
    props.isFirstOfGroup &&
    css`
      margin-top: 8px;
    `}

    ${(props) =>
    props.isLastOfGroup &&
    css`
      margin-bottom: 8px;
    `}
`;

const MessageProfilePicContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const MessageProfilePic = styled(ProfilePic)`
  grid-column: 1;
  grid-row: 1/3;
`;

const ZamiProfilePicWrapper = styled.div`
  grid-column: 1;
  grid-row: 1/3;
  display: flex;
  position: relative;
`;

const MessageContents = styled.div`
  padding: 0px 1rem;
  flex: 1;
`;

const MessageHeaderWrapper = styled.div<{
  direction?: MessageType["direction"];
}>`
  display: flex;
  ${(props) => props.direction === "outgoing" && "justify-content: end;"}
`;

const MessageFooterWrapper = styled(MessageHeaderWrapper)`
  margin-top: 4px;
  padding-left: 12px;
`;

const MessageStatusWrapper = styled.div`
  margin-left: 0.5rem;
`;

const ReplyReferenceWrapper = styled.div<{
  direction: NonNullable<MessageType["direction"]>;
}>`
  border-radius: 8px;
  padding: 10px 1rem;
  position: relative;
  margin-bottom: 0.5rem;
  overflow: hidden;
  background-color: ${(props) =>
    props.direction === "incoming"
      ? props.theme.colors.gray200
      : "rgba(185, 222, 209, 0.32)"};

  .inner-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-left: solid 4px ${(props) => props.theme.colors.primary500};
  }
`;

const ReplyImage = styled(FBImage)`
  height: 68px;
  width: 68px;
  margin: -10px -1rem;
  margin-left: 1rem;
  object-fit: cover;
`;

const StoryVideo = ({
  contentType,
  location,
}: {
  contentType: string;
  location: string;
}) => {
  return (
    <StoryVideoWrapper>
      <FBVideo location={location} contentType={contentType} />
    </StoryVideoWrapper>
  );
};

const MessageReplyReference = ({
  replyToMessage,
  direction,
  contact,
}: {
  direction: NonNullable<MessageType["direction"]>;
  contact: Contact;
  replyToMessage: MessageType;
}) => {
  const appData = useAppData();

  let senderName = "Desde WhatsApp";

  if (replyToMessage.direction === "incoming") {
    senderName = contact.name;
  } else if (
    replyToMessage.direction === "outgoing" &&
    replyToMessage.workspaceAuthorId &&
    appData.workspaceMembers[replyToMessage.workspaceAuthorId]
  ) {
    senderName =
      appData.workspaceMembers[replyToMessage.workspaceAuthorId].data()!.name;
  }

  return (
    <ReplyReferenceWrapper direction={direction}>
      <Box flex>
        <Box cols={1}>
          <Text size="text_md" weight="semibold" color="primary600">
            {senderName}
          </Text>

          <Text size="text_md" color="gray600">
            {replyToMessage.message?.type === "text" && (
              <div>{replyToMessage.message.body}</div>
            )}

            {replyToMessage.message?.type === "file" && (
              <Box flex alignItems="center">
                <Icon icon="file4" size={14} fill="gray500" />
                <Text size="text_sm" marginLeft="0.25rem">
                  {replyToMessage.message.fileName}
                </Text>
              </Box>
            )}

            {replyToMessage.message?.type === "image" && (
              <Box flex alignItems="center" marginTop="0.25rem">
                <Icon size={14} icon="camera" fill="gray500" />
                <Text size="text_sm" marginLeft="0.25rem">
                  Photo
                </Text>
              </Box>
            )}

            {!["file", "text", "image"].includes(
              replyToMessage.message?.type || ""
            ) && <div>{replyToMessage.message?.type}</div>}
          </Text>
        </Box>

        {replyToMessage.message?.type === "image" && (
          <ReplyImage location={replyToMessage.message.imageLocation} />
        )}
      </Box>
      <div className="inner-wrapper" />
    </ReplyReferenceWrapper>
  );
};

const TextMessage = ({
  message,
}: {
  message: TextMessageType | EmailTextMessage;
}) => {
  const lines = React.useMemo(() => message.body.split("\n"), [message]);
  const imagePreview = useImagePreview();
  return (
    <>
      {message.replyTo?.instagramStory && (
        <div>
          <Text size="text_sm" color="gray600" marginBottom="0.5rem">
            Respondió a tu historia
          </Text>
          <StoryPreviewWrapper>
            <div className="line" />
            {message.replyTo.instagramStory.contentType?.includes("video") ? (
              <StoryVideo
                contentType={message.replyTo.instagramStory.contentType}
                location={message.replyTo.instagramStory.imageLocation}
              />
            ) : (
              <StoryImage
                location={message.replyTo.instagramStory.imageLocation}
                onClick={() => {
                  if (message.replyTo?.instagramStory?.imageLocation)
                    imagePreview.setPreview({
                      type: "image",
                      imageLocation:
                        message.replyTo.instagramStory.imageLocation!,
                    });
                }}
              />
            )}
          </StoryPreviewWrapper>
        </div>
      )}
      {lines.map((line, i) => {
        if (line) {
          return (
            <Text size="text_md" key={i}>
              <EmojiRenderer>{line}</EmojiRenderer>
            </Text>
          );
        }

        if (i !== lines.length - 1) {
          return <span key={i}>&nbsp;</span>;
        }

        return null;
      })}
    </>
  );
};

const StoryMentionMessage = ({
  message,
}: {
  message: StoryMentionMessageType;
}) => {
  return (
    <>
      <Text size="text_sm" color="gray600" marginBottom="0.5rem">
        Te mencionó en una historia
      </Text>
      <StoryPreviewWrapper>
        <div className="line" />
        <StoryImage location={message.storyLocation} />
      </StoryPreviewWrapper>
    </>
  );
};

const FileMessageWrapper = styled.div`
  border: solid 1px rgba(0, 0, 0, 0.05);
  background-color: white;
  cursor: pointer;
  border-radius: 12px;
  align-self: flex-start;
  padding: 0.75rem;
  display: flex;
  align-items: center;

  &:hover {
    .filename {
      text-decoration: underline;
    }
  }
`;

const FileIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.primary100};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
`;

const FileMessageComponent = ({ message }: { message: FileMessage }) => {
  const handleDownload = async () => {
    window.open(await storage.ref(message.fileLocation).getDownloadURL());
  };

  return (
    <FileMessageWrapper onClick={handleDownload}>
      <FileIconWrapper>
        <Icon icon="file4" />
      </FileIconWrapper>

      <Box>
        <Text className="filename" size="text_sm" weight="semibold">
          {message.fileName}
        </Text>
        <Text size="text_sm" weight="regular" color="gray600">
          {prettyBytes(message.fileSize)}
        </Text>
      </Box>
    </FileMessageWrapper>
  );
};

const DeletedMessage = () => {
  return (
    <Box flex alignItems="center">
      <Icon icon="blocked" />
      <Text size="text_md" marginLeft="0.5rem">
        Este mensaje fue eliminado.
      </Text>
    </Box>
  );
};

const ContactMessage = ({
  message,
}: {
  message: NonNullable<MessageType["message"]>;
}) => {
  const theme = useTheme();
  const auth = useAuth();
  const [open, setOpen] = React.useState(false);

  if (message.type === "contact") {
    return (
      <>
        <ContactFormModal
          contact={{
            name: message.name,
            phone: message.phone,
            lastActivityAt: Math.trunc(Date.now() / 1000),
            workspaceId: auth.workspaceId!,
            assignee: "",
          }}
          workspaceId={auth.workspaceId}
          open={open}
          onOpenChange={setOpen}
        />
        <Box>
          <Box
            flex
            alignItems="center"
            padding="0px 14px 10px 14px"
            marginLeft="-14px"
            marginRight="-14px"
            style={{ borderBottom: `solid 1px ${theme.colors.gray200}` }}
          >
            <ProfilePic
              id={message.name}
              name={message.name}
              imageLocation={message.profilePictureLocation}
              size="md"
            />

            <Text size="text_md" weight="semibold" marginLeft="0.5rem">
              {message.name}
            </Text>
          </Box>
          <Box
            padding="10px 0px 0px 0px"
            flex
            justifyContent="center"
            style={{ cursor: "pointer" }}
            onClick={() => setOpen(true)}
          >
            <Text size="text_sm" weight="semibold" color="primary700">
              Agregar contacto
            </Text>
          </Box>
        </Box>
      </>
    );
  }

  return null;
};

function VideoMessage(props: {
  message: VideoMessageType;
  contact: { id: string; data: Contact } | undefined;
  workspaceMember: { id: string; data: WorkspaceMember } | undefined;
  timestamp: number;
}) {
  const preview = useImagePreview();

  if (props.message?.type === "video") {
    return (
      <Box style={{ cursor: "pointer" }}>
        <FBVideo
          contentType={props.message.videoMimetype}
          controls={false}
          location={props.message.videoLocation}
          width={400}
          height={400}
          style={{ objectFit: "cover" }}
          onClick={() => {
            if (props.message?.type === "video") {
              preview.setPreview({
                type: "video",
                videoMimetype: props.message.videoMimetype,
                videoLocation: props.message.videoLocation,
                contact: props.contact,
                workspaceMember: props.workspaceMember,
                messageTimestamp: props.timestamp,
              });
            }
          }}
        />
      </Box>
    );
  }
  return null;
}

const MessageStatus = ({ message }: { message: MessageType }) => {
  const icon = React.useMemo(() => {
    if (
      message.channel === "instagram" &&
      message.workspaceAuthorId === undefined &&
      message.direction === "outgoing"
    ) {
      return "messageStatusSent";
    }

    if (
      message.channel === "whatsapp_web" &&
      message.workspaceAuthorId === undefined &&
      message.direction === "outgoing"
    ) {
      return "messageStatusDelivered";
    }

    if (message.deliveredAt ?? message.readAt) {
      return "messageStatusDelivered";
    }

    if (message.sentAt) {
      return "messageStatusSent";
    }

    return "messageStatusPending";
  }, [message]);

  return (
    <MessageStatusWrapper>
      <Icon icon={icon} fill={message.readAt ? "cyan500" : undefined} />
    </MessageStatusWrapper>
  );
};

const Message = ({
  message,
  contact,
  onReplyClicked,
  isFirstOfGroup,
  isLastOfGroup,
}: {
  message: MessageType;
  contact: Contact;
  onReplyClicked?: () => void;
  isFirstOfGroup: boolean;
  isLastOfGroup: boolean;
}) => {
  const appData = useAppData();
  const imagePreview = useImagePreview();

  const messageTimeString = React.useMemo(() => {
    const messageDate = dayjs.unix(message.timestamp);
    return messageDate.format("h:mm a");
  }, [message.timestamp]);

  if (message.channel === "internal") {
    return null;
  }

  let senderName: string;
  let senderId: string;
  let senderProfilePictureLocation: string | undefined;

  if (message.direction === "outgoing") {
    if (
      message.workspaceAuthorId &&
      appData.workspaceMembers[message.workspaceAuthorId]
    ) {
      const author = appData.workspaceMembers[message.workspaceAuthorId];
      senderName = author.data().name;
      senderId = author.id;
      senderProfilePictureLocation = undefined;
    } else {
      senderName = "";
      senderId = "";
      senderProfilePictureLocation = undefined;
    }
  } else if (
    message.channel === "whatsapp_web" &&
    message.groupParticipantInfo !== undefined
  ) {
    senderId = "fake";
    senderName = message.groupParticipantInfo.name;
    senderProfilePictureLocation =
      message.groupParticipantInfo.profilePictureLocation;
  } else {
    senderName = contact.name;
    senderId = message.contactId;
    senderProfilePictureLocation = contact.profilePictureLocation;
  }

  let echoBadge: IconKey | null = null;

  const isInstagramOutgoingEchoMessage =
    message.channel === "instagram" &&
    message.workspaceAuthorId === undefined &&
    message.direction === "outgoing";

  const isWhatsappOutgoingEchoMessage =
    message.channel === "whatsapp_web" &&
    message.workspaceAuthorId === undefined &&
    message.direction === "outgoing";

  if (isInstagramOutgoingEchoMessage) {
    echoBadge = "instagramBadge";
  } else if (isWhatsappOutgoingEchoMessage) {
    echoBadge = "whatsappBadge";
  }

  return (
    <MessageWrapper
      direction={message.direction}
      isFirstOfGroup={isFirstOfGroup}
      isLastOfGroup={isLastOfGroup}
    >
      <MessageProfilePicContainer
        style={{
          visibility: isLastOfGroup ? "visible" : "hidden",
          paddingBottom: isLastOfGroup ? "22px" : undefined,
        }}
      >
        {echoBadge && <Icon icon={echoBadge} size={40} />}
        {!echoBadge && message.workspaceAuthorId !== "zami" && (
          <MessageProfilePic
            id={senderId}
            name={senderName}
            imageLocation={senderProfilePictureLocation}
            showChannelBadgeFor={message.channel}
          />
        )}

        {message.workspaceAuthorId === "zami" && (
          <ZamiProfilePicWrapper>
            <div
              style={{
                display: "flex",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <LogoSquare width={40} height={40} />
            </div>
            <ChannelBadge>
              <Icon icon="whatsappBadge" size={16} />
            </ChannelBadge>
          </ZamiProfilePicWrapper>
        )}
      </MessageProfilePicContainer>
      <MessageContents>
        {isFirstOfGroup && (
          <MessageHeaderWrapper direction={message.direction}>
            <Text
              size="text_sm"
              weight="medium"
              color="gray700"
              style={{ marginBottom: 4, padding: "0 8px" }}
            >
              {senderName}
            </Text>
          </MessageHeaderWrapper>
        )}

        <MessageBubble
          isFirstOfGroup={isFirstOfGroup}
          isLastOfGroup={isLastOfGroup}
          direction={message.direction}
        >
          {message.channel === "whatsapp_web" && (
            <MessageActionsWrapper direction={message.direction}>
              <IconButton
                icon="cornerUpLeft"
                size="md"
                onClick={onReplyClicked}
              />
            </MessageActionsWrapper>
          )}

          {(message.channel === "whatsapp_web" ||
            message.channel === "whatsapp" ||
            message.channel === "instagram") &&
            message.replyToMessage && (
              <MessageReplyReference
                contact={contact}
                replyToMessage={message.replyToMessage}
                direction={message.direction!}
              />
            )}
          {message.message.type === "text" && (
            <TextMessage message={message.message} />
          )}

          {message.message.type === "deleted" && <DeletedMessage />}

          {message.message.type === "story_mention" && (
            <StoryMentionMessage message={message.message} />
          )}

          {message.message.type === "sticker" && (
            <StickerImage location={message.message.stickerLocation} />
          )}

          {message.message.type === "file" && (
            <FileMessageComponent message={message.message} />
          )}

          {message.message.type === "location" && (
            <a
              href={`http://www.google.com/maps/place/${message.message.latitude},${message.message.longitude}`}
            >
              Ver ubicación
            </a>
          )}

          {message.message.type === "video" && (
            <VideoMessage
              message={message.message}
              contact={
                message.direction === "incoming"
                  ? {
                      id: message.contactId,
                      data: contact,
                    }
                  : undefined
              }
              timestamp={message.timestamp}
              workspaceMember={
                message.direction === "outgoing" &&
                message.workspaceAuthorId &&
                appData.workspaceMembers[message.workspaceAuthorId]
                  ? {
                      id: message.workspaceAuthorId,
                      data: appData.workspaceMembers[
                        message.workspaceAuthorId
                      ].data(),
                    }
                  : undefined
              }
            />
          )}

          {message.message.type === "image" && (
            <>
              <ImageMessageImage
                location={message.message.imageLocation}
                onClick={() => {
                  if (message.message.type === "image") {
                    imagePreview.setPreview({
                      type: "image",
                      imageLocation: message.message.imageLocation,
                      contact:
                        message.direction === "incoming"
                          ? {
                              id: message.contactId,
                              data: contact,
                            }
                          : undefined,
                      workspaceMember:
                        message.direction === "outgoing" &&
                        message.workspaceAuthorId &&
                        appData.workspaceMembers[message.workspaceAuthorId]
                          ? {
                              id: message.workspaceAuthorId,
                              data: appData.workspaceMembers[
                                message.workspaceAuthorId
                              ].data(),
                            }
                          : undefined,
                      messageTimestamp: message.timestamp,
                    });
                  }
                }}
              />
              {message.message.caption !== undefined && (
                <Text size="text_md" marginTop="0.5rem">
                  {message.message.caption}
                </Text>
              )}
            </>
          )}

          {message.message.type === "audio" && (
            <AudioPlayer location={message.message.audioLocation} />
          )}

          {message.message.type === "contact" && (
            <ContactMessage message={message.message} />
          )}
        </MessageBubble>

        {isLastOfGroup && (
          <MessageFooterWrapper direction={message.direction}>
            <Text size="text_xs" color="gray600">
              {messageTimeString}
            </Text>
            {message.direction === "outgoing" && (
              <MessageStatus message={message} />
            )}
          </MessageFooterWrapper>
        )}
      </MessageContents>
    </MessageWrapper>
  );
};

export default Message;
