import React from "react";
import Box from "ui/Box";
import Text from "ui/Text";
import { ChannelCard } from ".";
import { ReactComponent as WhatsappLogo } from "assets/channelLogos/whatsapp.svg";
import { ReactComponent as InstagramLogo } from "assets/channelLogos/instagram.svg";
import { ReactComponent as MessengerLogo } from "assets/channelLogos/messenger.svg";
import Checkbox from "ui/Checkbox";
import Button from "ui/Button";
import { useAppData } from "components/AppData";
import db from "lib/db";

interface SelectedChannels {
  whatsappWebAccounts: Record<string, boolean>;
  instagramAccounts: Record<string, boolean>;
  facebookPages: Record<string, boolean>;
}

export default function SelectChannelView(props: {
  onAddChannel: () => void;
  onCancel: () => void;
}) {
  const appData = useAppData();
  const [selectedChannels, setSelectedChannels] =
    React.useState<SelectedChannels>({
      whatsappWebAccounts: {},
      instagramAccounts: {},
      facebookPages: {},
    });

  const handlePublish = async () => {
    await Promise.all(
      Object.entries(selectedChannels.whatsappWebAccounts)
        .filter(([, selected]) => selected)
        .map(([whatsappWebAccountId]) => {
          const whatsappWebAccountRef =
            appData.whatsappWebAccounts[whatsappWebAccountId].ref;
          return whatsappWebAccountRef.update({
            assignee: {
              type: "simple",
              rule: "always",
              assignee: "", // TODO: put actual bot id here
            },
          });
        })
    );

    await Promise.all(
      Object.entries(selectedChannels.instagramAccounts)
        .filter(([, selected]) => selected)
        .map(([id]) => {
          const instagramAccountRef = appData.instagramAccounts[id].ref;
          return instagramAccountRef.update({
            assignee: {
              type: "simple",
              rule: "always",
              assignee: "", // TODO: put actual bot id here
            },
          });
        })
    );

    await Promise.all(
      Object.entries(selectedChannels.facebookPages)
        .filter(([, selected]) => selected)
        .map(([id]) => {
          const facebookPageRef = appData.facebookPages[id].ref;
          return facebookPageRef.update({
            assignee: {
              type: "simple",
              rule: "always",
              assignee: "", // TODO: put actual bot id here
            },
          });
        })
    );
  };

  return (
    <>
      <Box padding="1rem 1.5rem" paddingRight="5rem" borderBottom>
        <Text size="text_lg" weight="semibold" marginBottom="0.25rem">
          ¿En cuales canales quieres publicar?
        </Text>

        <Text size="text_sm" color="gray600">
          Cuando publicas un bot a un canal, todas las conversaciones entrantes
          de ese canal serán atendidas por el bot
        </Text>
      </Box>

      <Box padding="1.5rem" paddingBottom="0rem">
        {Object.values(appData.whatsappWebAccounts).map(
          (whatsappWebAccountDoc) => (
            <ChannelCard>
              <Box flex alignItems="center">
                <Box marginRight="0.75rem">
                  <Checkbox
                    checked={
                      selectedChannels.whatsappWebAccounts[
                        whatsappWebAccountDoc.id
                      ]
                    }
                    onChange={(val) => {
                      setSelectedChannels((prev) => ({
                        ...prev,
                        whatsappWebAccounts: {
                          ...prev.whatsappWebAccounts,
                          [whatsappWebAccountDoc.id]: val,
                        },
                      }));
                    }}
                  />
                </Box>
                <WhatsappLogo width={40} height={40} />

                <Text size="text_md" weight="semibold" marginLeft="0.75rem">
                  {whatsappWebAccountDoc.data().name}
                </Text>
              </Box>
            </ChannelCard>
          )
        )}

        {Object.values(appData.instagramAccounts).map((instagramAccountDoc) => (
          <ChannelCard>
            <Box flex alignItems="center">
              <Box marginRight="0.75rem">
                <Checkbox
                  checked={
                    selectedChannels.instagramAccounts[instagramAccountDoc.id]
                  }
                  onChange={(val) => {
                    setSelectedChannels((prev) => ({
                      ...prev,
                      instagramAccounts: {
                        ...prev.instagramAccounts,
                        [instagramAccountDoc.id]: val,
                      },
                    }));
                  }}
                />
              </Box>
              <InstagramLogo width={40} height={40} />

              <Text size="text_md" weight="semibold" marginLeft="0.75rem">
                {instagramAccountDoc.data().username}
              </Text>
            </Box>
          </ChannelCard>
        ))}

        {Object.values(appData.facebookPages).map((facebookPageDoc, i) => (
          <ChannelCard key={i}>
            <Box flex alignItems="center">
              <Box marginRight="0.75rem">
                <Checkbox
                  checked={selectedChannels.facebookPages[facebookPageDoc.id]}
                  onChange={(val) => {
                    setSelectedChannels((prev) => ({
                      ...prev,
                      facebookPages: {
                        ...prev.facebookPages,
                        [facebookPageDoc.id]: val,
                      },
                    }));
                  }}
                />
              </Box>
              <MessengerLogo width={40} height={40} />

              <Text size="text_md" weight="semibold" marginLeft="0.75rem">
                {facebookPageDoc.data().name}
              </Text>
            </Box>
          </ChannelCard>
        ))}
      </Box>

      <Box padding="0.5rem 1.5rem" marginBottom="1rem">
        <Button
          size="md"
          kind="tertiary"
          icon="plus"
          iconStroke="primary700"
          onClick={props.onAddChannel}
        >
          Agregar canal
        </Button>
      </Box>

      <Box flex justifyContent="flex-end" padding="1.5rem">
        <Button
          size="md"
          kind="secondary"
          style={{ marginRight: "0.75rem" }}
          onClick={props.onCancel}
        >
          Cancelar
        </Button>
        <Button size="md">Publicar</Button>
      </Box>
    </>
  );
}
