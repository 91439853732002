import React from "react";
import {
  DefaultTheme,
  ThemeProvider as SCThemeProvider,
} from "styled-components";

const theme: DefaultTheme = {
  colors: {
    white: "#FFFFFF",
    gray25: "#FCFCFD",
    gray50: "#F9FAFB",
    gray100: "#F2F4F7",
    gray200: "#EAECF0",
    gray300: "#D0D5DD",
    gray400: "#98A2B3",
    gray500: "#667085",
    gray600: "#475467",
    gray700: "#344054",
    gray800: "#1D2939",
    gray900: "#101828",
    primary50: "#F0FAF7",
    primary100: "#E3F1EC",
    primary200: "#B9DED1",
    primary300: "#A5DBC8",
    primary500: "#09A17D",
    primary600: "#068466",
    primary700: "#004D49",
    primary800: "#1C4044",
    primary900: "#12282B",
    error100: "#FEE4E2",
    error200: "#FECDCA",
    error500: "#F04438",
    error600: "#D92D20",
    error700: "#B42318",
    error800: "#912018",
    success50: "#ECFDF3",
    success500: "#12B76A",
    success700: "#027A48",
    grayBlue400: "#717BBC",
    pink50: "#FDF2FA",
    pink700: "#C11574",
    orange600: "#E04F16",
    yellow500: "#EAAA08",
    cyan500: "#06AED4",
    green500: "#16B364",
    beige50: "#F9F7F3",
    whatsappBlueCheck: "#4FB6EC",
  },
  mediaQueries: {
    mobile: "@media only screen and (max-width: 767px)",
  },
};

export const ThemeProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  return <SCThemeProvider theme={theme}>{children}</SCThemeProvider>;
};

export default theme;
