import React from "react";
import { debounce } from "lodash";
import firebase from "firebase";
import styled from "styled-components";

import db from "lib/db";
import { useAuth } from "lib/auth";

import Button from "ui/Button";
import Badge from "ui/Badge";
import Header from "ui/Header";
import Input from "ui/Input";

import { Contact } from "common/src/types";

import ContactsList from "./ContactsList";
import ContactFormModal from "./ContactFormModal";
import EmptyContactsView from "./EmptyContactsView";
import { useAppData } from "components/AppData";
import { useTypesense } from "lib/typesense";
import Box from "ui/Box";
import ContactsLabelFilter from "./ContactsLabelFilter";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0rem 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const SearchWrapper = styled.div`
  .search-input {
    max-width: 320px;
  }
`;

const PER_PAGE = 30;

export const useContacts = () => {
  const appData = useAppData();
  const [resultsCount, setResultsCount] = React.useState<number>();
  const [selectedLabels, setSelectedLabels] = React.useState<string[]>();
  const [searchResults, setSearchResults] = React.useState<{
    data: (Contact & { id: string })[];
    hasMore: boolean;
    page: number;
    total: number;
  }>();
  const [searchQuery, setSearchQuery] = React.useState("");
  const typesenseClient = useTypesense();

  const fetchContacts = React.useMemo(() => {
    let filters: string[] = [];

    if ((selectedLabels?.length ?? 0) > 0) {
      filters = [...filters, `labels:=[${selectedLabels!.join(", ")}]`];
    }

    if (!appData.permissions.canSeeAllContacts) {
      const assigneeIn = [`wm:${appData.workspaceMemberSelf.id}`];
      if (appData.permissions.canSeeUnassignedContacts) {
        assigneeIn.push("");
      }
      filters = [
        ...filters,
        ...assigneeIn.map((assignee) => `assignee:=${assignee}`),
      ];
    }

    console.log(`Fetching contact with filters`, filters);

    return async (opts: {
      query: string | undefined;
      page?: number;
      appendResults?: boolean;
    }) => {
      const response = await typesenseClient
        ?.collections("contacts")
        .documents()
        .search({
          q: opts.query ?? "*",
          query_by: "name,email,phone",
          filter_by: filters.join(" && "),
          per_page: PER_PAGE,
          page: opts.page ?? 1,
        });
      if (response) {
        const pageCount = Math.ceil(response.found / PER_PAGE);
        const hasMore = (opts.page ?? 1) < pageCount;

        const resultData =
          response.hits?.map(
            (hit) => hit.document as Contact & { id: string }
          ) ?? [];

        setResultsCount(response.found);
        setSearchResults((prev) => {
          return {
            total: response.found,
            page: opts.page ?? 1,
            hasMore,
            data: opts.appendResults
              ? [...(prev?.data ?? []), ...resultData]
              : resultData,
          };
        });
      }
    };
  }, [
    typesenseClient,
    appData.permissions,
    appData.workspaceMemberSelf,
    selectedLabels,
  ]);

  const debouncedFetchContacts = React.useMemo(() => {
    return debounce(fetchContacts, 500);
  }, [fetchContacts]);

  React.useEffect(() => {
    if (searchQuery.length > 0) {
      debouncedFetchContacts({ query: searchQuery });
    } else {
      debouncedFetchContacts({ query: undefined });
    }
  }, [debouncedFetchContacts, searchQuery]);

  return {
    searchResults,
    resultsCount,
    searchQuery,
    setSearchQuery,
    fetchContacts,
    selectedLabels,
    setSelectedLabels,
  };
};

const Contacts = () => {
  const [isContactModalOpen, setContactModalOpen] = React.useState(false);

  const auth = useAuth();
  const {
    searchQuery,
    searchResults,
    setSearchQuery,
    fetchContacts,
    resultsCount,
    selectedLabels,
    setSelectedLabels,
  } = useContacts();

  const filteredContacts = React.useMemo(() => {
    return searchResults?.data ?? [];
  }, [searchResults]);

  if (searchResults === undefined) {
    return null;
  }

  return (
    <>
      <ContactFormModal
        open={isContactModalOpen}
        onOpenChange={setContactModalOpen}
        workspaceId={auth.workspaceId}
        onSubmitted={async () => {
          setSearchQuery("");
          await fetchContacts({
            query: "",
            page: 1,
            appendResults: false,
          });
        }}
      />
      <Wrapper>
        <Header
          actions={
            <Button
              size="md"
              kind="primary"
              icon="plus"
              onClick={() => setContactModalOpen(true)}
            >
              Agregar contacto
            </Button>
          }
          badge={
            resultsCount !== undefined ? (
              <Badge
                label={`${resultsCount} contacto${
                  resultsCount !== 1 ? "s" : ""
                }`}
                size="lg"
                type="pill"
                variant="success"
              />
            ) : null
          }
          title="Contactos"
        >
          <SearchWrapper>
            <Box flex>
              <Box marginRight="1rem">
                <div className="search-input">
                  <Input
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Buscar contacto"
                    iconLeft="search"
                  />
                </div>
              </Box>

              <ContactsLabelFilter
                labels={selectedLabels}
                onChange={setSelectedLabels}
              />
            </Box>
          </SearchWrapper>
        </Header>

        <Container>
          {filteredContacts.length >= 1 ? (
            <ContactsList
              workspaceId={auth.workspaceId}
              contacts={filteredContacts}
              onContactUpdated={async () => {
                setSearchQuery("");
                await fetchContacts({
                  query: "",
                  page: 1,
                  appendResults: false,
                });
              }}
              onEndReach={() => {
                if (searchResults?.hasMore) {
                  fetchContacts({
                    query: searchQuery,
                    page: searchResults.page + 1,
                    appendResults: true,
                  });
                }
              }}
            />
          ) : (
            <EmptyContactsView setContactModalOpen={setContactModalOpen} />
          )}
        </Container>
      </Wrapper>
    </>
  );
};

export default Contacts;
