import React from "react";
import { Howl, Howler } from "howler";

export function formatAudioTime(time: number) {
  const minutes = Math.floor(Math.round(time) / 60);
  const seconds = Math.floor(Math.round(time) % 60);

  return `${minutes}:${seconds.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
  })}`;
}

export const useAudioPlayer = (
  source: string | undefined,
  opts?: {
    mimeType: string;
  }
) => {
  const howlRef = React.useRef<Howl>();
  const playIdRef = React.useRef<number>();
  const [state, setState] = React.useState<"playing" | "paused" | "stopped">();
  const [seek, setSeek] = React.useState(0);
  const [duration, setDuration] = React.useState<number>();

  const returnVal = React.useMemo(() => {
    return {
      play: () => {
        if (howlRef.current?.state() === "loaded") {
          const playId = howlRef.current?.play(playIdRef.current);
          playIdRef.current = playId;
        }
      },
      load: () => {
        howlRef.current?.load();
      },
      pause: () => {
        howlRef.current?.pause(playIdRef.current);
      },
      duration,
      state,
      seek,
    };
  }, [duration, seek, state]);

  React.useEffect(() => {
    if (state === "playing") {
      const interval = setInterval(() => {
        setSeek(howlRef.current?.seek() ?? 0);
      }, 100);

      return () => clearInterval(interval);
    }
  }, [state]);

  if (howlRef.current === undefined && source !== undefined) {
    console.log("setting up howl");
    console.log(opts?.mimeType);
    howlRef.current = new Howl({
      src: source,
      format: "webm",
    });

    howlRef.current.once("load", () => {
      console.log("loaded!");
      setDuration(howlRef.current?.duration());
    });

    howlRef.current.on("play", () => {
      setState("playing");
    });

    howlRef.current.on("pause", () => setState("paused"));
    howlRef.current.on("stop", () => setState("stopped"));
    howlRef.current.on("end", () => {
      playIdRef.current = undefined;
      setState("stopped");
    });

    howlRef.current.on("seek", (param) => {
      console.log("seek", param);
    });

    howlRef.current.once("loaderror", (id, error) => {
      console.log("error", error, "id", id);
    });
  } else if (source === undefined && howlRef.current !== undefined) {
    howlRef.current.unload();
    howlRef.current = undefined;
  }

  return returnVal;
};
