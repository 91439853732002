import React from "react";

export function useCachedState<T extends string>(key: string, val: T) {
  const cacheKey = `cachedstate_${key}`;
  const [state, setState] = React.useState<T>(
    (localStorage.getItem(cacheKey) as T | undefined) || val
  );

  React.useEffect(() => {
    localStorage.setItem(cacheKey, state);
  }, [state, cacheKey]);

  return [state, setState] as [T, React.Dispatch<React.SetStateAction<T>>];
}
