import React from "react";
import { isOnMobile } from "lib/responsive";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ThemeProvider } from "ui/theme";
import GlobalStyles from "components/GlobalStyles";
import LoginPage from "components/onboarding/login";
import { AuthenticationProvider, useAuth } from "lib/auth";
import AuthenticatedApp from "components/AuthenticatedApp";
import WorkspaceInvitationPage from "components/settings/team/WorkspaceInvitationPage";
import ImpersonateUser from "components/ImpersonateUser";
import ResetPassword, {
  PasswordResetSetNewPassword,
} from "components/onboarding/resetPassword";
import { RESTClientProvider } from "lib/restClient";
import { OnboardingStyles } from "components/onboarding/common";
import { IDBProvider } from "lib/idb";
import { isDevelopmentBuild } from "lib/db";
import ActivateProposal from "components/onboarding/activateProposal";
import Signup from "components/onboarding/signup";
import PostCheckoutOnboarding from "components/onboarding/postCheckoutOnboarding";

const stripePromise = process.env.REACT_APP_STRIPE_PUBLIC_KEY
  ? loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
      locale: "es",
    })
  : null;

const AppRoutes = () => {
  const auth = useAuth();
  const intercom = useIntercom();
  const isMobile = React.useMemo(() => isOnMobile(), []);

  React.useEffect(() => {
    if (!isDevelopmentBuild) {
      intercom.boot({
        email: auth.user?.email ? auth.user.email : undefined,
        userId: auth.user ? auth.user.uid : undefined,
        hideDefaultLauncher:
          !isMobile && auth.workspace?.onboarding?.finishedOnboarding === false,
      });
    }
  }, [auth.user, intercom, isMobile, auth.workspace]);

  if (auth.user && auth.workspaceId) {
    return <AuthenticatedApp />;
  }

  if (auth.user && !auth.workspaceId) {
    return (
      <>
        <OnboardingStyles />
      </>
    );
  }

  return (
    <Switch>
      <Route path="/impersonate-user/:authToken" component={ImpersonateUser} />
      <Route path="/join/:invitationId" component={WorkspaceInvitationPage} />
      <Route
        path="/reset-password/:passwordResetToken"
        component={PasswordResetSetNewPassword}
      />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/login" render={() => <LoginPage />} />
      <Route
        path="/activate-proposal/:proposalId"
        component={ActivateProposal}
      />
      <Route exact path="/signup" component={Signup} />
      <Route
        path="/post-checkout/:checkoutSessionId/:leadId"
        component={PostCheckoutOnboarding}
      />
      <Redirect to="/login" />
    </Switch>
  );
};

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <AuthenticationProvider>
        <IDBProvider>
          <IntercomProvider appId="big5mu6o">
            <RESTClientProvider>
              <ThemeProvider>
                <BrowserRouter>
                  <GlobalStyles />
                  <AppRoutes />
                </BrowserRouter>
              </ThemeProvider>
            </RESTClientProvider>
          </IntercomProvider>
        </IDBProvider>
      </AuthenticationProvider>
    </Elements>
  );
};

export default App;
