import React from "react";
import dayjs from "dayjs";
import firebase from "firebase";
import {
  Conversation,
  Conversation as ConversationType,
  Message,
} from "common/src/types";
import styled from "styled-components";
import Button from "ui/Button";
import db from "lib/db";
import Text from "ui/Text";
import Composer, { ComposerMessage, ComposerWrapper } from "./composer";
import { useCommandBar } from "components/commandBar";
import { useAppData } from "components/AppData";
import { sendOutgoingMessage } from "lib/chat";
import {
  assignConversationToMember,
  updateConversation,
} from "lib/conversations";
import { useIDB } from "lib/idb";

const WhatsappTemplateComposerWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WhatsappTemplateComposer = ({ contactId }: { contactId: string }) => {
  const commandBar = useCommandBar();
  return (
    <ComposerWrapper>
      <WhatsappTemplateComposerWrapper>
        <Text size="text_md" weight="semibold">
          Esta conversación no está activa
        </Text>

        <Text
          size="text_sm"
          color="gray600"
          style={{ margin: "0.5rem 0rem", marginBottom: "1rem" }}
        >
          Whatsapp requiere enviar plantillas de mensajes cuando el contacto no
          ha escrito en las últimas 24 horas.
        </Text>

        <Button
          size="md"
          title="Usar plantilla de mensaje"
          kind="primary"
          icon="file"
          onClick={() =>
            commandBar.openCommandBarForWhatsappTemplate(contactId)
          }
        >
          Usar plantilla de mensaje
        </Button>
      </WhatsappTemplateComposerWrapper>
    </ComposerWrapper>
  );
};

const ChatComposer = React.forwardRef(
  (
    {
      conversation,
      quotedMessage,
      onQuotedMessageClear,
    }: {
      quotedMessage?: firebase.firestore.QueryDocumentSnapshot<Message>;
      conversation: { id: string; data: ConversationType };
      onQuotedMessageClear?: () => void;
    },
    ref
  ) => {
    const initialChannels = React.useRef<Record<string, Message["channel"]>>(
      {}
    );
    const idb = useIDB();
    const appData = useAppData();
    const conversationData = conversation.data;
    const [channel, setChannel] = React.useState(
      conversationData.lastMessage.channel
    );

    const needsToSendTemplatedWhatsappMessage = React.useMemo(() => {
      return (
        channel === "whatsapp" &&
        Boolean(
          !conversationData.lastIncomingWhatsappMessage ||
            dayjs
              .unix(conversationData.lastIncomingWhatsappMessage.timestamp)
              .isBefore(dayjs().subtract(1, "day"))
        )
      );
    }, [conversationData.lastIncomingWhatsappMessage, channel]);

    React.useEffect(() => {
      if (!initialChannels.current[conversation.id]) {
        initialChannels.current[conversation.id] =
          conversationData.lastMessage.channel;
      }

      setChannel(initialChannels.current[conversation.id]);
    }, [conversation.id, conversationData]);

    const sendMessage = async (message: ComposerMessage) => {
      await sendOutgoingMessage({
        channel,
        workspaceAuthorId: appData.workspaceMemberSelf.id,
        contactId: conversationData.contactId,
        message,
      });

      const updates: Partial<Conversation> = {};

      if (conversationData.snoozeUntil !== null) {
        updates.snoozeUntil = null;
      }

      if (conversationData.assignee === "") {
        assignConversationToMember({
          idb,
          conversationId: conversation.id,
          authorId: appData.workspaceMemberSelf.id,
          assigneeId: `wm:${appData.workspaceMemberSelf.id}`,
          contactId: conversationData.contactId,
        });
      }

      await updateConversation(idb, conversation.id, updates);
    };

    if (needsToSendTemplatedWhatsappMessage) {
      return (
        <WhatsappTemplateComposer contactId={conversation.data.contactId} />
      );
    }

    if (conversation.data.closed) {
      return null;
    }

    return (
      <Composer
        channel={channel}
        contact={conversationData.contact}
        contactId={conversationData.contactId}
        onSendMessage={sendMessage}
        onQuotedMessageClear={onQuotedMessageClear}
        ref={ref}
        quotedMessage={
          quotedMessage
            ? {
                id: quotedMessage.id,
                data: quotedMessage.data(),
              }
            : undefined
        }
      />
    );
  }
);

export default ChatComposer;
