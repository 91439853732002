import styled, { keyframes } from "styled-components";
import * as Dialog from "@radix-ui/react-dialog";

import Icon, { IconKey, IconProps } from "ui/Icon";

const overlayShow = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Overlay = styled(Dialog.Overlay)`
  background-color: rgba(52, 64, 84, 0.7);
  position: fixed;
  inset: 0;
  animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
`;

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
`;

const Content = styled(Dialog.Content)`
  padding-top: 20px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 12px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 400px;
  max-height: 85vh;
  padding: 1.5rem;
  animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);

  ${(props) => props.theme.mediaQueries.mobile} {
    border-radius: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    transform: initial;
    max-width: initial;
    width: initial;
    max-height: 100vh;
    height: 100%;
    padding: 0rem;
    animation: ${slideUp} 150ms;
  }
`;

const Children = styled.div`
  height: 100%;
`;

const Title = styled(Dialog.Title)`
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.gray900};
`;

const Description = styled(Dialog.Description)`
  margin: 0;
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.gray600};
`;

const IconButton = styled.button`
  all: unset;
  cursor: pointer;
  font-family: inherit;
  border-radius: 100%;
  height: 1.5rem;
  width: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
`;

const Actions = styled.div`
  margin-top: 32px;
`;

const Close = styled(Dialog.Close)`
  all: unset;
`;

const FeaturedIconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  border-radius: 10px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FeaturedIcon = ({
  icon,
  iconProps,
}: {
  icon: IconKey;
  iconProps?: Partial<IconProps>;
}) => {
  return (
    <FeaturedIconWrapper>
      <Icon icon={icon} {...iconProps} />
    </FeaturedIconWrapper>
  );
};

type ModalProps = {
  trigger?: React.ReactNode;
  noClose?: boolean;
  contentStyle?: React.CSSProperties;
} & Dialog.DialogProps;

const Modal = ({ children, trigger, noClose, ...props }: ModalProps) => {
  return (
    <Dialog.Root {...props}>
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Portal>
        <Overlay />
        <Content
          style={props.contentStyle}
          onEscapeKeyDown={() => props.onOpenChange?.(false)}
        >
          {children && <Children>{children}</Children>}
          {!noClose && (
            <Dialog.Close asChild>
              <IconButton>
                <Icon icon="x" stroke="gray500" />
              </IconButton>
            </Dialog.Close>
          )}
        </Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

Modal.Title = Title;
Modal.Description = Description;
Modal.Actions = Actions;
Modal.Cancel = Dialog.Close;
Modal.Close = Close;
Modal.FeaturedIcon = FeaturedIcon;

export default Modal;
