import React from "react";
import styled from "styled-components";
import Box from "ui/Box";
import Modal from "ui/Modal";
import Text from "ui/Text";
import Button from "ui/Button";
import Icon from "ui/Icon";
import QRCode from "react-qr-code";
import { useConnectWhatsappWebAccount } from "components/settings/ConnectWhatsappWebAccount";
import SelectChannelView from "./selectChannelsForPublish";
import AddChannelView from "./addChannel";

export const ChannelCard = styled.div`
  border-radius: 12px;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0rem;
  }
`;

const QRCodeWrapper = styled.div`
  margin: 0rem 1.5rem;
  border-radius: 12px;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  height: 224px;
  box-sizing: content-box;
`;

const Card = styled.div`
  border-radius: 12px;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  padding: 1.5rem;
`;

function ConnectWhatsAppView(props: {
  onBack: () => void;
  onConnectSuccess?: () => void;
}) {
  const { whatsappWebAccountDoc, qrCode, handleRefreshQR } =
    useConnectWhatsappWebAccount();

  const status = whatsappWebAccountDoc?.data()?.status;

  const { onConnectSuccess } = props;

  React.useEffect(() => {
    if (status === "active") {
      onConnectSuccess?.();
    }
  }, [status, onConnectSuccess]);

  return (
    <>
      <Box
        padding="1rem 1.5rem"
        paddingRight="5rem"
        borderBottom
        flex
        alignItems="center"
      >
        <Box onClick={props.onBack}>
          <Icon icon="arrowLeft" />
        </Box>
        <Box marginLeft="1rem">
          <Text size="text_lg" weight="semibold" marginBottom="0.25rem">
            WhatsApp
          </Text>

          <Text size="text_sm" color="gray600">
            Cuando conectas tu número de WhatsApp puedes publicar bots y atender
            las conversaciones desde Zami.
          </Text>
        </Box>
      </Box>

      <QRCodeWrapper>
        {qrCode && <QRCode value={qrCode} size={224} />}
        {whatsappWebAccountDoc?.data()?.status === "timed_out" && (
          <Box
            style={{ height: 224 }}
            flex
            justifyContent="center"
            alignItems="center"
          >
            <Button size="sm" icon="refresh" onClick={handleRefreshQR}>
              Actualizar código QR
            </Button>
          </Box>
        )}
      </QRCodeWrapper>

      <Box padding="1.5rem">
        <Card>
          <Text size="text_md" weight="semibold" marginBottom="1.5rem">
            Pasos para conectar WhatsApp
          </Text>

          <Text size="text_md" color="gray600" marginBottom="1rem">
            1. Abre WhatsApp en tu teléfono.
          </Text>

          <Box marginBottom="1.25rem">
            <Text size="text_md" color="gray600" inline>
              2. Toca Menú
            </Text>{" "}
            <Icon
              icon="whatsappDots"
              style={{ display: "inline", verticalAlign: "middle" }}
            />{" "}
            <Text size="text_md" color="gray600" marginBottom="1.25rem" inline>
              o Configuración
            </Text>{" "}
            <Icon
              icon="whatsappSettings"
              style={{ display: "inline", verticalAlign: "middle" }}
            />{" "}
            <Text size="text_md" color="gray600" marginBottom="1.25rem" inline>
              y selecciona Dispositivos vinculados.
            </Text>
          </Box>

          <Text size="text_md" color="gray600" marginBottom="1rem">
            3. Toca Vincular un dispositivo
          </Text>

          <Text size="text_md" color="gray600">
            4. Cuando se active la cámara, apunta tu teléfono hacia esta
            pantalla para escanear el código.
          </Text>
        </Card>
      </Box>
    </>
  );
}

type PublishModalStep =
  | "add_channel"
  | "select_channels_for_publish"
  | "connect_whatsapp";

export default function PublishModal(props: {
  open: boolean;
  onOpenChange: () => void;
}) {
  const [step, setStep] = React.useState<PublishModalStep>(
    "select_channels_for_publish"
  );

  return (
    <Modal
      open={props.open}
      noClose
      onOpenChange={props.onOpenChange}
      contentStyle={{
        padding: 0,
        maxWidth: 554,
      }}
    >
      {step === "select_channels_for_publish" && (
        <SelectChannelView
          onAddChannel={() => setStep("add_channel")}
          onCancel={() => props.onOpenChange()}
        />
      )}

      {step === "add_channel" && (
        <AddChannelView
          onSelectChannel={(channel) => {
            if (channel === "whatsapp") {
              setStep("connect_whatsapp");
            }
          }}
          onBack={() => setStep("select_channels_for_publish")}
        />
      )}

      {step === "connect_whatsapp" && (
        <ConnectWhatsAppView
          onBack={() => setStep("add_channel")}
          onConnectSuccess={() => setStep("select_channels_for_publish")}
        />
      )}
    </Modal>
  );
}
