import React from "react";
import RSelect, { Props } from "react-select";
import { useTheme } from "styled-components";

const Select = (props: Props) => {
  const theme = useTheme();
  return (
    <RSelect
      {...props}
      value={props.value}
      styles={{
        indicatorSeparator: () => ({
          display: "none",
        }),
        clearIndicator: () => {
          return {
            display: "none",
          };
        },
        control: (baseStyles, state) => {
          return {
            ...baseStyles,
            ":hover": {
              borderColor: theme.colors.gray300,
            },
            borderColor: theme.colors.gray300,
            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
            borderRadius: 8,
          };
        },
      }}
      onChange={props.onChange}
      options={props.options}
    />
  );
};

export default Select;
