import { useAppData } from "components/AppData";
import styled from "styled-components";
import { Table, TableWrapper, THead, TBody, Tr, Td, Th } from "ui/Table";
import { ReportData } from ".";
import Text from "ui/Text";
import Box from "ui/Box";
import { formatSeconds } from "./utils";
import ProfilePic from "components/ProfilePic";

const TableBorderWrapper = styled.div`
  border: solid 1px ${(props) => props.theme.colors.gray200};
  border-radius: 12px;
  margin-bottom: 1.5rem;

  ${Tr} {
    &:last-child {
      ${Td} {
        border-bottom: none;
      }
    }
  }
`;

const MembersTableBreakdown = ({ reportData }: { reportData: ReportData }) => {
  const appData = useAppData();
  return (
    <TableBorderWrapper>
      <TableWrapper>
        <Table>
          <THead>
            <Tr>
              <Th>Nombre</Th>
              <Th>Conv. abiertas</Th>
              <Th>Conv. cerradas</Th>
              <Th>Tiempo primera respuesta</Th>
              <Th>Tiempo de cierre</Th>
            </Tr>
          </THead>

          <TBody>
            {Object.keys(appData.workspaceMembers).map(
              (workspaceMemberId, i) => {
                const workspaceMember =
                  appData.workspaceMembers[workspaceMemberId];
                const workspaceMemberData = workspaceMember.data();
                const conversationOpens =
                  reportData.aggregatedReportDataByMember[
                    workspaceMemberId
                  ]?.conversation_opened.eventCount.toString() ?? "—";
                const conversationCloses =
                  reportData.aggregatedReportDataByMember[
                    workspaceMemberId
                  ]?.conversation_closed.eventCount.toString() ?? "—";
                const memberFirstResponseTimeEntry =
                  reportData.aggregatedReportDataByMember[workspaceMemberId]
                    ?.first_response_time;

                const memberFirstResponseTimeLabel =
                  memberFirstResponseTimeEntry
                    ? formatSeconds(
                        Math.trunc(
                          memberFirstResponseTimeEntry.valueSum /
                            memberFirstResponseTimeEntry.eventCount
                        )
                      )
                    : "—";

                const memberCloseTimeEntry =
                  reportData.aggregatedReportDataByMember[workspaceMemberId]
                    ?.conversation_closed;
                const memberCloseTimeLabel = memberCloseTimeEntry
                  ? formatSeconds(
                      Math.trunc(
                        memberCloseTimeEntry.valueSum /
                          memberCloseTimeEntry.eventCount
                      )
                    )
                  : "—";

                return (
                  <Tr key={i}>
                    <Td>
                      <Box flex alignItems="center">
                        <ProfilePic
                          id={workspaceMember.id}
                          name={workspaceMemberData.name}
                          size="md"
                        />
                        <Text
                          size="text_sm"
                          weight="semibold"
                          marginLeft="0.75rem"
                        >
                          {workspaceMemberData.name}
                        </Text>
                      </Box>
                    </Td>
                    <Td>{conversationOpens}</Td>
                    <Td>{conversationCloses}</Td>
                    <Td>{memberFirstResponseTimeLabel}</Td>
                    <Td>{memberCloseTimeLabel}</Td>
                  </Tr>
                );
              }
            )}
          </TBody>
        </Table>
      </TableWrapper>
    </TableBorderWrapper>
  );
};

export default MembersTableBreakdown;
