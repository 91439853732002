import React from "react";
import styled from "styled-components";
import Dropdown from "ui/Dropdown";
import Button from "ui/Button";
import Text from "ui/Text";
import Radio from "ui/Radio";
import { useChat } from "./context";

const DropdownSection = styled.div`
  margin: 0rem -0.5rem;
  padding: 0rem 0.5rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
`;

const ConversationListFilterDropdown = () => {
  const {
    setConversationsOrderFilter,
    conversationsOrderFilter,
    conversationsStatusFilter,
    setConversationsStatusFilter,
  } = useChat();
  return (
    <Dropdown
      trigger={
        <div>
          <Button
            icon="settings2"
            size="sm"
            kind="secondary"
            style={{ padding: "0.5rem" }}
          />
        </div>
      }
    >
      <DropdownSection>
        <Text
          size="text_sm"
          weight="semibold"
          color="gray500"
          paddingLeft="0.5rem"
          marginBottom="0.25rem"
        >
          Orden
        </Text>
        <Dropdown.Item
          onClick={() => setConversationsOrderFilter("oldest_first")}
        >
          <Radio
            marginRight="0.5rem"
            active={conversationsOrderFilter === "oldest_first"}
          />
          Antiguas primero
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => setConversationsOrderFilter("newest_first")}
        >
          <Radio
            marginRight="0.5rem"
            active={conversationsOrderFilter === "newest_first"}
          />
          <div>Nuevas primero</div>
        </Dropdown.Item>
      </DropdownSection>

      <Text
        size="text_sm"
        weight="semibold"
        color="gray500"
        paddingLeft="0.5rem"
        marginBottom="0.25rem"
      >
        Estado
      </Text>
      <Dropdown.Item onClick={() => setConversationsStatusFilter("open")}>
        <Radio
          marginRight="0.5rem"
          active={conversationsStatusFilter === "open"}
        />
        Abiertas
      </Dropdown.Item>
      <Dropdown.Item onClick={() => setConversationsStatusFilter("closed")}>
        <Radio
          marginRight="0.5rem"
          active={conversationsStatusFilter === "closed"}
        />
        Cerradas
      </Dropdown.Item>
      <Dropdown.Item onClick={() => setConversationsStatusFilter("snoozed")}>
        <Radio
          marginRight="0.5rem"
          active={conversationsStatusFilter === "snoozed"}
        />
        Pospuestas
      </Dropdown.Item>
    </Dropdown>
  );
};

export default ConversationListFilterDropdown;
