import { createGlobalStyle } from "styled-components";
import interRegular from "assets/Inter-Regular.ttf";
import interMedium from "assets/Inter-Medium.ttf";
import interSemiBold from "assets/Inter-SemiBold.ttf";
import interBold from "assets/Inter-Bold.ttf";

const GlobalStyles = createGlobalStyle`
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    overflow: auto;
    background-color: white;
    border-radius: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
    overflow: auto;
    width: 8px;
    max-width: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.gray200};
    background-clip: padding-box;
    width: 4px;
    max-width: 4px;
    overflow: auto;
    border-radius: 8px;
    border: solid 1px white;
  }

  @font-face {
    font-family: Inter;
    src: url("${interRegular}");
    font-weight: 400;
  }

  @font-face {
    font-family: Inter;
    src: url("${interMedium}");
    font-weight: 500;
  }

  @font-face {
    font-family: Inter;
    src: url("${interSemiBold}");
    font-weight: 600;
  }

  @font-face {
    font-family: Inter;
    src: url("${interBold}");
    font-weight: 700;
  }

  html {
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
  }

  div,input {
    box-sizing: border-box;
  }

  input,button,select,textarea,div,body,html {
    font-family: Inter;
  }

  body {
    margin: 0;
  }

  html {
    height: 100%;
    overflow: hidden;
  }
`;

export default GlobalStyles;
