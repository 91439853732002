import React from "react";
import posthog from "posthog-js";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import Conversation from "./Conversation";
import ConversationList from "./ConversationList";
import { useAuth } from "lib/auth";
import Inboxes from "./Inboxes";
import { useAppData } from "components/AppData";
import { useChat, wrapWithChatContext } from "./context";
import NewConversation from "./NewConversation";
import notificationSound from "assets/sfx/notification.aac";
import useSound from "use-sound";
import ContactProfile from "./ContactProfile";
import { useMediaQuery } from "react-responsive";
import InboxEmptyState from "./InboxEmptyState";
import { useConversationList } from "lib/conversations";

const Chat = ({
  match,
}: {} & RouteComponentProps<{
  inbox: string;
  selectedConversationId?: string;
}>) => {
  const shouldInboxesFloat = useMediaQuery({ query: "(max-width: 1366px)" });
  const [inboxesOpen, setInboxesOpen] = React.useState(!shouldInboxesFloat);
  const history = useHistory();
  const inboxName = match.params.inbox;
  const auth = useAuth();
  const appData = useAppData();
  const chat = useChat();
  const [playNotificationSound] = useSound(notificationSound);

  const receivedFirstSnapshot = React.useRef<boolean>(false);

  const myWorkspaceMember = React.useMemo(() => {
    return Object.values(appData.workspaceMembers).find(
      (member) => member.data().userId === auth.user?.uid
    );
  }, [auth.user, appData.workspaceMembers]);

  const isNewConversation =
    match.params.selectedConversationId?.includes("new:conv");

  const { conversations } = useConversationList(inboxName, {
    status: chat.conversationsStatusFilter,
    order: chat.conversationsOrderFilter,
  });

  const conversation = conversations.find((conv) =>
    match?.params.selectedConversationId
      ? conv.id === match.params.selectedConversationId
      : undefined
  );

  const conversationLastMessage = conversation?.data.lastMessage?.channel;

  React.useEffect(() => {
    if (conversation?.id) {
      posthog.capture("Conversation Viewed", {
        conversationId: conversation.id,
        channel: conversationLastMessage,
      });
    }
  }, [conversation?.id, conversationLastMessage]);

  React.useEffect(() => {
    if (!conversation && conversations.length > 0 && !isNewConversation) {
      history.push(`/chat/${inboxName}/${conversations[0].id}`);
    }
  }, [conversation, history, conversations, inboxName, isNewConversation]);

  const allowedInboxes = ["inbox", "pendingResponse"];

  if (
    appData.permissions.canSeeAllConversations ||
    appData.permissions.canSeeUnassignedConversations
  ) {
    allowedInboxes.push("unassigned");
  }

  if (
    !allowedInboxes.includes(inboxName) &&
    inboxName.includes("assignee-") &&
    !appData.permissions.canSeeAllConversations &&
    inboxName !== `assignee-${myWorkspaceMember?.id}`
  ) {
    return <Redirect to="/chat" />;
  }

  return (
    <>
      <Inboxes
        inbox={inboxName}
        open={inboxesOpen}
        selectedConversationId={match.params.selectedConversationId}
        shouldFloat={shouldInboxesFloat}
        onOpenChange={(open) => setInboxesOpen(open)}
      />
      <ConversationList
        conversations={conversations}
        onInboxesToggle={() => setInboxesOpen((o) => !o)}
        shouldInboxesFloat={shouldInboxesFloat}
        selectedConversationId={match.params.selectedConversationId}
        inbox={inboxName}
      />
      {isNewConversation && (
        <NewConversation
          conversations={conversations}
          contactId={match.params.selectedConversationId!.replace(
            "new:conv:",
            ""
          )}
        />
      )}

      {!isNewConversation && conversation && (
        <Conversation conversation={conversation} />
      )}

      {!isNewConversation && !conversation && receivedFirstSnapshot.current && (
        <InboxEmptyState />
      )}

      {conversation && (
        <ContactProfile
          contact={{
            id: conversation.data.contactId,
            data: conversation.data.contact,
          }}
        />
      )}
    </>
  );
};

export default wrapWithChatContext(Chat);
