import {
  Message,
  Contact,
  Conversation,
  Workspace,
  WorkspaceMember,
  WhatsappTemplate,
  WorkspaceInboxes,
  InstagramAccount,
  FacebookPage,
  WhatsappBusinessAccount,
  WorkspaceInvite,
  ReportingEvent,
  WhatsappWebAccount,
  ReportingEntry,
  WorkspaceRole,
  Team,
  ContactNote,
  SavedReply,
  ScheduledMessage,
  Label,
  AutomatedMessagingSequence,
  Lead,
} from "common/src/types";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";

export const isDevelopmentBuild =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

firebase.initializeApp({
  apiKey: "AIzaSyDZFQLqq-m_3_0oSlIEZ0YjBadg0NNdy_U",
  authDomain: "zami-352207.firebaseapp.com",
  projectId: "zami-352207",
  storageBucket: "zami-352207.appspot.com",
  messagingSenderId: "15928746117",
  appId: "1:15928746117:web:27582f4e5794adcdef49ec",
});

if (isDevelopmentBuild) {
  firebase.firestore().useEmulator("localhost", 8080);
  firebase.functions().useEmulator("localhost", 5001);
  firebase.database().useEmulator("localhost", 5002);
}

(window as any).fb = firebase;

export const firestore = firebase.firestore();

const converter = <T>() => ({
  toFirestore: (data: T) => data as firebase.firestore.DocumentData,
  fromFirestore: (snap: any) => snap.data() as T,
});

const dataPoint = <T>(collectionPath: string) =>
  firestore.collection(collectionPath).withConverter(converter<T>());

export const CORE_URL = isDevelopmentBuild
  ? "http://localhost:6570"
  : "https://core.zamiapp.com";

const db = {
  messages: dataPoint<Message>("messages"),
  contacts: dataPoint<Contact>("contacts"),
  conversations: dataPoint<Conversation>("conversations"),
  workspaceMembers: dataPoint<WorkspaceMember>("workspaceMembers"),
  workspaces: dataPoint<Workspace>("workspaces"),
  whatsappTemplates: dataPoint<WhatsappTemplate>("whatsappTemplates"),
  workspaceInboxes: dataPoint<WorkspaceInboxes>("workspaceInboxes"),
  instagramAccounts: dataPoint<InstagramAccount>("instagramAccounts"),
  facebookPages: dataPoint<FacebookPage>("facebookPages"),
  whatsappBusinessAccounts: dataPoint<WhatsappBusinessAccount>(
    "whatsappBusinessAccounts"
  ),
  whatsappWebAccounts: dataPoint<WhatsappWebAccount>("whatsappWebAccounts"),
  workspaceInvites: dataPoint<WorkspaceInvite>("workspaceInvites"),
  reportingEvents: dataPoint<ReportingEvent>("reportingEvents"),
  reportingEntries: dataPoint<ReportingEntry>("reportingEntries"),
  roles: dataPoint<WorkspaceRole>("roles"),
  teams: dataPoint<Team>("teams"),
  labels: dataPoint<Label>("labels"),
  notes: dataPoint<ContactNote>("notes"),
  savedReplies: dataPoint<SavedReply>("savedReplies"),
  scheduledMessages: dataPoint<ScheduledMessage>("scheduledMessages"),
  automatedMessagingSequences: dataPoint<AutomatedMessagingSequence>(
    "automatedMessagingSequences"
  ),
  leads: dataPoint<Lead>("leads"),
};

export const storage = firebase.storage();

type ConnectInstagramAccountResult =
  | {
      ok: false;
      error: string;
    }
  | {
      ok: true;
      instagramAccounts: Exclude<InstagramAccount, "fbPageAccessToken">[];
    };

type ConnectMessengerResult =
  | { ok: false }
  | {
      ok: true;
      facebookPages: ({ id: string } & Exclude<
        FacebookPage,
        "fbPageAccessToken"
      >)[];
    };

type ConnectWhatsappResult =
  | { ok: false }
  | {
      ok: true;
      whatsappBusinessAccounts: (WhatsappBusinessAccount & { id: string })[];
    };

const closeConversation = firebase
  .functions()
  .httpsCallable("closeConversation");

export const functions = {
  closeConversation: async (conversationId: string) =>
    closeConversation({ conversationId }),
  sendWhatsappTemplate: async (
    whatsappTemplateId: string,
    contactId: string,
    variableValues: Record<string, string>
  ) =>
    firebase.functions().httpsCallable("sendWhatsappTemplate")({
      whatsappTemplateId,
      contactId,
      variableValues,
    }),
  createContact: async (contact: Contact) =>
    firebase.functions().httpsCallable("createContact")(contact),
  deleteContact: async (contactId: string, workspaceId: string) =>
    firebase.functions().httpsCallable("deleteContact")({
      contactId,
      workspaceId,
    }),
  updateContact: async (contactId: string, contact: Partial<Contact>) =>
    firebase.functions().httpsCallable("updateContact")({ contactId, contact }),
  getInstagramAccounts: async (workspaceId: string) =>
    (await firebase.functions().httpsCallable("getInstagramAccounts")({
      workspaceId,
    })) as {
      data: (Exclude<InstagramAccount, "fbPageAccessToken"> & { id: string })[];
    },
  connectInstagramAccount: async (params: {
    fbUserAccessToken: string;
    fbPageId: string;
    fbInstagramAccountId: string;
    workspaceId: string;
  }) => {
    const result = await firebase
      .functions()
      .httpsCallable("connectInstagramAccount")(params);
    return result.data as ConnectInstagramAccountResult;
  },
  disconnectInstagramAccount: async (params: { instagramAccountId: string }) =>
    await firebase.functions().httpsCallable("disconnectInstagramAccount")(
      params
    ),
  getFacebookPages: async (workspaceId: string) => {
    return (
      await firebase.functions().httpsCallable("getFacebookPages")({
        workspaceId,
      })
    ).data as ({ id: string } & Exclude<FacebookPage, "fbPageAccessToken">)[];
  },
  connectFacebookPageForMessenger: async (params: {
    fbUserAccessToken: string;
    fbPageId: string;
    workspaceId: string;
  }) => {
    const result = await firebase
      .functions()
      .httpsCallable("connectFacebookPageForMessenger")(params);

    return result.data as ConnectMessengerResult;
  },
  disconnectFacebookPage: async (params: { facebookPageId: string }) =>
    await firebase.functions().httpsCallable("disconnectFacebookPage")(params),
  connectWhatsappBusinessAccount: async (params: {
    accessToken: string;
    whatsappBusinessAccountId: string;
    workspaceId: string;
  }) =>
    (
      await firebase
        .functions()
        .httpsCallable("connectWhatsappBusinessAccount")(params)
    ).data as ConnectWhatsappResult,
  getWhatasppBusinessAccountsForAccessToken: async (params: {
    accessToken: string;
  }) =>
    (
      await firebase
        .functions()
        .httpsCallable("getWhatasppBusinessAccountsForAccessToken")(params)
    ).data as { name: string; id: string }[],
  disconnectWhatsappBusinessAccount: async (params: {
    whatsappBusinessAccountId: string;
  }) =>
    (
      await firebase
        .functions()
        .httpsCallable("disconnectWhatsappBusinessAccount")(params)
    ).data,
  inviteTeamMember: async (params: {
    email?: string;
    userId?: string;
    workspaceId: string;
    roleId: string | undefined;
    isSuperuser: boolean;
    teams?: string[];
  }) => await firebase.functions().httpsCallable("inviteTeamMember")(params),
  acceptInvite: async (params: {
    workspaceInvitationId: string;
    name: string;
    newPassword: string;
  }) =>
    (await firebase.functions().httpsCallable("acceptInvite")(params)).data as {
      ok: boolean;
      authToken?: string;
    },
  signup: async (params: {
    email: string;
    name: string;
    phone: string;
    businessName: string;
    password: string;
  }) =>
    (await firebase.functions().httpsCallable("signup")(params)).data as {
      ok: boolean;
      token: string;
    },
  requestPasswordResetEmail: async (params: { email: string }) =>
    await firebase.functions().httpsCallable("requestPasswordResetEmail")(
      params
    ),
};

export default db;
