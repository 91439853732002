import { Message } from "common/src/types";
import { ComposerMessage } from "components/chat/composer";
import db from "./db";
import { AppDataCtxInterface } from "components/AppData";
import { ZamiDexie } from "./idb";

export const sendOutgoingMessage = async ({
  channel,
  message,
  contactId,
  workspaceAuthorId,
}: {
  channel: Message["channel"];
  contactId: string;
  message: ComposerMessage;
  workspaceAuthorId: string;
}) => {
  if (
    channel === "instagram" ||
    channel === "whatsapp" ||
    channel === "whatsapp_web" ||
    channel === "page"
  ) {
    console.log("quoted message", message.quotedMessage);
    const partialData: any = {
      contactId,
      direction: "outgoing" as "outgoing",
      timestamp: Math.trunc(Date.now() / 1000),
      sentAt: null,
      deliveredAt: null,
      workspaceAuthorId,
      readAt: null,
    };

    if (message.quotedMessage) {
      partialData.replyToMessageId = message.quotedMessage.id;
      partialData.replyToMessage = message.quotedMessage.data;
    }

    let messageData: Message | undefined = undefined;

    if (channel === "whatsapp_web" && message.type === "file") {
      messageData = {
        ...partialData,
        channel,
        message: {
          type: "file",
          fileName: message.file.name,
          fileSize: message.file.size,
          fileLocation: message.location,
          fileMimetype: message.file.type,
        },
      } as Message;
    } else if (message.type === "image") {
      messageData = {
        ...partialData,
        channel,
        message: {
          type: "image",
          imageLocation: message.location,
          caption: message.caption ?? "",
        },
      } as Message;
    } else if (message.type === "audio") {
      messageData = {
        ...partialData,
        channel,
        message: {
          type: "audio",
          audioLocation: message.location,
          duration: message.duration,
          audioMimetype: message.mimeType,
        },
      };
    } else if (message.type === "text") {
      messageData = {
        ...partialData,
        channel,
        message: {
          type: "text",
          body: message.body,
        },
      } as Message;
    }

    if (messageData !== undefined) {
      const res = await db.messages.add(messageData);
      return { id: res.id, data: messageData };
    }
  }
  return null;
};

export function getPermittedAssignees(appData: AppDataCtxInterface) {
  if (appData.permissions.canSeeAllConversations) {
    return null;
  }

  const permittedAssignees = [`wm:${appData.workspaceMemberSelf.id}`];

  if (appData.permissions.canSeeUnassignedConversations) {
    permittedAssignees.push("");
  }

  appData.workspaceMemberSelf?.data().teams?.forEach((teamId) => {
    permittedAssignees.push(`t:${teamId}`);
  });

  return permittedAssignees;
}

export function getPendingResponseIDBCollection(
  idb: ZamiDexie,
  appData: AppDataCtxInterface
) {
  if (appData.permissions.canSeeAllConversations) {
    return idb.conversations
      .where("[lastMessageDirection+status]")
      .equals(["incoming", "open"]);
  }

  const permittedAssignees = getPermittedAssignees(appData)!;

  return idb.conversations
    .where("[assignee+lastMessageDirection+status]")
    .anyOf(
      permittedAssignees.map((assignee) => [`${assignee}`, "incoming", "open"])
    );
}
