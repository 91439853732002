import {
  Contact,
  Conversation,
  FirestoreTimestamp,
  Message,
} from "common/src/types";
import { useAppData } from "components/AppData";
import ProfilePic from "components/ProfilePic";
import { channel } from "diagnostics_channel";
import firebase from "firebase";
import { useAuth } from "lib/auth";
import { sendOutgoingMessage } from "lib/chat";
import db from "lib/db";
import { useImagePreview } from "lib/imagePreview";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Box from "ui/Box";
import Text from "ui/Text";
import Composer, { ComposerMessage } from "./composer";
import ConversationMessages from "./ConversationMessages";
import { IDBConversation, useIDB } from "lib/idb";
import { createConversation, updateConversation } from "lib/conversations";

const ConversationWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.gray25};
  min-height: 0px;
`;

const HeaderWrapper = styled.div`
  padding: 1rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
  background-color: white;
  display: flex;
  align-items: center;
  height: 4.5rem;
`;

const ChatWindow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  padding: 1rem;
  overflow-y: auto;
`;

const getContactChannel = (
  contact?: Contact | undefined | null
): Message["channel"] | null => {
  if (!contact) {
    return null;
  }
  if (contact.whatsappWebId) {
    return "whatsapp_web";
  } else if (contact.whatsappId) {
    return "whatsapp";
  } else if (contact.instagramAccount?.id) {
    return "instagram";
  } else if (contact.facebookUser?.id) {
    return "page";
  } else {
    return null;
  }
};

const NewConversation = ({
  contactId,
  conversations,
}: {
  contactId: string;
  conversations: IDBConversation[];
}) => {
  const auth = useAuth();
  const appData = useAppData();
  const history = useHistory();
  const idb = useIDB();
  const [sending, setSending] = React.useState(false);
  const imagePreview = useImagePreview();
  const [contact, setContact] =
    React.useState<firebase.firestore.DocumentSnapshot<Contact>>();
  const [redirectWhenFound, setRedirectWhenFound] = React.useState<string>();

  console.log("redirectWhenFound", redirectWhenFound);

  const handleSendMessage = async (message: ComposerMessage) => {
    const contactData = contact!.data()!;
    const channel = getContactChannel(contactData);
    setSending(true);
    const newConversationId = `conv:${contactId}`;
    const conversationDoc = await db.conversations.doc(newConversationId).get();
    console.log("DOES IT GET HERE AT ALL?");
    if (channel) {
      const sendResult = await sendOutgoingMessage({
        contactId,
        message,
        workspaceAuthorId: appData.workspaceMemberSelf!.id,
        channel,
      });
      const ts = Math.trunc(Date.now() / 1000);
      if (conversationDoc.exists && sendResult) {
        await updateConversation(idb, newConversationId, {
          closed: false,
          lastMessageAt: ts,
          lastMessageId: sendResult.id,
          lastMessage: sendResult.data,
          snoozeUntil: null,
          assigneeId: appData.workspaceMemberSelf.id,
        });
      } else if (sendResult) {
        await createConversation(idb, newConversationId, {
          lastMessage: sendResult.data,
          lastMessageId: sendResult.id,
          lastMessageAt: ts,
          contact: contactData,
          closed: false,
          contactId,
          workspaceId: auth.workspaceId!,
          snoozeUntil: null,
          assignee: `wm:${appData.workspaceMemberSelf.id}`,
        });
      }
      setRedirectWhenFound(newConversationId);
    }

    setSending(false);
  };

  React.useEffect(() => {
    if (redirectWhenFound) {
      const foundConversation =
        conversations.find((conv) => conv.id === redirectWhenFound) !==
        undefined;

      if (foundConversation) {
        history.push(`/chat/inbox/${redirectWhenFound}`);
      }
    }
  }, [history, conversations, redirectWhenFound]);

  React.useEffect(() => {
    (async () => {
      const contact = await db.contacts.doc(contactId).get();
      setContact(contact);
    })();
  }, [auth.workspaceId, setContact, contactId]);

  if (!contact || !contact.exists) {
    return null;
  }

  const contactData = contact.data()!;
  const channel = getContactChannel(contactData);

  return (
    <ConversationWrapper>
      <HeaderWrapper>
        <Box
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (contactData.profilePictureLocation) {
              imagePreview.setPreview({
                type: "image",
                imageLocation: contactData.profilePictureLocation,
                contact: {
                  id: contact.id,
                  data: contactData,
                },
              });
            }
          }}
        >
          <ProfilePic
            id={contactId}
            name={contactData.name}
            imageLocation={contactData.profilePictureLocation}
          />
        </Box>
        <Text
          size="text_lg"
          weight="semibold"
          color="gray900"
          marginLeft="1rem"
        >
          {contactData.name}
        </Text>
      </HeaderWrapper>
      <ChatWindow>
        <ConversationMessages contact={contactData} contactId={contactId} />
      </ChatWindow>
      {channel && (
        <Composer
          contactId={contactId}
          channel={channel}
          onSendMessage={handleSendMessage}
          contact={contactData}
        />
      )}
    </ConversationWrapper>
  );
};

export default NewConversation;
