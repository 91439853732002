import React from "react";
import styled from "styled-components";

const TextareaInput = styled.textarea`
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.gray300};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  width: 100%;
  box-sizing: border-box;
  transition-duration: 200ms;
  resize: none;

  &:focus-visible {
    outline: none;
    border: solid 1px ${(props) => props.theme.colors.primary300};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px ${(props) => props.theme.colors.primary100};
  }
`;

const Textarea = (props: React.TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  return <TextareaInput {...props} />;
};

export default Textarea;
