import { formatAudioTime, useAudioPlayer } from "lib/audio";
import React from "react";
import styled from "styled-components";
import Box from "ui/Box";
import Icon from "ui/Icon";
import Text from "ui/Text";

type RecorderOptions = {
  onStart?: () => void;
  onStop?: () => void;
};

class AudioRecorder {
  stream?: MediaStream;
  mediaRecorder?: MediaRecorder;
  audioBlobs: Blob[] = [];
  options?: RecorderOptions;

  constructor(options?: RecorderOptions) {
    this.startRecording = this.startRecording.bind(this);
    this.options = options;
  }

  async startRecording() {
    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      return {
        stream: null,
        error: "not_supported",
      };
    }

    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    this.stream = audioStream;
    this.mediaRecorder = new MediaRecorder(audioStream);
    this.audioBlobs = [];

    this.mediaRecorder.addEventListener("dataavailable", (event) => {
      //store audio Blob object
      this.audioBlobs.push(event.data);
    });

    this.mediaRecorder.addEventListener("start", (e) => {
      this.options?.onStart?.();
    });

    this.mediaRecorder.addEventListener("stop", (e) => {
      this.options?.onStop?.();
    });

    this.mediaRecorder.start();

    return {
      stream: audioStream,
      error: null,
    };
  }

  async stopRecording() {
    return new Promise<{ mimeType: string; blob: Blob }>((resolve, reject) => {
      if (this.mediaRecorder === undefined) {
        return reject();
      }
      //save audio type to pass to set the Blob type
      const mimeType = this.mediaRecorder.mimeType;

      //listen to the stop event in order to create & return a single Blob object
      this.mediaRecorder.addEventListener("stop", () => {
        //create a single blob object, as we might have gathered a few Blob objects that needs to be joined as one
        let audioBlob = new Blob(this.audioBlobs, { type: mimeType });

        //resolve promise with the single audio blob representing the recorded audio
        resolve({ mimeType, blob: audioBlob });
      });

      //stop the recording feature
      this.mediaRecorder.stop();

      //stop all the tracks on the active stream in order to stop the stream
      this.stream
        ?.getTracks() //get all tracks from the stream
        .forEach((track) /*of type MediaStreamTrack*/ => track.stop());
    });
  }
}

export const useRecorder = () => {
  const [isRecording, setRecording] = React.useState(false);
  const [recordingStartTime, setRecordingStartTime] = React.useState<number>();
  const [recordingStopTime, setRecordingStopTime] = React.useState<number>();
  const [recordedAudio, setRecordedAudio] = React.useState<{
    blob: Blob;
    mimeType: string;
  }>();

  const cancel = React.useCallback(() => {
    setRecording(false);
  }, []);

  React.useEffect(() => {
    if (!isRecording) {
      setRecordedAudio(undefined);
    }
  }, [isRecording]);

  const getDuration = React.useCallback(() => {
    if (!recordingStartTime) {
      return 0;
    }

    if (!recordingStopTime) {
      return Math.trunc(Date.now() / 1000) - recordingStartTime;
    }

    return recordingStopTime - recordingStartTime;
  }, [recordingStartTime, recordingStopTime]);

  const [duration, setDuration] = React.useState(getDuration());

  React.useEffect(() => {
    if (isRecording && recordingStopTime === undefined) {
      const interval = setInterval(() => {
        setDuration(getDuration());
      }, 100);

      return () => {
        clearInterval(interval);
      };
    }
  }, [getDuration, isRecording, recordingStopTime]);

  const recorderRef = React.useRef(
    new AudioRecorder({
      onStart: () => {
        setRecording(true);
        setRecordingStopTime(undefined);
        setRecordingStartTime(Math.trunc(Date.now() / 1000));
      },
      onStop: () => {
        setRecordingStopTime(Math.trunc(Date.now() / 1000));
      },
    })
  );

  const reset = React.useCallback(() => {
    setRecordedAudio(undefined);
    setRecording(false);
    setRecordingStartTime(undefined);
    setRecordingStopTime(undefined);
  }, [
    setRecordedAudio,
    setRecording,
    setRecordingStartTime,
    setRecordingStopTime,
  ]);

  const startRecording = React.useCallback(() => {
    recorderRef.current?.startRecording();
  }, []);

  const stopRecording = React.useCallback(async () => {
    const audio = await recorderRef.current?.stopRecording();
    setRecordedAudio(audio);
  }, [setRecordedAudio]);

  const result = React.useMemo(() => {
    return {
      isRecording,
      startRecording,
      stopRecording,
      cancel,
      duration,
      reset,
      recordingFinished: recordingStopTime !== undefined,
      recordedAudio,
    };
  }, [
    isRecording,
    startRecording,
    stopRecording,
    cancel,
    reset,
    duration,
    recordingStopTime,
    recordedAudio,
  ]);

  return result;
};

const CloseButtonWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.gray100};
  border-radius: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 200ms;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray200};
  }
`;

const GrayBox = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  background-color: ${(props) => props.theme.colors.gray100};
  margin-left: 0.5rem;
  padding: 0rem 1rem;
`;

const PauseWrapper = styled(Box)`
  width: 32px;
  height: 32px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.error600};
  margin-left: 0.5rem;
`;

const PlayerWrapper = styled(Box)`
  background-color: ${(props) => props.theme.colors.gray100};
  margin-left: 0.5rem;
  border-radius: 24px;
  height: 32px;
  padding: 0rem 1rem;
`;

export function VoiceRecorder(props: {
  finished?: boolean;
  duration: number;
  onCancel: () => void;
  audio?: {
    blob: Blob;
    mimeType: string;
  };
  onStop: () => void;
}) {
  const minutes = Math.trunc(props.duration / 60);
  const seconds = Math.trunc(props.duration % 60);
  const player = useAudioPlayer(
    props.audio ? URL.createObjectURL(props.audio.blob) : undefined,
    {
      mimeType: props.audio?.mimeType ?? "",
    }
  );

  React.useEffect(() => {
    if (props.audio !== undefined) {
      player.load();
    }
  }, [props.audio, player]);

  return (
    <Box flex alignItems="center">
      <Box marginRight="0.5rem" flex alignItems="center">
        <CloseButtonWrapper onClick={props.onCancel}>
          <Icon icon="x" size={20} />
        </CloseButtonWrapper>

        {!props.finished && (
          <GrayBox>
            Grabando... {minutes}:
            {seconds.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })}
          </GrayBox>
        )}

        {props.finished && props.audio !== undefined && (
          <PlayerWrapper flex alignItems="center">
            <Box
              onClick={player.state === "playing" ? player.pause : player.play}
            >
              <Icon
                icon={player.state === "playing" ? "pause" : "play"}
                fill="gray600"
                size={16}
              />
            </Box>
            <Text size="text_md" marginLeft="0.5rem">
              {player.state !== undefined &&
              ["playing", "paused"].includes(player.state)
                ? formatAudioTime(player.seek)
                : formatAudioTime(player.duration ?? 0)}
            </Text>
          </PlayerWrapper>
        )}

        {!props.finished ? (
          <PauseWrapper onClick={props.onStop}>
            <Icon icon="pause" size={16} />
          </PauseWrapper>
        ) : (
          <Box marginLeft="0.5rem">
            <Icon icon="microphoneFill" size={20} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
