import { ReportingEntry, ReportingEventName } from "common/src/types";
import firebase from "firebase";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useAuth } from "lib/auth";
import db from "lib/db";
import React from "react";
import styled from "styled-components";
import BarChart from "ui/BarChart";
import Box from "ui/Box";
import ButtonGroup from "ui/ButtonGroup";
import Text from "ui/Text";
import { formatSeconds } from "./utils";
import MembersTableBreakdown from "./membersTableBreakdown";
import { useAppData } from "components/AppData";
import { Redirect } from "react-router-dom";
dayjs.extend(utc);

const Wrapper = styled.div`
  padding: 0 2rem;
  padding-top: 2rem;
  flex: 1;
  overflow: auto;
`;

const Heading = styled.div`
  padding-bottom: 20px;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
  margin-bottom: 2rem;
`;

const ChartsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
  margin-bottom: 2rem;
`;

type ReportingPeriod = "yesterday" | "7D" | "30D" | "12S" | "6M" | "12M";

const reportingEntryIntervalMap: Record<
  ReportingPeriod,
  ReportingEntry["interval"]
> = {
  yesterday: "hour",
  "7D": "day",
  "30D": "week",
  "12S": "week",
  "6M": "month",
  "12M": "month",
};

const formatTimestampForInterval = (
  timestamp: number,
  interval: ReportingEntry["interval"]
) => {
  if (interval === "day") {
    return dayjs.unix(timestamp).utc().format("dddd D [de] MMMM");
  } else if (interval === "week") {
    const day = dayjs.unix(timestamp).utc();
    return `${day.startOf("week").format("D")} ${day
      .startOf("week")
      .format("MMM")} - ${day.endOf("week").format("D")} ${day
      .endOf("week")
      .format("MMM")}`;
  } else if (interval === "month") {
    const day = dayjs.unix(timestamp).utc();
    return day.format("MMMM YYYY");
  } else {
    return "wtf";
  }
};

const reportingPeriodTimestampMap: Record<
  ReportingPeriod,
  () => { start: number; end: number }
> = {
  yesterday: () => {
    const yesterday = dayjs.utc().subtract(1, "day");
    return {
      start: yesterday.startOf("day").unix(),
      end: yesterday.endOf("day").unix(),
    };
  },
  "7D": () => {
    // subtract 6 days because today counts
    return {
      start: dayjs.utc().subtract(6, "days").startOf("day").unix(),
      end: dayjs.utc().endOf("day").unix(),
    };
  },
  "30D": () => {
    return {
      start: dayjs.utc().subtract(30, "days").startOf("week").unix(),
      end: dayjs.utc().endOf("week").unix(),
    };
  },
  "12S": () => {
    return {
      start: dayjs.utc().subtract(10, "week").startOf("week").unix(),
      end: dayjs.utc().endOf("week").unix(),
    };
  },
  "6M": () => {
    return {
      start: dayjs.utc().subtract(5, "month").startOf("month").unix(),
      end: dayjs.utc().endOf("month").unix(),
    };
  },
  "12M": () => {
    return {
      start: dayjs.utc().subtract(1, "year").startOf("year").unix(),
      end: dayjs.utc().endOf("year").unix(),
    };
  },
};

export interface ReportData {
  conversationOpenReportData: { x: number; y: number }[];
  conversationCloseReportData: { x: number; y: number }[];
  firstResponseData: {
    timestamp: number;
    x: number;
    y: number;
    avgSeconds: number;
    eventCount: number;
  }[];
  conversationCloseTimeData: {
    timestamp: number;
    x: number;
    y: number;
    avgSeconds: number;
    eventCount: number;
  }[];
  aggregatedReportDataByMember: Record<
    string,
    Record<ReportingEventName, ReportingEntry["byWorkspaceMember"][string]>
  >;
}

const Reporting = () => {
  const auth = useAuth();
  const appData = useAppData();
  const [reportingPeriod, setReportingPeriod] =
    React.useState<ReportingPeriod>("7D");

  const [reportData, setReportData] = React.useState<ReportData>();

  const interval = reportingEntryIntervalMap[reportingPeriod];

  React.useEffect(() => {
    (async () => {
      const { start, end } = reportingPeriodTimestampMap[reportingPeriod]();
      const reportingEntries = await db.reportingEntries
        .where("interval", "==", interval)
        .where("workspaceId", "==", auth.workspaceId!)
        .where("timestamp", ">=", start)
        .where("timestamp", "<=", end)
        .get();

      const intervalCount =
        dayjs.unix(end).utc().diff(dayjs.unix(start).utc(), interval) + 1;

      const reportingEntriesMap = reportingEntries.docs.reduce((acc, curr) => {
        const data = curr.data();
        return {
          ...acc,
          [data.eventName]: [...(acc[data.eventName] ?? []), curr],
        };
      }, {} as Record<ReportingEntry["eventName"], firebase.firestore.QueryDocumentSnapshot<ReportingEntry>[]>);

      const conversationOpenData = Array(intervalCount)
        .fill(1)
        .map((_, i) => {
          const ts = dayjs.unix(start).utc().add(i, interval).unix();

          return {
            x: ts,
            y:
              reportingEntriesMap.conversation_opened
                ?.find((entry) => entry.data().timestamp === ts)
                ?.data().eventCount ?? 0,
          };
        });

      const conversationCloseData = Array(intervalCount)
        .fill(1)
        .map((_, i) => {
          const ts = dayjs.unix(start).utc().add(i, interval).unix();

          return {
            x: ts,
            y:
              reportingEntriesMap.conversation_closed
                ?.find((entry) => entry.data().timestamp === ts)
                ?.data().eventCount ?? 0,
          };
        });

      const conversationCloseTimeData = Array(intervalCount)
        .fill(1)
        .map((_, i) => {
          const ts: number = dayjs.unix(start).utc().add(i, interval).unix();

          const closeTimeEntryData = reportingEntriesMap.conversation_closed
            ?.find((entry) => entry.data().timestamp === ts)
            ?.data();

          const avg = closeTimeEntryData
            ? closeTimeEntryData.valueSum / closeTimeEntryData.eventCount
            : 0;

          return {
            x: ts,
            timestamp: ts,
            avgSeconds: avg,
            y: avg,
            eventCount: closeTimeEntryData?.eventCount ?? 0,
          };
        });

      const firstResponseData = Array(intervalCount)
        .fill(1)
        .map((_, i) => {
          const ts = dayjs.unix(start).utc().add(i, interval).unix();

          const firstResponseEntryData = reportingEntriesMap.first_response_time
            ?.find((entry) => entry.data().timestamp === ts)
            ?.data();
          const avg = firstResponseEntryData
            ? firstResponseEntryData.valueSum /
              firstResponseEntryData.eventCount
            : 0;

          return {
            timestamp: ts,
            x: ts,
            y: avg,
            avgSeconds: avg,
            eventCount: firstResponseEntryData?.eventCount ?? 0,
          };
        });

      const aggregatedReportDataByMember =
        {} as ReportData["aggregatedReportDataByMember"];

      reportingEntries.docs.forEach((reportingEntry) => {
        const eventName = reportingEntry.data().eventName;
        const entryByWorkspaceMember = reportingEntry.data().byWorkspaceMember;
        Object.keys(entryByWorkspaceMember).forEach((workspaceMemberId) => {
          if (!aggregatedReportDataByMember[workspaceMemberId]) {
            aggregatedReportDataByMember[workspaceMemberId] = {
              conversation_close_time: {
                valueSum: 0,
                eventCount: 0,
              },
              conversation_closed: {
                valueSum: 0,
                eventCount: 0,
              },
              conversation_opened: {
                valueSum: 0,
                eventCount: 0,
              },
              first_response_time: {
                valueSum: 0,
                eventCount: 0,
              },
            };
          }

          aggregatedReportDataByMember[workspaceMemberId][eventName] = {
            valueSum:
              aggregatedReportDataByMember[workspaceMemberId][eventName]
                .valueSum + entryByWorkspaceMember[workspaceMemberId].valueSum,
            eventCount:
              aggregatedReportDataByMember[workspaceMemberId][eventName]
                .eventCount +
              entryByWorkspaceMember[workspaceMemberId].eventCount,
          };
        });
      }, {} as ReportData["aggregatedReportDataByMember"]);

      setReportData({
        conversationOpenReportData: conversationOpenData,
        conversationCloseReportData: conversationCloseData,
        firstResponseData,
        conversationCloseTimeData: conversationCloseTimeData,
        aggregatedReportDataByMember,
      });
    })();
  }, [auth.workspaceId, reportingPeriod, interval]);

  let averageResponseTimeLabel = "N/A";
  let averageCloseTimeLabel = "N/A";

  const xTickFormat = (val: number) => {
    if (interval === "hour") {
      return dayjs.unix(val).utc().format("h:mm");
    }

    if (["day", "week"].includes(interval)) {
      return dayjs.unix(val).utc().format("D MMM");
    }

    if (["month", "year"].includes(interval)) {
      return dayjs.unix(val).utc().format("MMM");
    }

    return val.toString();
  };

  if (reportData) {
    const aggregatedFirstResponseData = reportData.firstResponseData.reduce(
      (acc, curr) => {
        return {
          totalSeconds: acc.totalSeconds + curr.avgSeconds * curr.eventCount,
          eventCount: acc.eventCount + curr.eventCount,
        };
      },
      { totalSeconds: 0, eventCount: 0 }
    );
    averageResponseTimeLabel = formatSeconds(
      Math.trunc(
        aggregatedFirstResponseData.totalSeconds /
          aggregatedFirstResponseData.eventCount
      )
    );

    const aggregatedCloseTimeData = reportData.conversationCloseTimeData.reduce(
      (acc, curr) => {
        return {
          totalSeconds: acc.totalSeconds + curr.avgSeconds * curr.eventCount,
          eventCount: acc.eventCount + curr.eventCount,
        };
      },
      { totalSeconds: 0, eventCount: 0 }
    );
    averageCloseTimeLabel = formatSeconds(
      Math.trunc(
        aggregatedCloseTimeData.totalSeconds /
          aggregatedCloseTimeData.eventCount
      )
    );
  }

  console.log(reportData?.aggregatedReportDataByMember);

  if (!appData.permissions.canSeeReporting) {
    return <Redirect to="/" />;
  }

  return (
    <Wrapper>
      <Heading>
        <Text size="display_xs" weight="semibold">
          Informes de conversaciones
        </Text>
      </Heading>

      <Box marginBottom="2rem">
        <ButtonGroup.Wrapper>
          <ButtonGroup.Button
            active={reportingPeriod === "yesterday"}
            onClick={() => setReportingPeriod("yesterday")}
          >
            Ayer
          </ButtonGroup.Button>
          <ButtonGroup.Button
            active={reportingPeriod === "7D"}
            onClick={() => setReportingPeriod("7D")}
          >
            7D
          </ButtonGroup.Button>
          <ButtonGroup.Button
            active={reportingPeriod === "30D"}
            onClick={() => setReportingPeriod("30D")}
          >
            30D
          </ButtonGroup.Button>
          <ButtonGroup.Button
            active={reportingPeriod === "12S"}
            onClick={() => setReportingPeriod("12S")}
          >
            12S
          </ButtonGroup.Button>
          <ButtonGroup.Button
            active={reportingPeriod === "6M"}
            onClick={() => setReportingPeriod("6M")}
          >
            6M
          </ButtonGroup.Button>
          <ButtonGroup.Button
            active={reportingPeriod === "12M"}
            onClick={() => setReportingPeriod("12M")}
          >
            12M
          </ButtonGroup.Button>
        </ButtonGroup.Wrapper>
      </Box>

      {reportData !== undefined && (
        <>
          <ChartsGrid>
            <BarChart
              data={reportData?.conversationOpenReportData ?? []}
              renderHint={(val) => {
                return (
                  <>
                    <Text size="text_xs" weight="semibold">
                      {val.y} Conversaci{val.y > 1 ? "ones" : "ón"}
                    </Text>
                    <Text size="text_xs">
                      ({formatTimestampForInterval(val.x, interval)})
                    </Text>
                  </>
                );
              }}
              xTickFormat={xTickFormat}
              value={
                reportData?.conversationOpenReportData
                  .reduce((acc, curr) => {
                    return acc + curr.y;
                  }, 0)
                  .toString() ?? "—"
              }
              label="Nuevas conversaciones"
            />
            <BarChart
              data={reportData?.conversationCloseReportData ?? []}
              xTickFormat={xTickFormat}
              renderHint={(val) => {
                return (
                  <>
                    <Text size="text_xs" weight="semibold">
                      {val.y} Conversaci{val.y > 1 ? "ones" : "ón"}
                    </Text>
                    <Text size="text_xs">
                      ({formatTimestampForInterval(val.x, interval)})
                    </Text>
                  </>
                );
              }}
              value={
                reportData?.conversationCloseReportData
                  .reduce((acc, curr) => {
                    return acc + curr.y;
                  }, 0)
                  .toString() ?? "asdf"
              }
              label="Conversaciones cerradas"
            />
            {reportData && (
              <BarChart
                data={reportData.firstResponseData}
                value={averageResponseTimeLabel}
                xTickFormat={xTickFormat}
                label="Tiempo promedio de primera respuesta"
                yTickFormat={(
                  val: typeof reportData.firstResponseData[number]["y"]
                ) => {
                  return formatSeconds(val, "", 1);
                }}
                renderHint={(val) => {
                  return (
                    <>
                      <Text size="text_xs" weight="semibold">
                        {formatSeconds(val.y)}
                      </Text>
                      <Text size="text_xs">
                        ({formatTimestampForInterval(val.x, interval)})
                      </Text>
                    </>
                  );
                }}
              />
            )}
            {reportData && (
              <BarChart
                data={reportData.conversationCloseTimeData}
                value={averageCloseTimeLabel}
                label="Tiempo promedio en cerrar conversaciones"
                xTickFormat={xTickFormat}
                yTickFormat={(
                  val: typeof reportData.firstResponseData[number]["y"]
                ) => {
                  return formatSeconds(val, "", 1);
                }}
                renderHint={(val) => {
                  return (
                    <>
                      <Text size="text_xs" weight="semibold">
                        {formatSeconds(val.y)}
                      </Text>
                      <Text size="text_xs">
                        ({formatTimestampForInterval(val.x, interval)})
                      </Text>
                    </>
                  );
                }}
              />
            )}
          </ChartsGrid>
          <MembersTableBreakdown reportData={reportData} />
        </>
      )}
    </Wrapper>
  );
};

export default Reporting;
