import Modal from "ui/Modal";

export default function IdeasModal(props: {
  open: boolean;
  onOpenChange: () => void;
}) {
  return (
    <Modal open={props.open} onOpenChange={props.onOpenChange}>
      This is the ideas modal
    </Modal>
  );
}
