import { MessageBubble, MessageWrapper } from "components/chat/Message";
import { isOnMobile } from "lib/responsive";
import { ReactComponent as EnvelopeParachutingIllustration } from "assets/illustrations/envelope-parachute-illustration.svg";
import { baseURL } from "lib/restClient";
import React from "react";
import styled from "styled-components";
import Box from "ui/Box";
import Button from "ui/Button";
import Icon from "ui/Icon";
import IconButton from "ui/IconButton";
import Modal from "ui/Modal";
import Text from "ui/Text";
import { REPL_MODE_SLOPPY } from "repl";

const MessageInput = styled.input`
  background-color: ${(props) => props.theme.colors.gray100};
  border-radius: 8px;
  border: none;
  padding: 8px 12px;
  outline: none;
  font-size: 16px;
  flex: 1;
`;

const ComposerWrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1rem;

  ${(props) => props.theme.mediaQueries.mobile} {
    border-radius: 0px;
  }
`;

const TextWrapper = styled.div`
  white-space: pre-wrap;
  ${(props) => props.theme.mediaQueries.mobile} {
    font-size: 14px;
    line-height: 20px;
  }
`;

const MessagesScrollWrapper = styled.div`
  height: 520px;
  ${(props) => props.theme.mediaQueries.mobile} {
    max-height: initial;
    flex: 1;
  }
`;

const EmptyMessagesWrapper = styled.div`
  padding: 3rem 0rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

function Message(props: {
  isFirstOfGroup: boolean;
  isLastOfGroup: boolean;
  children: React.ReactNode;
  direction: "incoming" | "outgoing";
}) {
  return (
    <MessageWrapper direction={props.direction} isFirstOfGroup isLastOfGroup>
      <MessageBubble
        isFirstOfGroup
        isLastOfGroup
        direction={props.direction}
        style={{ maxWidth: "80%" }}
      >
        <TextWrapper>{props.children}</TextWrapper>
      </MessageBubble>
    </MessageWrapper>
  );
}

type TestMessage = {
  direction: "incoming" | "outgoing";
  content: string;
};

async function streamGPTResponse(payload: {
  automatedSequenceId: string;
  promptMessage: string;
  messages: TestMessage[];
  onToken: (token: string) => void;
}) {
  const response = await fetch(
    `${baseURL}/automated-sequences/${payload.automatedSequenceId}/stream-gpt-completion`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        promptMessage: payload.promptMessage,
        messages: payload.messages.map((message) => ({
          role: message.direction === "incoming" ? "assistant" : "user",
          content: message.content,
        })),
      }),
    }
  );
  if (response.body) {
    const reader = response.body.getReader();
    while (true) {
      const readResult = await reader.read();
      if (readResult.value) {
        const resultDelta = new TextDecoder().decode(readResult.value);
        payload.onToken(resultDelta);
      }
      if (readResult.done) {
        break;
      }
    }
  }
}

export default function TestConversationModal(props: {
  open: boolean;
  automatedSequenceId: string;
  promptMessage: string;
  onOpenChange: (open: boolean) => void;
}) {
  const scrollDivRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [response, setResponse] = React.useState<string>("");
  const [height, setHeight] = React.useState(
    window.visualViewport?.height.toString() + "px" ?? "100vh"
  );
  const [messages, setMessages] = React.useState<TestMessage[]>([]);
  const [inputVal, setInputVal] = React.useState("");
  const [processingResponse, setProcessingResponse] = React.useState(false);

  const onMessageSent = async () => {
    const newMessage = {
      direction: "outgoing" as "outgoing",
      content: inputVal,
    };
    setMessages((prev) => [...prev, newMessage]);
    setProcessingResponse(true);
    setInputVal("");
    setResponse("");
    await streamGPTResponse({
      automatedSequenceId: props.automatedSequenceId,
      messages: [...messages, newMessage],
      promptMessage: props.promptMessage,
      onToken: (token) => {
        setMessages((prevMessages) => {
          if (prevMessages[prevMessages.length - 1].direction !== "incoming") {
            return [
              ...prevMessages,
              {
                direction: "incoming",
                content: token,
              },
            ];
          } else {
            return [
              ...prevMessages.slice(0, prevMessages.length - 1),
              {
                direction: "incoming",
                content: prevMessages[prevMessages.length - 1].content + token,
              },
            ];
          }
        });
      },
    });
    setProcessingResponse(false);
  };

  React.useEffect(() => {
    if (!processingResponse) {
      inputRef.current?.focus();
    }
  }, [processingResponse]);

  const scrollChatToBottom = React.useCallback(() => {
    if (scrollDivRef.current) {
      scrollDivRef.current.scrollTop = scrollDivRef.current?.scrollHeight;
    }
  }, []);

  const resetViewportDimensions = React.useCallback(() => {
    setHeight(window.visualViewport?.height + "px");
    scrollChatToBottom();
  }, [scrollChatToBottom]);

  React.useEffect(() => {
    if (props.open) {
      scrollChatToBottom();
    }
  }, [props.open, scrollChatToBottom]);

  React.useEffect(() => {
    scrollChatToBottom();
  }, [messages, scrollChatToBottom]);

  React.useEffect(() => {
    if (!props.open) {
      setMessages([]);
    }
  }, [props.open]);

  React.useEffect(() => {
    window.visualViewport?.addEventListener("scroll", resetViewportDimensions);
    window.visualViewport?.addEventListener("resize", resetViewportDimensions);
  }, [resetViewportDimensions]);

  return (
    <Modal
      open={props.open}
      onOpenChange={props.onOpenChange}
      noClose
      contentStyle={{
        padding: 0,
        maxWidth: 608,
      }}
    >
      <Box
        flex
        direction="column"
        style={
          isOnMobile()
            ? { height, position: "fixed", bottom: 0, left: 0, right: 0 }
            : undefined
        }
      >
        <Box
          flex
          alignItems="center"
          justifyContent="space-between"
          borderBottom
          padding="0.75rem"
          mobileOnly
        >
          <Box flex alignItems="center">
            <IconButton
              icon="x"
              size="sm"
              onClick={() => props.onOpenChange(false)}
            />
            <Text size="text_sm" weight="semibold">
              Mi primer bot
            </Text>
          </Box>

          <Button size="sm">Publicar</Button>
        </Box>

        <Box
          flex
          alignItems="center"
          justifyContent="space-between"
          borderBottom
          padding="0.75rem"
          desktopOnly
        >
          <Text size="text_sm" weight="semibold">
            Mi primer bot
          </Text>

          <IconButton
            icon="x"
            size="sm"
            onClick={() => props.onOpenChange(false)}
          />
        </Box>

        <MessagesScrollWrapper
          style={{
            padding: "0rem 0.75rem",
            minHeight: 0,
            overflow: "auto",
          }}
          ref={scrollDivRef}
        >
          {messages.length === 0 && (
            <EmptyMessagesWrapper>
              <EnvelopeParachutingIllustration />

              <Text
                size="text_md"
                weight="semibold"
                style={{
                  maxWidth: "338px",
                  textAlign: "center",
                  padding: "1rem 0rem",
                }}
              >
                Estas comunicandote con "Mi primer bot", escribe cualquier
                mensaje para empezar.
              </Text>
            </EmptyMessagesWrapper>
          )}

          {messages.map((message, i) => (
            <Message
              direction={message.direction}
              isFirstOfGroup
              isLastOfGroup
              key={i}
            >
              {message.content}
            </Message>
          ))}
        </MessagesScrollWrapper>

        <ComposerWrapper>
          <Box flex alignItems="center" justifyContent="space-between">
            <MessageInput
              ref={inputRef}
              value={inputVal}
              onChange={(e) => setInputVal(e.target.value)}
              disabled={processingResponse}
              placeholder="Escribe un mensaje"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onMessageSent();
                }
              }}
              onMouseDown={(e) => {
                // realInputRef.current?.focus();
                // e.preventDefault();
                // e.stopPropagation();
              }}
            />

            <Box marginLeft="12px" onClick={() => onMessageSent()}>
              <Icon
                icon="send3Fill"
                fill={processingResponse ? "gray400" : undefined}
              />
            </Box>
          </Box>
        </ComposerWrapper>
      </Box>
    </Modal>
  );
}
