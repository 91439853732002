import {
  AutomatedMessagingSequence,
  FacebookPage,
  InstagramAccount,
  Label,
  Team,
  WhatsappWebAccount,
  WorkspaceMember,
  WorkspaceRole,
} from "common/src/types";
import React from "react";
import firebase from "firebase";
import { useAuth } from "lib/auth";
import db from "lib/db";

export interface AppDataCtxInterface {
  workspaceMembers: Record<
    string,
    firebase.firestore.QueryDocumentSnapshot<WorkspaceMember>
  >;
  workspaceMemberSelf: firebase.firestore.QueryDocumentSnapshot<WorkspaceMember>;
  permissions: WorkspaceRole["permissions"];
  roles: Record<
    string,
    firebase.firestore.QueryDocumentSnapshot<WorkspaceRole>
  >;
  whatsappWebAccounts: Record<
    string,
    firebase.firestore.QueryDocumentSnapshot<WhatsappWebAccount>
  >;
  instagramAccounts: Record<
    string,
    firebase.firestore.QueryDocumentSnapshot<InstagramAccount>
  >;
  automatedMessagingSequences: Record<
    string,
    firebase.firestore.QueryDocumentSnapshot<AutomatedMessagingSequence>
  >;
  facebookPages: Record<
    string,
    firebase.firestore.QueryDocumentSnapshot<FacebookPage>
  >;
  teams: Record<string, firebase.firestore.QueryDocumentSnapshot<Team>>;
  labels: Record<string, firebase.firestore.QueryDocumentSnapshot<Label>>;
}

const AppDataCtx = React.createContext<AppDataCtxInterface>({
  workspaceMembers: {},
  workspaceMemberSelf: null as any,
  permissions: {} as any,
  roles: {},
  automatedMessagingSequences: {},
  instagramAccounts: {},
  whatsappWebAccounts: {},
  facebookPages: {},
  teams: {},
  labels: {},
});

const AppData = ({ children }: { children: React.ReactNode }) => {
  const [workspaceMembers, setWorkspaceMembers] =
    React.useState<
      Record<string, firebase.firestore.QueryDocumentSnapshot<WorkspaceMember>>
    >();
  const [labels, setLabels] =
    React.useState<
      Record<string, firebase.firestore.QueryDocumentSnapshot<Label>>
    >();
  const [workspaceRoles, setWorkspaceRoles] =
    React.useState<
      Record<string, firebase.firestore.QueryDocumentSnapshot<WorkspaceRole>>
    >();
  const [workspaceTeams, setWorkspaceTeams] =
    React.useState<
      Record<string, firebase.firestore.QueryDocumentSnapshot<Team>>
    >();
  const [instagramAccounts, setInstagramAccounts] =
    React.useState<
      Record<string, firebase.firestore.QueryDocumentSnapshot<InstagramAccount>>
    >();
  const [whatsappWebAccounts, setWhatsappWebAccounts] =
    React.useState<
      Record<
        string,
        firebase.firestore.QueryDocumentSnapshot<WhatsappWebAccount>
      >
    >();
  const [facebookPages, setFacebookPages] =
    React.useState<
      Record<string, firebase.firestore.QueryDocumentSnapshot<FacebookPage>>
    >();
  const [automatedMessagingSequences, setAutomatedMessagingSequences] =
    React.useState<
      Record<
        string,
        firebase.firestore.QueryDocumentSnapshot<AutomatedMessagingSequence>
      >
    >({});

  const auth = useAuth();

  React.useEffect(() => {
    const unsubscribeTeamsListener = db.teams
      .where("workspaceId", "==", auth.workspaceId)
      .onSnapshot((snap) => {
        setWorkspaceTeams(
          snap.docs.reduce((acc, curr) => {
            return { ...acc, [curr.id]: curr };
          }, {})
        );
      });

    const unsubscribeInstagramAccounts = db.instagramAccounts
      .where("workspaceId", "==", auth.workspaceId)
      .onSnapshot((snap) => {
        setInstagramAccounts(
          snap.docs.reduce((acc, curr) => {
            return { ...acc, [curr.id]: curr };
          }, {})
        );
      });

    const unsubscribeRolesListener = db.roles
      .where("workspaceId", "==", auth.workspaceId)
      .onSnapshot((snap) => {
        setWorkspaceRoles(
          snap.docs.reduce((acc, curr) => {
            return { ...acc, [curr.id]: curr };
          }, {})
        );
      });
    const unsubscribeWorkspaceMembersListener = db.workspaceMembers
      .where("workspaceId", "==", auth.workspaceId)
      .where("status", "==", "active")
      .onSnapshot((snap) => {
        setWorkspaceMembers(
          snap.docs.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.id]: curr,
            };
          }, {})
        );
      });

    const unsubscribeWhatsappWebAccountsListener = db.whatsappWebAccounts
      .where("workspaceId", "==", auth.workspaceId)
      .where("status", "==", "active")
      .onSnapshot((snap) => {
        console.log(snap.docs.map((doc) => doc.data()));
        setWhatsappWebAccounts(
          snap.docs.reduce((acc, curr) => {
            const data = curr.data();
            if (data.whatsappJid) {
              return {
                ...acc,
                [data.whatsappJid]: curr,
              };
            }
            return acc;
          }, {})
        );
      });

    const unsubscribeLabelsListener = db.labels
      .where("workspaceId", "==", auth.workspaceId)
      .onSnapshot((snap) => {
        setLabels(
          snap.docs.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.id]: curr,
            };
          }, {})
        );
      });

    const unsubscribeAutomatedSequencesListener = db.automatedMessagingSequences
      .where("workspaceId", "==", auth.workspaceId)
      .onSnapshot((snap) => {
        setAutomatedMessagingSequences(
          snap.docs.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.id]: curr,
            };
          }, {})
        );
      });

    const unsubscribeFacebookPagesListener = db.facebookPages
      .where("workspaceId", "==", auth.workspaceId)
      .onSnapshot((snap) => {
        setFacebookPages(
          snap.docs.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.id]: curr,
            };
          }, {})
        );
      });

    return () => {
      unsubscribeLabelsListener();
      unsubscribeWorkspaceMembersListener();
      unsubscribeRolesListener();
      unsubscribeInstagramAccounts();
      unsubscribeTeamsListener();
      unsubscribeWhatsappWebAccountsListener();
      unsubscribeAutomatedSequencesListener();
      unsubscribeFacebookPagesListener();
    };
  }, [auth.workspaceId, setWorkspaceMembers]);

  const workspaceMemberSelf = React.useMemo(() => {
    return Object.values(workspaceMembers || {}).find(
      (member) => member.data().userId === auth.user!.uid
    )!;
  }, [workspaceMembers, auth.user]);

  const permissions = React.useMemo(() => {
    const isSuperuser = workspaceMemberSelf?.data().isSuperuser;
    const role =
      workspaceRoles?.[workspaceMemberSelf?.data().roleId ?? ""]?.data();
    const permissions: WorkspaceRole["permissions"] = {
      canAssignConversations: Boolean(
        isSuperuser || role?.permissions.canAssignConversations
      ),
      canSeeAllContacts: Boolean(
        isSuperuser || role?.permissions.canSeeAllContacts
      ),
      canSeeUnassignedContacts: Boolean(
        isSuperuser || role?.permissions.canSeeUnassignedContacts
      ),
      canSeeUnassignedConversations: Boolean(
        isSuperuser ||
          role?.permissions.canSeeUnassignedConversations ||
          role?.permissions.canSeeAllConversations
      ),
      canSeeAllConversations: Boolean(
        isSuperuser || role?.permissions.canSeeAllConversations
      ),
      canSeeReporting: Boolean(
        isSuperuser || role?.permissions.canSeeReporting
      ),
      canAssignContacts: Boolean(
        isSuperuser || role?.permissions.canAssignContacts
      ),
      canSeePhoneNumbers: Boolean(
        isSuperuser || role?.permissions.canSeePhoneNumbers
      ),
    };

    return permissions;
  }, [workspaceMemberSelf, workspaceRoles]);

  const ctxVal = React.useMemo(() => {
    return {
      automatedMessagingSequences,
      workspaceMembers: workspaceMembers ?? {},
      workspaceMemberSelf,
      permissions,
      labels: labels ?? {},
      instagramAccounts: instagramAccounts ?? {},
      whatsappWebAccounts: whatsappWebAccounts ?? {},
      roles: workspaceRoles ?? {},
      teams: workspaceTeams ?? {},
      facebookPages: facebookPages ?? {},
    };
  }, [
    workspaceMembers,
    workspaceMemberSelf,
    permissions,
    labels,
    workspaceRoles,
    instagramAccounts,
    workspaceTeams,
    whatsappWebAccounts,
    automatedMessagingSequences,
    facebookPages,
  ]);

  if (workspaceMembers === undefined) {
    return null;
  }

  return <AppDataCtx.Provider value={ctxVal}>{children}</AppDataCtx.Provider>;
};

export const useAppData = () => React.useContext(AppDataCtx);

export default AppData;
