import React from "react";
import { DefaultTheme, useTheme } from "styled-components";
import Box, { BoxProps } from "./Box";

export type TextSize =
  | "display_xl"
  | "display_lg"
  | "display_md"
  | "display_sm"
  | "display_xs"
  | "text_xl"
  | "text_lg"
  | "text_md"
  | "text_sm"
  | "text_xs"
  | "text_xxs";

export const fontSizeStyles: Record<
  TextSize,
  { fontSize: number; lineHeight: string }
> = {
  display_xl: {
    fontSize: 60,
    lineHeight: "72px",
  },
  display_lg: {
    fontSize: 48,
    lineHeight: "60px",
  },
  display_md: {
    fontSize: 36,
    lineHeight: "44px",
  },
  display_sm: {
    fontSize: 30,
    lineHeight: "38px",
  },
  display_xs: {
    fontSize: 24,
    lineHeight: "32px",
  },
  text_xl: {
    fontSize: 20,
    lineHeight: "30px",
  },
  text_lg: {
    fontSize: 18,
    lineHeight: "28px",
  },
  text_md: {
    fontSize: 16,
    lineHeight: "24px",
  },
  text_sm: {
    fontSize: 14,
    lineHeight: "20px",
  },
  text_xs: {
    fontSize: 12,
    lineHeight: "18px",
  },
  text_xxs: {
    fontSize: 10,
    lineHeight: "16px",
  },
};

export type FontWeight = "regular" | "medium" | "semibold" | "bold";

const fontWeightStyles: Record<FontWeight, { fontWeight: number }> = {
  regular: {
    fontWeight: 400,
  },
  medium: {
    fontWeight: 500,
  },
  semibold: {
    fontWeight: 600,
  },
  bold: {
    fontWeight: 700,
  },
};

const Text = ({
  children,
  size,
  weight,
  color,
  style,
  ...restProps
}: {
  children: React.ReactNode;
  size?: TextSize;
  weight?: FontWeight;
  color?: keyof DefaultTheme["colors"];
  style?: React.CSSProperties;
} & BoxProps) => {
  const theme = useTheme();
  const styles = {
    ...style,
    ...fontSizeStyles[size ?? "text_md"],
    ...fontWeightStyles[weight ?? "regular"],
    ...(color && { color: theme.colors[color] }),
  };
  return (
    <Box style={styles} {...restProps}>
      {children}
    </Box>
  );
};

export default Text;
