import React, { useMemo } from "react";
import firebase from "firebase";
import dayjs from "dayjs";
import {
  Contact,
  Message as MessageType,
  InternalMessage as InternalMessageType,
} from "common/src/types";
import db from "lib/db";
import Message from "./Message";
import Text from "ui/Text";
import styled from "styled-components";
import {
  TimeAgoOptions,
  timeAgoLongSuffixOptions,
  useTimeAgoString,
} from "lib/time";
import { capitalize } from "lib/utils";
import { useAppData } from "../AppData";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { posthog } from "posthog-js";

dayjs.extend(weekOfYear);

export type EnhancedMessage =
  | {
      type: "message";
      message: {
        id: string;
        data: MessageType;
      };
      isFirstOfGroup: boolean;
      isLastOfGroup: boolean;
    }
  | {
      type: "date";
      timestamp: number;
    };

const InternalMessageWrapper = styled.div`
  margin: 8px 0;
  display: flex;
  justify-content: center;
`;
const Ellipsis = styled.div<{
  width?: string;
  height?: string;
  color?: string;
}>`
  display: inline-block;
  margin: auto 8px 2px 8px;
  width: ${(props) => props.width || "4px"};
  height: ${(props) => props.height || "4px"};
  background-color: ${(props) => props.color || "#667085"};
  border-radius: 50%;
`;

function isSameDay(timestamp1: number, timestamp2: number) {
  return dayjs.unix(timestamp1).isSame(dayjs.unix(timestamp2), "day");
}

function enhanceMessages(
  messages: firebase.firestore.QueryDocumentSnapshot<MessageType>[]
) {
  const enhancedMessages: EnhancedMessage[] = [];
  for (let i = 0; i < messages.length; i++) {
    const currentMessage = messages[i];
    const previousMessage = i - 1 >= 0 ? messages[i - 1] : undefined;
    const nextMessage = i + 1 < messages.length ? messages[i + 1] : undefined;

    const previousMessageData = previousMessage?.data();
    const currentMessageData = currentMessage?.data();
    const nextMessageData = nextMessage?.data();

    let isNewDate = false;

    if (i === 0) {
      isNewDate = true;
    } else if (
      !isSameDay(
        currentMessageData.timestamp,
        previousMessageData?.timestamp ?? 0
      )
    ) {
      isNewDate = true;
    }

    if (isNewDate) {
      enhancedMessages.push({
        type: "date",
        timestamp: currentMessageData.timestamp,
      });
    }

    let isFirstOfGroup = false;
    let isLastOfGroup = false;

    const previousMessageGroupParticipantName =
      previousMessageData?.channel === "whatsapp_web" ||
      previousMessageData?.channel === "whatsapp"
        ? previousMessageData.groupParticipantInfo?.name
        : "";

    const currentMessageGroupParticipantName =
      currentMessageData?.channel === "whatsapp_web" ||
      currentMessageData?.channel === "whatsapp"
        ? currentMessageData.groupParticipantInfo?.name
        : "";

    if (
      isNewDate ||
      previousMessage === undefined ||
      previousMessage.data().direction !== currentMessage.data().direction ||
      currentMessage.data().timestamp - previousMessage.data().timestamp >
        60 * 60 ||
      previousMessageGroupParticipantName !== currentMessageGroupParticipantName
    ) {
      isFirstOfGroup = true;
    }

    if (
      nextMessage === undefined ||
      nextMessage.data().direction !== currentMessage.data().direction ||
      nextMessage.data().timestamp - currentMessage.data().timestamp >
        60 * 60 ||
      !isSameDay(currentMessage.data().timestamp, nextMessage.data().timestamp)
    ) {
      isLastOfGroup = true;
    }

    if (currentMessage) {
      enhancedMessages.push({
        type: "message",
        message: {
          id: currentMessage.id,
          data: currentMessage.data(),
        },
        isFirstOfGroup,
        isLastOfGroup,
      });
    }
  }

  return enhancedMessages.reverse();
}

const InternalMessage = ({ message }: { message: InternalMessageType }) => {
  const appData = useAppData();

  const timeAgoString = useTimeAgoString(
    message.timestamp,
    timeAgoLongSuffixOptions
  );

  const snoozeUntilString = useMemo(() => {
    if (!message.snoozeUntil) return null;

    dayjs.locale("es");

    return dayjs
      .unix(message.snoozeUntil)
      .format("MMM DD[,] YYYY [a las] h:mm A");
  }, [message.snoozeUntil]);

  const isAssignedConversation =
    message.action === "assigned_conversation" &&
    message.workspaceMemberAssigneeId;

  const isSelfAssignedConversation =
    isAssignedConversation &&
    message.workspaceMemberAssigneeId?.includes(
      message.workspaceAuthorId ?? ""
    );

  const assignee = useMemo(() => {
    if (
      !(
        message.action === "assigned_conversation" &&
        message.workspaceMemberAssigneeId
      )
    ) {
      return null;
    }

    const [assigneeType, assigneeId] = message.workspaceMemberAssigneeId!.split(
      ":"
    ) as ["wm" | "t" | "b", string];

    if (assigneeType === "wm") {
      const member = Object.values(appData.workspaceMembers).find(
        (member) => member.id === assigneeId
      );
      return {
        type: "wm" as "wm",
        data: member?.data(),
      };
    }

    if (assigneeType === "t") {
      const team = Object.values(appData.teams).find(
        (team) => team.id === assigneeId
      );

      return {
        type: "t" as "t",
        data: team?.data(),
      };
    }

    if (assigneeType === "b") {
      const automatedMessagingSequence = Object.values(
        appData.automatedMessagingSequences
      ).find((sequence) => sequence.id === assigneeId);

      return {
        type: "b" as "b",
        data: automatedMessagingSequence?.data(),
      };
    }

    return null;
  }, [
    appData.teams,
    appData.workspaceMembers,
    appData.automatedMessagingSequences,
    message,
  ]);

  const label = useMemo(
    () =>
      message.labelId
        ? Object.values(appData.labels)
            .find((label) => label.id === message.labelId)
            ?.data()
        : null,
    [appData.labels, message.labelId]
  );

  const author = Object.values(appData.workspaceMembers)
    .find((member) => member.id === message.workspaceAuthorId)
    ?.data();

  const authorName =
    message.workspaceAuthorId === "zami" ? "Zami AI" : author?.name ?? "";

  const messagesByAction: Record<InternalMessageType["action"], string> = {
    opened_conversation: ` abrió la conversación`,
    closed_conversation: ` cerró la conversación`,
    snoozed_conversation: ` pospuso la conversación hasta `,
    assigned_conversation: ` le asignó la conversación a `,
    unassigned_conversation: ` eliminó la asignación de la conversación`,
    labeled_contact: ` agregó la etiqueta`,
    unlabeled_contact: ` eliminó la etiqueta`,
  };

  const actionMessageText = messagesByAction[message.action];

  const timeAgo =
    timeAgoString === "ahora"
      ? capitalize(timeAgoString)
      : `Hace ${timeAgoString}`;

  if (
    message.action === "opened_conversation" ||
    message.action === "closed_conversation"
  )
    return (
      <InternalMessageWrapper>
        <Text color="gray500" size="text_sm">
          <Text color="gray700" size="text_sm" weight="medium" inline>
            {authorName}
          </Text>
          <Text size="text_sm" inline>
            {actionMessageText}
          </Text>
          <Ellipsis />
          <Text
            size="text_sm"
            style={{
              textDecorationLine: "underline",
              lineHeight: "20px",
              cursor: "pointer",
            }}
            inline
          >
            {timeAgo}
          </Text>
        </Text>
      </InternalMessageWrapper>
    );

  if (isSelfAssignedConversation) {
    return (
      <InternalMessageWrapper>
        <Text color="gray500" size="text_sm">
          <Text color="gray700" size="text_sm" weight="medium" inline>
            {authorName}
          </Text>{" "}
          <Text size="text_sm" inline>
            se autoasignó la conversación
          </Text>
          <Ellipsis />
          <Text
            size="text_sm"
            style={{
              textDecorationLine: "underline",
              lineHeight: "20px",
              cursor: "pointer",
            }}
            inline
          >
            {timeAgo}
          </Text>
        </Text>
      </InternalMessageWrapper>
    );
  }

  if (isAssignedConversation && assignee?.data) {
    return (
      <InternalMessageWrapper>
        <Text color="gray500" size="text_sm">
          <Text color="gray700" size="text_sm" weight="medium" inline>
            {authorName}
          </Text>
          <Text size="text_sm" inline>
            {actionMessageText}
          </Text>
          <Text color="gray700" size="text_sm" weight="medium" inline>
            {assignee.type === "t"
              ? `${assignee.data.emoji} ${assignee.data.name}`
              : assignee.data.name}
          </Text>
          <Ellipsis />
          <Text
            size="text_sm"
            style={{
              textDecorationLine: "underline",
              lineHeight: "20px",
              cursor: "pointer",
            }}
            inline
          >
            {timeAgo}
          </Text>
        </Text>
      </InternalMessageWrapper>
    );
  }

  if (message.action === "unassigned_conversation") {
    return (
      <InternalMessageWrapper>
        <Text color="gray500" size="text_sm">
          <Text color="gray700" size="text_sm" weight="medium" inline>
            {authorName}
          </Text>
          <Text size="text_sm" inline>
            {actionMessageText}
          </Text>
          <Ellipsis />
          <Text
            size="text_sm"
            style={{
              textDecorationLine: "underline",
              lineHeight: "20px",
              cursor: "pointer",
            }}
            inline
          >
            {timeAgo}
          </Text>
        </Text>
      </InternalMessageWrapper>
    );
  }

  if (message.action === "labeled_contact" && label) {
    return (
      <InternalMessageWrapper>
        <Text color="gray500" size="text_sm">
          <Text color="gray700" size="text_sm" weight="medium" inline>
            {authorName}
          </Text>
          <Text size="text_sm" inline>
            {actionMessageText}
          </Text>
          <Ellipsis color={label.color} width="8px" height="8px" />
          <Text size="text_sm" inline>
            {label.label}
          </Text>
        </Text>
      </InternalMessageWrapper>
    );
  }

  if (message.action === "unlabeled_contact" && label) {
    return (
      <InternalMessageWrapper>
        <Text color="gray500" size="text_sm">
          <Text color="gray700" size="text_sm" weight="medium" inline>
            {authorName}
          </Text>
          <Text size="text_sm" inline>
            {actionMessageText}
          </Text>
          <Ellipsis color={label.color} width="8px" height="8px" />
          <Text size="text_sm" inline>
            {label.label}
          </Text>
        </Text>
      </InternalMessageWrapper>
    );
  }

  if (message.action === "snoozed_conversation" && snoozeUntilString) {
    return (
      <InternalMessageWrapper>
        <Text color="gray500" size="text_sm">
          <Text color="gray700" size="text_sm" weight="medium" inline>
            {authorName}
          </Text>
          <Text size="text_sm" inline>
            {actionMessageText}
          </Text>
          <Text size="text_sm" inline>
            {capitalize(snoozeUntilString)}
          </Text>
          <Ellipsis />
          <Text
            size="text_sm"
            style={{
              textDecorationLine: "underline",
              lineHeight: "20px",
              cursor: "pointer",
            }}
            inline
          >
            {timeAgo}
          </Text>
        </Text>
      </InternalMessageWrapper>
    );
  }

  return null;
};

const ConversationMessages: React.FC<{
  contactId: string;
  contact: Contact;
  onReplyClicked?: (
    message: firebase.firestore.QueryDocumentSnapshot<MessageType>
  ) => void;
}> = ({ contactId, contact, onReplyClicked }) => {
  const [messages, setMessages] = React.useState<
    firebase.firestore.QueryDocumentSnapshot<MessageType>[]
  >([]);

  const enhancedMessages = React.useMemo(() => {
    return enhanceMessages(messages);
  }, [messages]);

  React.useEffect(() => {
    let isFirstRead = true;
    const unsubscribe = db.messages
      .where("contactId", "==", contactId)
      .orderBy("timestamp", "asc")
      .onSnapshot((snapshot) => {
        if (isFirstRead) {
          isFirstRead = false;

          posthog.capture("Messages Loaded", {
            contactid: contactId,
            count: snapshot.docs.length,
          });
        }
        setMessages(snapshot.docs.map((doc) => doc));
      });

    return unsubscribe;
  }, [contactId]);

  return (
    <>
      {enhancedMessages.map((enhancedMessage) => {
        if (enhancedMessage.type === "date") {
          const messageDate = dayjs.unix(enhancedMessage.timestamp);

          const isSameWeek =
            messageDate.week() === dayjs().week() &&
            messageDate.year() === dayjs().year();

          const formattedMessageDate = isSameWeek
            ? messageDate.format("dddd")
            : messageDate.format("dddd DD [de] MMMM, YYYY");

          return (
            <InternalMessageWrapper>
              <Text color="gray500" size="text_sm">
                {capitalize(formattedMessageDate)}
              </Text>
            </InternalMessageWrapper>
          );
        }

        const messageData = enhancedMessage.message.data;

        if (messageData.contactId !== contactId) {
          return null;
        }

        if (messageData.channel === "internal") {
          return <InternalMessage message={messageData} />;
        }

        return (
          <Message
            key={enhancedMessage.message.id}
            message={messageData}
            contact={contact}
            isFirstOfGroup={enhancedMessage.isFirstOfGroup}
            isLastOfGroup={enhancedMessage.isLastOfGroup}
            onReplyClicked={() => {
              const repliedMessage = messages.find(
                (message) => enhancedMessage.message.id === message.id
              );
              repliedMessage && onReplyClicked?.(repliedMessage);
            }}
          />
        );
      })}
    </>
  );
};

export default ConversationMessages;
