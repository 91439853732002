import React from "react";
import styled from "styled-components";
import Box from "ui/Box";
import { ReactComponent as InstagramLogo } from "assets/channelLogos/instagram.svg";
import {
  BreadcrumbChevron,
  BreadcrumbCurrentPage,
  BreadcrumbLink,
  Breadcrumbs,
} from "ui/Breadcrumbs";
import Button from "ui/Button";
import Text from "ui/Text";
import { functions } from "lib/db";
import { useAuth } from "lib/auth";
import { InstagramAccount } from "common/src/types";
import { RouteComponentProps, useHistory } from "react-router-dom";
import db from "lib/db";
import ChannelAssigner from "./ChannelAssigner";

const Wrapper = styled.div`
  max-width: 70rem;
  margin: 0 auto;
  flex: 1;
  overflow-y: auto;
`;

const Heading = styled.div`
  flex: 1;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
  margin-bottom: 2rem;
`;

const SettingsBox = styled.div`
  border-radius: 12px;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  padding: 2rem 1.5rem;
`;

const InstagramChannelConfigure = (
  props: RouteComponentProps<{ username: string }>
) => {
  const history = useHistory();
  const [isDisconnecting, setDisconnecting] = React.useState(false);
  const [instagramAccount, setInstagramAccount] = React.useState<
    Exclude<InstagramAccount, "fbPageAccessToken"> & { id: string }
  >();
  const auth = useAuth();

  React.useEffect(() => {
    return db.instagramAccounts
      .where("workspaceId", "==", auth.workspaceId)
      .onSnapshot((snap) => {
        const igDoc = snap.docs.find(
          (doc) => doc.data().username === props.match.params.username
        )!;
        setInstagramAccount({ ...igDoc.data()!, id: igDoc.id });
      });
  }, [auth.workspaceId, props.match.params.username]);

  if (instagramAccount === undefined) {
    return null;
  }

  return (
    <Wrapper>
      <Heading>
        <Breadcrumbs marginBottom="20px">
          <BreadcrumbLink to="/settings/channels">Canales</BreadcrumbLink>
          <BreadcrumbChevron />
          <BreadcrumbCurrentPage>Instagram</BreadcrumbCurrentPage>
        </Breadcrumbs>
        <Box flex alignItems="center">
          <InstagramLogo />
          <Box marginLeft="20px" cols={1}>
            <Text size="display_xs" weight="semibold">
              Configuración de {instagramAccount.username}
            </Text>
          </Box>
        </Box>
      </Heading>

      <ChannelAssigner
        accountId={instagramAccount.id}
        accountType="instagram"
      />

      <SettingsBox>
        <Text size="text_xl" weight="semibold" marginBottom="1rem">
          Desconectar la cuenta
        </Text>
        <Text
          size="text_md"
          color="gray600"
          marginBottom="1.5rem"
          style={{ maxWidth: "40rem" }}
        >
          Al desconectar la cuenta no podrás recibir ni responder a los mesajes
          directos de instagram de esta cuenta. Los contactos o mensajes
          existentes no se borrarán
        </Text>
        <Button
          size="md"
          kind="secondary"
          disabled={isDisconnecting}
          onClick={async () => {
            setDisconnecting(true);
            const result = await functions.disconnectInstagramAccount({
              instagramAccountId: instagramAccount.id,
            });

            if (result.data.ok) {
              history.push("/settings/channels");
            }
          }}
        >
          {isDisconnecting ? "Desconectando" : "Desconectar canal"}
        </Button>
      </SettingsBox>
    </Wrapper>
  );
};

export default InstagramChannelConfigure;
