import React from "react";
import firebase from "firebase";
import styled from "styled-components";
import * as Dialog from "@radix-ui/react-dialog";
import {
  Contact,
  Conversation,
  WhatsappWebAccount,
  ComposingMessage,
  Message,
} from "common/src/types";
import { PickWhatsappTemplateCommandWindow } from "./whatsappTemplate";
import AssignConversationsCommandWindow from "./assignConversation";
import { CommandBarDialogContent } from "./common";
import AssignContactCommandWindow from "./assignContact";
import NewConversationCommandWindow from "./newConversation";
import SnoozeConversationCommandWindow from "./snoozeConversation";
import SavedRepliesCommandWindow from "./savedReplies";
import ScheduleMessage from "./scheduleMessage";
import AssignChannelAccount, {
  ChannelAccount,
} from "./assignWhatsappWebAccount";

const CommandBarDialogOverlay = styled(Dialog.Overlay)`
  background-color: ${(props) => props.theme.colors.gray700};
  opacity: 0.7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

type CommandBarStage =
  | null
  | "whatsappTemplate"
  | "assignConversation"
  | "assignContact"
  | "channelAccount"
  | "newConversation"
  | "savedReplies"
  | "snoozeConversation"
  | "scheduleMessage";

const CommandBarCtx = React.createContext<{
  stage: CommandBarStage;
  setStage: (stage: CommandBarStage) => void;
  scheduleMessage: (
    message: ComposingMessage,
    contactId: string,
    channel: Message["channel"],
    onFinish: () => void
  ) => void;
  openCommandBarAtStage: (stage: CommandBarStage) => void;
  openCommandBarForWhatsappTemplate: (contactId: string) => void;
  openAssignConversation: (conversation: {
    id: string;
    data: Conversation;
  }) => void;
  openToSnoozeConversation: (conversation: {
    id: string;
    data: Conversation;
  }) => void;
  openAssignContact: (contact: { id: string; data: Contact }) => void;
  openAssignChannelAccount: (
    channelAccount: ChannelAccount,
    multipleAssign?: boolean
  ) => void;
  openSavedReplies: (sendMessage: (message: ComposingMessage) => void) => void;
}>({
  stage: null,
  setStage: () => null,
  scheduleMessage: () => null,
  openCommandBarAtStage: () => null,
  openCommandBarForWhatsappTemplate: () => null,
  openAssignConversation: () => null,
  openToSnoozeConversation: () => null,
  openAssignContact: () => null,
  openAssignChannelAccount: () => null,
  openSavedReplies: () => null,
});

export const useCommandBar = () => React.useContext(CommandBarCtx);

const CommandBar = ({ children }: { children: React.ReactNode }) => {
  const [stage, setStage] = React.useState<CommandBarStage>(null);
  const [whatsappTemplateContactId, setWhatsappTemplateContactId] =
    React.useState<string>();
  const [contact, setContact] = React.useState<{ id: string; data: Contact }>();
  const [conversation, setConversation] = React.useState<{
    id: string;
    data: Conversation;
  }>();
  const replySelectedRef = React.useRef<(message: ComposingMessage) => void>();
  const [isMultipleAssign, setIsMultipleAssign] = React.useState(false);
  const [channelAccount, setChannelAccount] = React.useState<ChannelAccount>();
  const [messageToScheduleData, setMessageToScheduleData] = React.useState<{
    message: ComposingMessage;
    contactId: string;
    channel: Message["channel"];
    onFinish: () => void;
  }>();

  const openCommandBarAtStage = React.useCallback(
    (stage: CommandBarStage) => {
      setStage(stage);
    },
    [setStage]
  );

  const scheduleMessage = React.useCallback(
    (
      message: ComposingMessage,
      contactId: string,
      channel: Message["channel"],
      onFinish: () => void
    ) => {
      setMessageToScheduleData({ message, contactId, channel, onFinish });
      setStage("scheduleMessage");
    },
    []
  );

  const openAssignChannelAccount = React.useCallback(
    (channelAccount: ChannelAccount) => {
      setIsMultipleAssign(!!channelAccount.multiple);
      setChannelAccount(channelAccount);
      setStage("channelAccount");
    },
    []
  );

  const openAssignConversation = React.useCallback(
    (conversation: { id: string; data: Conversation }) => {
      setConversation(conversation);
      setStage("assignConversation");
    },
    [setConversation]
  );

  const openToSnoozeConversation = React.useCallback(
    (conversation: { id: string; data: Conversation }) => {
      setConversation(conversation);
      setStage("snoozeConversation");
    },
    []
  );

  const openAssignContact = React.useCallback(
    (contact: { id: string; data: Contact }) => {
      setContact(contact);
      setStage("assignContact");
    },
    []
  );

  const openCommandBarForWhatsappTemplate = React.useCallback(
    (contactId: string) => {
      setWhatsappTemplateContactId(contactId);
      setStage("whatsappTemplate");
    },
    [setStage, setWhatsappTemplateContactId]
  );

  const openSavedReplies = React.useCallback(
    (fn: (message: ComposingMessage) => void) => {
      replySelectedRef.current = fn;
      setStage("savedReplies");
    },
    []
  );

  const ctxVal = React.useMemo(() => {
    return {
      stage,
      setStage,
      openCommandBarAtStage,
      openCommandBarForWhatsappTemplate,
      openToSnoozeConversation,
      scheduleMessage,
      openAssignConversation,
      openAssignContact,
      openAssignChannelAccount,
      openSavedReplies,
    };
  }, [
    stage,
    setStage,
    openCommandBarAtStage,
    openCommandBarForWhatsappTemplate,
    openToSnoozeConversation,
    openAssignConversation,
    scheduleMessage,
    openAssignContact,
    openAssignChannelAccount,
    openSavedReplies,
  ]);

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setStage(null);
    }
  };

  return (
    <CommandBarCtx.Provider value={ctxVal}>
      {children}
      <Dialog.Root open={stage !== null} onOpenChange={handleOpenChange}>
        <Dialog.Portal>
          <CommandBarDialogOverlay />
          {stage !== "snoozeConversation" && (
            <CommandBarDialogContent>
              {whatsappTemplateContactId && stage === "whatsappTemplate" && (
                <PickWhatsappTemplateCommandWindow
                  contactId={whatsappTemplateContactId}
                  onSend={() => setStage(null)}
                />
              )}

              {stage === "assignConversation" && conversation && (
                <AssignConversationsCommandWindow
                  conversation={conversation}
                  onConversationAssigned={() => {
                    setConversation(undefined);
                    setStage(null);
                  }}
                />
              )}

              {stage === "assignContact" && contact && (
                <AssignContactCommandWindow
                  contact={contact}
                  onContactAssigned={() => {
                    setContact(undefined);
                    setStage(null);
                  }}
                />
              )}

              {stage === "channelAccount" && channelAccount && (
                <AssignChannelAccount
                  channelAccount={channelAccount}
                  multiple={isMultipleAssign}
                  onAccountAssigned={() => {
                    setChannelAccount(undefined);
                    setStage(null);
                  }}
                />
              )}

              {stage === "newConversation" && (
                <NewConversationCommandWindow onExit={() => setStage(null)} />
              )}
            </CommandBarDialogContent>
          )}
          {stage === "snoozeConversation" && conversation && (
            <SnoozeConversationCommandWindow
              conversation={conversation}
              onSnooze={() => {
                setStage(null);
              }}
            />
          )}

          {stage === "savedReplies" && replySelectedRef.current && (
            <>
              <SavedRepliesCommandWindow
                onReplySelected={(message) => {
                  replySelectedRef.current?.(message);
                  setStage(null);
                }}
              />
            </>
          )}

          {stage === "scheduleMessage" &&
            messageToScheduleData !== undefined && (
              <ScheduleMessage
                contactId={messageToScheduleData.contactId}
                channel={messageToScheduleData.channel}
                message={messageToScheduleData.message}
                onFinish={() => {
                  messageToScheduleData.onFinish();
                  setStage(null);
                }}
              />
            )}
        </Dialog.Portal>
      </Dialog.Root>
    </CommandBarCtx.Provider>
  );
};

export default CommandBar;
