import React from "react";
import { use100vh } from "react-div-100vh";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import Box from "ui/Box";
import Chat from "components/chat";
import Contacts from "components/contacts";
import CommandBar from "./commandBar";
import AppData from "./AppData";
import Settings from "./settings";
import { useAuth } from "lib/auth";
import Reporting from "./reporting";
import ImpersonateUser from "./ImpersonateUser";
import { ImagePreviewProvider } from "lib/imagePreview";
import { TypesenseProvider } from "lib/typesense";
import { OnboardingConnectFirstChannel } from "./onboarding/channels";
import OnboardingConnectWhatsapp from "./onboarding/whatsapp";
import OnboardingConnectInstagram from "./onboarding/instagram";
import OnboardingConnectMessenger from "./onboarding/messenger";
import OnboardingInviteTeamMembers from "./onboarding/inviteTeamMembers";
import ActivateTrial from "./onboarding/billing";
import Text from "ui/Text";
import Button from "ui/Button";
import dayjs from "dayjs";
import Modal from "ui/Modal";
import { useRESTClient } from "lib/restClient";
import NavigationBar from "./navigationBar";
import StripeCheckoutSuccess from "./StripeCheckoutSuccess";
import DownloadMobileApp from "./onboarding/downloadMobileApp";
import BusinessProfile from "./onboarding/businessProfile";
import { PresenceTracker } from "../lib/presence";
import { PromptComposer } from "./botBuilder";
import { isOnMobile } from "lib/responsive";
import AutomatedSequencesList from "./botBuilder/automatedSequencesList";
import {
  IDBConversationLoader,
  useIDBConversationLoader,
} from "lib/conversations";

const AuthenticatedAppWrapper = styled.div`
  width: 100vw;
  height: 94vh;
  display: flex;
  flex-direction: column;
`;

const BillingAlertWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.primary900};
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function BillingAlert() {
  const auth = useAuth();
  const restClient = useRESTClient();
  const [redirectingToCheckout, setRedirectingToCheckout] =
    React.useState(false);

  const goToCheckout = async () => {
    setRedirectingToCheckout(true);
    const response = await restClient.post<{ url: string }>(
      "/accounts/stripe-checkout-session"
    );
    setRedirectingToCheckout(false);
    window.location.href = response.data.url;
  };

  const hasBillingProfile = auth.workspace?.billingProfile !== undefined;
  const workspaceCreatedAt = auth.workspace?.createdAt;

  const trialDays = React.useMemo(() => {
    if (workspaceCreatedAt === undefined) {
      return 0;
    }

    let totalTrialDays = 60;

    if (workspaceCreatedAt > 1685287141) {
      totalTrialDays = 14;
    }

    return dayjs
      .unix(workspaceCreatedAt ?? 0)
      .add(totalTrialDays, "d")
      .diff(dayjs(), "d");
  }, [workspaceCreatedAt]);

  if (auth.workspace === undefined) {
    return null;
  }

  if (!hasBillingProfile && trialDays < 1) {
    return (
      <Modal open noClose>
        <Modal.Title>Período de prueba terminó</Modal.Title>
        <Modal.Description>
          Para continuar utilizando Zami activa tu cuenta haciendo click en el
          botón de abajo.
        </Modal.Description>

        <Modal.Actions>
          <Button
            size="md"
            block
            onClick={goToCheckout}
            isLoading={redirectingToCheckout}
            disabled={redirectingToCheckout}
          >
            Activar suscripción
          </Button>
        </Modal.Actions>
      </Modal>
    );
  } else if (auth.workspace.billingProfile?.subscriptionStatus === "canceled") {
    return null;
    return (
      <Modal open noClose>
        <Modal.Title>Activa tu cuenta de Zami</Modal.Title>
        <Modal.Description>
          Para continuar utilizando Zami activa tu cuenta haciendo click en el
          botón de abajo.
        </Modal.Description>

        <Modal.Actions>
          <Button
            size="md"
            block
            onClick={goToCheckout}
            isLoading={redirectingToCheckout}
            disabled={redirectingToCheckout}
          >
            Activar suscripción
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  if (!hasBillingProfile) {
    return (
      <BillingAlertWrapper>
        <Text size="text_sm" weight="medium" color="white">
          Tu período de prueba gratis termina en {trialDays} día
          {trialDays > 1 && "s"}
        </Text>

        <Button
          isLoading={redirectingToCheckout}
          disabled={redirectingToCheckout}
          style={{ marginLeft: "1.5rem" }}
          size="md"
          kind="secondary"
          onClick={goToCheckout}
        >
          Agregar método de pago
        </Button>
      </BillingAlertWrapper>
    );
  }

  return null;
}

const AuthenticatedApp = () => {
  const auth = useAuth();
  const [isCustomerOnboarding, setCustomerOnboarding] = React.useState(false);
  const height = use100vh();

  const shouldCustomerBeOnboarding = React.useMemo(() => {
    if (auth.workspace?.onboarding?.finishedOnboarding === false) {
      return true;
    }

    return (
      auth.workspace?.onboarding !== undefined &&
      auth.workspace.onboarding.connectedChannels === false
    );
  }, [auth.workspace]);

  React.useEffect(() => {
    if (shouldCustomerBeOnboarding) {
      setCustomerOnboarding(true);
    } else {
      setCustomerOnboarding(false);
    }
  }, [shouldCustomerBeOnboarding]);

  return (
    <AppData>
      <IDBConversationLoader>
        <PresenceTracker>
          <TypesenseProvider>
            <ImagePreviewProvider>
              <CommandBar>
                {isCustomerOnboarding ? (
                  <Switch>
                    <Route
                      path="/stripe-checkout-success"
                      component={StripeCheckoutSuccess}
                    />
                    <Route
                      path="/onboarding/activate-trial"
                      component={ActivateTrial}
                    />
                    {auth.workspace?.billingProfile && (
                      <Switch>
                        <Route
                          path="/onboarding/connect-first-channel"
                          component={OnboardingConnectFirstChannel}
                        />
                        <Route
                          path="/onboarding/connect-whatsapp"
                          component={OnboardingConnectWhatsapp}
                        />
                        <Route
                          path="/onboarding/connect-instagram"
                          render={() => (
                            <OnboardingConnectInstagram
                              onFinish={() => setCustomerOnboarding(false)}
                            />
                          )}
                        />
                        <Route
                          path="/onboarding/connect-messenger"
                          render={() => (
                            <OnboardingConnectMessenger
                              onFinish={() => setCustomerOnboarding(false)}
                            />
                          )}
                        />

                        <Route
                          path="/onboarding/invite-team-members"
                          component={OnboardingInviteTeamMembers}
                        />

                        <Route
                          path="/onboarding/download-mobile-app"
                          component={DownloadMobileApp}
                        />
                        <Redirect to="/onboarding/connect-first-channel" />
                      </Switch>
                    )}
                    {auth.workspace?.businessProfile === null && (
                      <Switch>
                        <Route
                          path="/onboarding/business-profile"
                          component={BusinessProfile}
                        />
                        <Redirect to="/onboarding/business-profile" />
                      </Switch>
                    )}
                    <Redirect to="/onboarding/activate-trial" />
                  </Switch>
                ) : (
                  <>
                    <AuthenticatedAppWrapper
                      style={{ height: height ?? undefined }}
                    >
                      <BillingAlert />
                      <Box
                        flex
                        direction="row"
                        cols={1}
                        style={{ minHeight: 0, overflow: "hidden" }}
                      >
                        {!isOnMobile() && <NavigationBar />}
                        <Switch>
                          <Route
                            path="/impersonate-user/:authToken"
                            component={ImpersonateUser}
                          />
                          <Route
                            path="/chat/:inbox/:selectedConversationId"
                            component={Chat}
                          />
                          <Route path="/chat/:inbox" component={Chat} />
                          <Route path="/contacts" component={Contacts} />
                          <Route path="/settings" component={Settings} />
                          <Route path="/reports" component={Reporting} />
                          {auth.workspace?.featureFlags?.aiBotBuilder && (
                            <Switch>
                              <Route
                                exact
                                path="/ai"
                                component={AutomatedSequencesList}
                              />
                              <Route
                                path="/ai/:automatedSequenceId/prompt-composer"
                                component={PromptComposer}
                              />
                            </Switch>
                          )}
                          <Route
                            path="/stripe-checkout-success"
                            component={StripeCheckoutSuccess}
                          />
                          <Redirect to="/chat/inbox" />
                        </Switch>
                      </Box>
                    </AuthenticatedAppWrapper>
                  </>
                )}
              </CommandBar>
            </ImagePreviewProvider>
          </TypesenseProvider>
        </PresenceTracker>
      </IDBConversationLoader>
    </AppData>
  );
};

export default AuthenticatedApp;
