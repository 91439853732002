import React from "react";
import styled from "styled-components";
import Box from "./Box";
import Text from "./Text";

const FieldWrapper = styled(Box)`
  margin-bottom: 1.25rem;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const Field = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactElement;
}) => {
  return (
    <FieldWrapper>
      <Text
        size="text_sm"
        weight="medium"
        marginBottom="0.25rem"
        color="gray700"
      >
        {label}
      </Text>

      {children}
    </FieldWrapper>
  );
};

export default Field;
