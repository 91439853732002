import React from "react";
import styled, { DefaultTheme, css } from "styled-components";

import Text, { TextSize } from "ui/Text";

type BadgeProps = {
  variant?: "success" | "error" | "pink" | "gray100";
  backgroundColor?: keyof DefaultTheme["colors"];
  type?: "circle" | "pill";
  size: "lg" | "sm" | "xs";
  label: string | React.ReactNode;
};

const Wrapper = styled.div<Partial<BadgeProps>>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  background-color: ${(props) => props.theme.colors.gray100};

  ${(props) =>
    props.type === "circle" &&
    css`
      border-radius: 50%;
    `}

  ${(props) =>
    props.type === "pill" &&
    css`
      border-radius: 9999px;
    `}

  ${(props) =>
    props.variant === "success" &&
    css`
      background-color: ${props.theme.colors.success50};

      .text {
        color: ${props.theme.colors.success700};
        font-weight: medium;
      }
    `}

  ${(props) =>
    props.variant === "pink" &&
    css`
      background-color: ${props.theme.colors.pink50};

      .text {
        color: ${props.theme.colors.pink700};
        font-weight: medium;
      }
    `}

  ${(props) =>
    props.size === "sm" &&
    css`
      padding: 2px 8px;
    `}

    ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.theme.colors[props.backgroundColor]};
    `}
`;

const fontSizes: Record<BadgeProps["size"], TextSize> = {
  lg: "text_sm",
  sm: "text_xs",
  xs: "text_xxs",
};

const Badge = ({
  backgroundColor,
  label,
  type = "pill",
  variant,
  size,
}: BadgeProps) => {
  return (
    <Wrapper
      variant={variant}
      size={size}
      type={type}
      backgroundColor={backgroundColor}
    >
      <Text className="text" size={fontSizes[size]} weight="medium">
        {label}
      </Text>
    </Wrapper>
  );
};

export default Badge;
