import React from "react";
import { OnboardingStyles } from "./common";

export default function ActivateProposal() {
  return (
    <div>
      <OnboardingStyles />
    </div>
  );
}
