import React from "react";
import posthog from "posthog-js";
import dayjs from "dayjs";
import "dayjs/locale/es-us";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import "flag-icons/css/flag-icons.min.css";

const isDevelopmentBuild =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

if (!isDevelopmentBuild) {
  Sentry.init({
    dsn: "https://f500b136e1944d1ea297af9451e01750@o4504794103611392.ingest.sentry.io/4504794106494976",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });

  posthog.init("phc_evOOxCjBE6pEJBEKqnYgiOIeTC3YeFGtZrlbf1TqDyJ", {
    api_host: "https://app.posthog.com",
  });
  posthog.capture("App Loaded");
}

dayjs.locale("es-us");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
