import styled, { css } from "styled-components";
import * as Dialog from "@radix-ui/react-dialog";
import Box from "ui/Box";
import theme from "ui/theme";

export const CommandBarHeader = styled.div`
  height: 3.5rem;
  display: flex;
  align-items: center;
  padding: 0rem 1rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
`;

export const CommandBarFooter = styled.div`
  padding: 16px;
  border: 1px solid ${theme.colors.gray200};
`;

export const BorderlessInput = styled.input`
  border: none;
  font-size: 16px;
  line-height: 24px;
  margin-left: 0.5rem;

  &:placeholder {
    color: ${(props) => props.theme.colors.gray500};
    font-size: ;
  }

  &:focus {
    outline: none;
  }
`;

export const MenuItem = styled(Box)<{ highlighted?: boolean }>`
  padding: 0.5rem;
  padding-left: 14px;
  border-radius: 8px;
  margin: 0.5rem;
  cursor: pointer;

  ${(props) =>
    props.highlighted &&
    css`
      background-color: ${(props) => props.theme.colors.gray50};
    `}

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }
`;

export const CommandBarDialogContent = styled(Dialog.Content)`
  position: fixed;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  width: 820px;
  border-radius: 12px;
  overflow: hidden;
`;
