import React from "react";
import axios from "axios";
import { useAuth } from "./auth";

const isDevelopmentBuild =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const baseURL = isDevelopmentBuild
  ? "http://localhost:8082"
  : "https://rest.zamiapp.com";

const RESTClientContext = React.createContext(
  axios.create({
    baseURL,
  })
);

export function RESTClientProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const auth = useAuth();
  const [idToken, setIdToken] = React.useState<string>();

  const client = React.useMemo(() => {
    return axios.create({
      baseURL,
      headers:
        idToken === undefined
          ? undefined
          : {
              Authorization: `Bearer ${idToken}`,
            },
    });
  }, [idToken]);

  React.useEffect(() => {
    if (auth.user) {
      auth.user.getIdToken().then(setIdToken);
      const interval = setInterval(() => {
        if (auth.user) {
          auth.user.getIdToken().then(setIdToken);
        }
      }, 600 * 1000);

      return () => clearInterval(interval);
    } else {
    }
  }, [auth.user]);

  return (
    <RESTClientContext.Provider value={client}>
      {children}
    </RESTClientContext.Provider>
  );
}

export function useRESTClient() {
  return React.useContext(RESTClientContext);
}
