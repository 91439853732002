import styled, { useTheme } from "styled-components";
import { v4 as uuid } from "uuid";
import Box from "ui/Box";
import Text from "ui/Text";
import { ReactComponent as LogoFull } from "assets/icons/logoFull.svg";
import ProgressWindow from "./progressWindow";
import { BorderlessInput } from "components/commandBar/common";
import { OnboardingStyles } from "./common";
import Icon from "ui/Icon";
import React from "react";
import Dropdown from "ui/Dropdown";
import Button from "ui/Button";
import { useRESTClient } from "lib/restClient";
import { useHistory } from "react-router-dom";

const MemberInvitationWrapper = styled(Box)`
  border: solid 1px ${(props) => props.theme.colors.gray300};
  border-radius: 8px;
  background-color: white;
  display: inline-flex;
  align-items: center;
`;

function RoleDropdownItem(props: {
  name: string;
  description: string;
  selected?: boolean;
  onClick?: () => void;
}) {
  return (
    <Dropdown.Item onClick={props.onClick}>
      <Box flex alignItems="center">
        <Box>
          <Text size="text_md" weight="medium" marginBottom="0.5rem">
            {props.name}
          </Text>

          <Text
            size="text_sm"
            weight="regular"
            color="gray600"
            style={{ maxWidth: 330 }}
          >
            {props.description}
          </Text>
        </Box>

        {props.selected && (
          <Box marginLeft="0.5rem">
            <Icon icon="check" stroke="primary600" />
          </Box>
        )}
      </Box>
    </Dropdown.Item>
  );
}

function WorkspaceMemberInvitation(props: {
  email: string;
  role: string;
  onChange: (a: {
    email?: string | undefined;
    role?: string | undefined;
  }) => unknown;
}) {
  const theme = useTheme();

  return (
    <MemberInvitationWrapper>
      <Box
        padding="0.5rem 0.75rem"
        style={{ borderRight: `solid 1px ${theme.colors.gray300}` }}
      >
        <BorderlessInput
          type="email"
          placeholder="email@ejemplo.com"
          value={props.email}
          style={{ minWidth: 360 }}
          onChange={(e) => {
            props.onChange({ email: e.target.value });
          }}
        />
      </Box>

      <Dropdown
        align="start"
        sideOffset={14}
        trigger={
          <div>
            <Box
              padding="0rem 1rem"
              flex
              alignItems="center"
              style={{ cursor: "pointer" }}
            >
              <Text size="text_sm" weight="medium" marginRight="0.5rem">
                {props.role === "admin" ? "Administrador" : "Miembro"}
              </Text>

              <Icon icon="chevronDown" stroke="gray400" />
            </Box>
          </div>
        }
      >
        <RoleDropdownItem
          name="Administrador"
          description="Tiene acceso total a la configuración de la cuenta, puede agregar y eliminar miembros y editar roles."
          selected={props.role === "admin"}
          onClick={() => props.onChange({ role: "admin" })}
        />

        <RoleDropdownItem
          name="Miembro"
          description="No tiene permisos administrativos, puede atender a los clientes a través de los canales de comunicación de la cuenta."
          selected={props.role === "member"}
          onClick={() => props.onChange({ role: "member" })}
        />
      </Dropdown>
    </MemberInvitationWrapper>
  );
}

export default function OnboardingInviteTeamMembers() {
  const [loading, setLoading] = React.useState(false);
  const [invitations, setInvitations] = React.useState<
    Record<
      string,
      {
        email: string;
        role: string;
      }
    >
  >({
    [uuid()]: {
      email: "",
      role: "",
    },

    [uuid()]: {
      email: "",
      role: "",
    },

    [uuid()]: {
      email: "",
      role: "",
    },
  });

  const hasAtleastOneInvitation = React.useMemo(() => {
    return Object.values(invitations).some((invitation) => invitation.email);
  }, [invitations]);

  const restClient = useRESTClient();
  const history = useHistory();

  const sendInvitations = React.useCallback(async () => {
    setLoading(true);
    await restClient.post("/accounts/invite-team-members", {
      invitations: Object.values(invitations).filter((inv) => inv.email),
    });
    history.push("/onboarding/download-mobile-app");
  }, [restClient, invitations, history]);

  return (
    <ProgressWindow currentStep={3}>
      <OnboardingStyles />
      <form
        style={{ height: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
          sendInvitations();
        }}
      >
        <Box style={{ height: "100%" }} flex direction="column">
          <Box
            marginTop="4rem"
            marginBottom="4rem"
            paddingLeft="3rem"
            paddingRight="3rem"
            cols={1}
          >
            <Box marginBottom="3rem">
              <LogoFull />
            </Box>
            <Text
              size="display_sm"
              weight="semibold"
              marginTop="1.5rem"
              marginBottom="0.75rem"
              color="primary900"
            >
              Agrega a los miembros de tu equipo.
            </Text>

            <Text size="text_lg" color="gray600" style={{ marginBottom: 40 }}>
              Cada miembro recibirá una invitación por correo para ingresar y
              atender a los clientes a través de Zami.
            </Text>

            {Object.entries(invitations).map(([key, invitation]) => (
              <Box marginBottom="1.5rem">
                <WorkspaceMemberInvitation
                  email={invitation.email}
                  role={invitation.role}
                  onChange={({ role, email }) => {
                    if (role !== undefined) {
                      setInvitations({
                        ...invitations,
                        [key]: {
                          ...invitation,
                          role,
                        },
                      });
                    }

                    if (email !== undefined) {
                      setInvitations({
                        ...invitations,
                        [key]: {
                          ...invitation,
                          email,
                        },
                      });
                    }
                  }}
                />
              </Box>
            ))}

            <Box
              alignItems="center"
              marginTop="1.5rem"
              style={{ display: "inline-flex", cursor: "pointer" }}
              onClick={() => {
                setInvitations({
                  ...invitations,
                  [uuid()]: {
                    email: "",
                    role: "",
                  },
                });
              }}
            >
              <Icon icon="plus" stroke="primary700" />

              <Text
                size="text_md"
                weight="semibold"
                color="primary700"
                marginLeft="0.5rem"
              >
                Agregar otro
              </Text>
            </Box>
          </Box>

          <Box
            borderTop
            padding="1.5rem 0rem"
            flex
            justifyContent="flex-end"
            paddingRight="1.5rem"
          >
            <Box
              marginRight="20px"
              flex
              alignItems="center"
              padding="0 1rem"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push("/onboarding/download-mobile-app");
              }}
            >
              <Text size="text_sm" weight="semibold" color="gray600">
                Saltar por ahora
              </Text>
            </Box>

            <Button
              kind="primary"
              size="md"
              icon="send"
              title="Enviar invitaciones"
              type="submit"
              disabled={loading || !hasAtleastOneInvitation}
              isLoading={loading}
            >
              Enviar invitaciones
            </Button>
          </Box>
        </Box>
      </form>
    </ProgressWindow>
  );
}
