import ProfilePic from "components/ProfilePic";
import { capitalize, orderBy } from "lodash";
import firebase from "firebase";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import styled from "styled-components";
import Box from "ui/Box";
import Icon from "ui/Icon";
import Modal from "ui/Modal";
import Text from "ui/Text";
import { MessageBubble } from "./Message";
import { formatDiagnosticsWithColorAndContext } from "typescript";
import db from "lib/db";
import { useAuth } from "lib/auth";
import { ScheduledMessage } from "common/src/types";

const ScheduledMessagesWrapper = styled.div`
  padding: 0.75rem;
  display: flex;
  align-items: center;
`;

const LinkButton = styled.div`
  color: ${(props) => props.theme.colors.primary700};
  cursor: pointer;
  font-weight: 600;
  margin-left: 0.5rem;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const OutgoingMessageBubble = styled(MessageBubble)`
  background-color: ${(props) => props.theme.colors.primary100};
  color: ${(props) => props.theme.colors.primary800};
`;

const BorderWrapper = styled.div`
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
  &:last-child {
    border-bottom: none;
  }
`;

const ActionMenu = styled.div`
  border: solid 1px ${(props) => props.theme.colors.gray200};
  border-radius: 8px;
  background-color: white;
  padding: 0.5rem 1rem;
  position: absolute;
  right: 0.5rem;
  top: 0px;
  z-index: 1;
  display: none;
`;

const ActionIcon = styled.div`
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: ${(props) => props.theme.colors.gray100};
  }
`;

const ScheduledMessageWrapper = styled.div`
  display: flex;
  padding: 1rem;
  margin: 0.5rem 0rem;
  border-radius: 8px;
  position: relative;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};

    ${ActionMenu} {
      display: flex;
    }
  }
`;

function formatDate(date: Dayjs) {
  const isToday = date.isSame(dayjs(), "day");

  if (isToday) {
    return "hoy";
  }
  const isTomorrow = date.add(1, "day").isSame(date, "day");
  if (isTomorrow) {
    return "mañana";
  }

  return date.format("dddd D [de] MMMM");
}

function ScheduledMessagesModal(props: {
  open: boolean;
  scheduledMessages: firebase.firestore.QueryDocumentSnapshot<ScheduledMessage>[];
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Modal
      contentStyle={{ maxWidth: 640 }}
      open={props.open}
      onOpenChange={props.onOpenChange}
    >
      <Modal.Title>Mensajes programados</Modal.Title>

      <Box marginTop="1rem">
        {props.scheduledMessages.map((scheduledMessage) => {
          const messageData = scheduledMessage.data().message;
          return (
            <BorderWrapper>
              <ScheduledMessageWrapper>
                <ActionMenu>
                  <ActionIcon
                    onClick={async () => {
                      await scheduledMessage.ref.delete();
                      if (props.scheduledMessages.length === 1) {
                        props.onOpenChange(false);
                      }
                    }}
                  >
                    <Icon size={24} icon="trash" stroke="gray700" />
                  </ActionIcon>
                </ActionMenu>
                <ProfilePic
                  name="Luis"
                  id="asdf"
                  showChannelBadgeFor="whatsapp"
                />
                <Box marginLeft="0.75rem" cols={1}>
                  <Box flex>
                    <Text
                      size="text_sm"
                      weight="medium"
                      marginBottom="0.25rem"
                      cols={1}
                    >
                      Tú
                    </Text>

                    <Text size="text_xs" color="gray600" marginRight="0.25rem">
                      Enviar{" "}
                      {formatDate(dayjs.unix(scheduledMessage.data().sendAt))} a
                      las{" "}
                      {dayjs
                        .unix(scheduledMessage.data().sendAt)
                        .format("h:mm a")
                        .toUpperCase()}
                    </Text>
                    <Icon icon="clockSnooze" />
                  </Box>

                  <OutgoingMessageBubble>
                    {messageData.type === "text" && messageData.message}
                  </OutgoingMessageBubble>
                </Box>
              </ScheduledMessageWrapper>
            </BorderWrapper>
          );
        })}
      </Box>
    </Modal>
  );
}

export function ScheduledMessageComposerIndicator(props: {
  contactId: string;
}) {
  const auth = useAuth();
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [scheduledMessages, setScheduledMessages] = React.useState<
    firebase.firestore.QueryDocumentSnapshot<ScheduledMessage>[]
  >([]);

  React.useEffect(() => {
    if (auth.workspaceId) {
      return db.scheduledMessages
        .where("workspaceId", "==", auth.workspaceId)
        .where("contactId", "==", props.contactId)
        .where("sentAt", "==", null)
        .onSnapshot((snap) => {
          setScheduledMessages(snap.docs);
        });
    }
  }, [auth.workspaceId, props.contactId]);

  const nextMessage = React.useMemo(() => {
    if (scheduledMessages.length > 0) {
      const nextMessage = orderBy(
        scheduledMessages.map((message) => {
          return {
            ts: message.data().sendAt,
            message,
          };
        }),
        "ts",
        "asc"
      )[0].message;

      return nextMessage.data();
    }

    return null;
  }, [scheduledMessages]);

  return (
    <>
      <ScheduledMessagesModal
        open={isModalOpen}
        onOpenChange={setModalOpen}
        scheduledMessages={scheduledMessages}
      />
      {nextMessage && (
        <ScheduledMessagesWrapper>
          <Icon icon="clockSnooze" />
          <Text
            size="text_sm"
            marginLeft="0.5rem"
            style={{ whiteSpace: "nowrap" }}
          >
            {capitalize(formatDate(dayjs.unix(nextMessage.sendAt)))} a las{" "}
            {dayjs.unix(nextMessage.sendAt).format("h:mm a")}
          </Text>

          <LinkButton
            onClick={() => setModalOpen(true)}
            style={{ whiteSpace: "nowrap" }}
          >
            Ver mensajes programados
          </LinkButton>
        </ScheduledMessagesWrapper>
      )}
    </>
  );
}
