import styled, { DefaultTheme, css } from "styled-components";

export const THead = styled("thead")<{
  backgroundColor?: keyof DefaultTheme["colors"];
}>`
  position: sticky;
  top: 0;

  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
`;

export const Table = styled("table")`
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: auto;
`;

export const Caption = styled("caption")`
  text-align: start;
  margin-bottom: 1.5rem;
`;

export const TBody = styled("tbody")`
  width: 100%;
`;

export const TFoot = styled("tfoot")``;

export const Tr = styled("tr")``;

type ThProps = {
  textAlign?: "start" | "center" | "end";
};

export const Th = styled("th")<ThProps>`
  text-align: start;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.gray600};
  padding: 0.75rem 1.5rem;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.gray200}`};
  position: sticky;
  top: 0;

  ${(props) =>
    props.textAlign === "start" &&
    css`
      text-align: start;
    `};

  ${(props) =>
    props.textAlign === "center" &&
    css`
      text-align: center;
    `}

  ${(props) =>
    props.textAlign === "end" &&
    css`
      text-align: end;
    `}
`;

type TdProps = {
  textAlign?: "start" | "center" | "end";
};

export const Td = styled("td")<TdProps>`
  padding: 1.5rem;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.gray200}`};
  color: ${(props) => props.theme.colors.gray600};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: start;

  ${(props) =>
    props.textAlign === "start" &&
    css`
      text-align: start;
    `};

  ${(props) =>
    props.textAlign === "center" &&
    css`
      text-align: center;
    `}

  ${(props) =>
    props.textAlign === "end" &&
    css`
      text-align: end;
    `}
`;
