import React from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as ZamiLogo } from "assets/logo.svg";
import helpQRCodeImage from "assets/zami-help-qr-code.png";
import styled from "styled-components";
import NavItem from "ui/NavItem";
import { useAppData } from "./AppData";
import { useAuth } from "lib/auth";
import Box from "ui/Box";
import Dropdown from "ui/Dropdown";
import ProfilePic from "./ProfilePic";
import Text from "ui/Text";
import Icon from "ui/Icon";
import Modal from "ui/Modal";
import db from "../lib/db";

const StyledNavItem = styled(NavItem)`
  margin-bottom: 0.5rem;
`;

const NavigationBarWrapper = styled.div`
  width: 5rem;
  border-right: solid 1px ${(props) => props.theme.colors.gray200};
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  justify-content: space-between;
  align-items: center;
`;

const QRWrapper = styled(Box)`
  background-color: ${(props) => props.theme.colors.gray50};
  border: solid 1px ${(props) => props.theme.colors.gray200};
  border-radius: 12px;
  padding: 1rem;

  img {
    width: 400px;
  }
`;

function HelpModal(props: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  return (
    <Modal
      contentStyle={{ maxWidth: 480 }}
      open={props.open}
      onOpenChange={props.onOpenChange}
    >
      <Modal.Title>¿Necesitas ayuda?</Modal.Title>

      <Text size="text_md" weight="semibold" marginTop="1.5rem">
        Escanea el código QR para comunicarte con nosotros a través de WhatsApp.
      </Text>

      <QRWrapper marginTop="1rem">
        <img src={helpQRCodeImage} alt="QR Code" />
      </QRWrapper>
    </Modal>
  );
}

const NavigationBar = () => {
  const location = useLocation();
  const appData = useAppData();
  const self = appData.workspaceMemberSelf;
  const [helpModalOpen, setHelpModalOpen] = React.useState(false);
  const auth = useAuth();

  const handleChangeUserStatus = async () => {
    const workspaceMemberDocRef = db.workspaceMembers.doc(self.id);

    if (self.data().presence === "online") {
      await workspaceMemberDocRef.update({
        presence: "away",
      });

      return;
    }

    await workspaceMemberDocRef.update({
      presence: "online",
    });
  };

  return (
    <NavigationBarWrapper>
      <HelpModal open={helpModalOpen} onOpenChange={setHelpModalOpen} />
      <Box flex direction="column" alignItems="center">
        <ZamiLogo />
        <Box marginTop="1.5rem">
          <StyledNavItem
            icon="inbox"
            href="/chat"
            active={location.pathname.startsWith("/chat")}
          />
          {auth.workspace?.featureFlags?.aiBotBuilder && (
            <StyledNavItem
              icon="aiStars"
              href="/ai"
              active={location.pathname.startsWith("/ai")}
            />
          )}
          <StyledNavItem
            icon="contacts"
            href="/contacts"
            active={location.pathname.startsWith("/contacts")}
          />
          {appData.permissions.canSeeReporting && (
            <StyledNavItem
              icon="reports"
              href="/reports"
              active={location.pathname.startsWith("/reports")}
            />
          )}
        </Box>
      </Box>

      <Box flex direction="column" alignItems="center">
        <StyledNavItem
          icon="settings"
          href="/settings"
          active={location.pathname.startsWith("/settings")}
        />
        {/* <div onClick={() => auth.logOut?.()}> */}
        <Dropdown
          side="right"
          trigger={
            <div style={{ cursor: "pointer" }}>
              <ProfilePic
                id={self.id}
                name={self.data().name}
                imageLocation={undefined}
                showStatusIcon
                status={self.data().presence}
              />
            </div>
          }
        >
          <Box
            flex
            marginLeft="-0.5rem"
            marginRight="-0.5rem"
            paddingLeft="1rem"
            paddingRight="1rem"
            paddingTop="0.5rem"
            paddingBottom="1rem"
            borderBottom
          >
            <ProfilePic
              name={self.data().name}
              id={self.id}
              showStatusIcon
              status={self.data().presence}
            />

            <Box marginLeft="0.75rem">
              <Text size="text_sm" weight="semibold">
                {self.data().name}
              </Text>
              <Text size="text_sm" color="gray600">
                {auth.user?.email}
              </Text>
            </Box>
          </Box>

          <Box paddingTop="0.5rem">
            <Box marginBottom="0.25rem">
              <Dropdown.Item onClick={handleChangeUserStatus}>
                <Box marginRight="0.5rem">
                  <Icon icon="userStatus" />
                </Box>
                <Text size="text_sm" weight="medium" color="gray700">
                  Cambiar a{" "}
                  <Text size="text_sm" weight="semibold" inline>
                    {self.data().presence === "online"
                      ? "inactivo"
                      : "disponible"}
                  </Text>
                </Text>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setHelpModalOpen(true)}>
                <Box marginRight="0.5rem">
                  <Icon icon="help" />
                </Box>
                <Text size="text_sm" weight="medium" color="gray700">
                  Ayuda
                </Text>
              </Dropdown.Item>
            </Box>
            <Dropdown.Item onClick={() => auth.logOut?.()}>
              <Box marginRight="0.5rem">
                <Icon icon="logOut" />
              </Box>
              <Text size="text_sm" weight="medium" color="gray700">
                Cerrar sesión
              </Text>
            </Dropdown.Item>
          </Box>
        </Dropdown>
        {/* </div> */}
      </Box>
    </NavigationBarWrapper>
  );
};

export default NavigationBar;
