import React from "react";
import * as Avatar from "@radix-ui/react-avatar";
import axios from "axios";
import { ReactComponent as MessengerLogo } from "assets/channelLogos/messenger.svg";
import igPlaceholderImage from "assets/igplaceholder.jpeg";
import { ReactComponent as Check2Icon } from "assets/icons/check2.svg";
import styled from "styled-components";
import Box from "ui/Box";
import {
  Breadcrumbs,
  BreadcrumbChevron,
  BreadcrumbCurrentPage,
  BreadcrumbLink,
} from "ui/Breadcrumbs";
import Text from "ui/Text";
import Button from "ui/Button";
import config from "config";
import { CORE_URL, functions } from "lib/db";
import { useAuth } from "lib/auth";
import { FacebookPage } from "common/src/types";

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: 70rem;
  padding: 0rem 2rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
`;

const MessengerRequirementsHelp = styled.div`
  background-color: ${(props) => props.theme.colors.gray50};
  border-radius: 12px;
  padding: 2rem 1.5rem;
  margin-top: 2rem;
`;

const IGWrapper = styled.div`
  padding: 1rem 0rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
`;

const IgPic = styled(Avatar.Image)`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`;

const Pic = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`;

const IgFallback = styled(Avatar.Fallback)`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`;

const Root = styled(Avatar.Root)`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
`;

interface FBPage {
  access_token: string;
  id: string;
  name: string;
  picture: {
    data: {
      url: string;
    };
  };
}

interface UseConnectMessengerOptions {
  autoConnectAccounts: boolean;
}

export function useConnectMessenger(opts?: UseConnectMessengerOptions) {
  const auth = useAuth();
  const [loginLoading, setLoginLoading] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState<string>();
  const [facebookPages, setFacebookPages] = React.useState<FBPage[]>();
  const [connectedFacebookPages, setConnectedFacebookPages] = React.useState<
    Record<string, { id: string } & Exclude<FacebookPage, "fbPageAccessToken">>
  >({});
  const [messengerConnectionStatuses, setMessengerConnectionStatuses] =
    React.useState<Record<string, undefined | "loading">>({});
  const [autoConnectStatus, setAutoConnectStatus] = React.useState<
    "new" | "loading" | "error" | "done" | "no_accounts"
  >("new");

  React.useEffect(() => {
    FB.init({
      appId: config.fbAppId,
      cookie: true,
      xfbml: true,
      version: "v16.0",
    });
  }, []);

  const handleConnect = React.useCallback(() => {
    setLoginLoading(true);
    FB.login(
      (response) => {
        const accessToken = response.authResponse.accessToken;
        setAccessToken(accessToken);
      },
      {
        scope: "pages_manage_metadata,pages_read_engagement,pages_messaging",
      }
    );
  }, []);

  const handleConnectFbPage = React.useCallback(
    async (fbPage: FBPage) => {
      setMessengerConnectionStatuses({
        [fbPage.id]: "loading",
      });
      try {
        const result = await functions.connectFacebookPageForMessenger({
          fbUserAccessToken: fbPage.access_token,
          fbPageId: fbPage.id,
          workspaceId: auth.workspaceId!,
        });

        if (result.ok) {
          setConnectedFacebookPages(
            result.facebookPages.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.fbPageId]: true,
              };
            }, {})
          );
        }
      } catch (err) {}

      setMessengerConnectionStatuses({
        [fbPage.id]: undefined,
      });
    },
    [auth.workspaceId]
  );

  React.useEffect(() => {
    (async () => {
      if (opts?.autoConnectAccounts) {
        if (facebookPages === undefined) {
          return;
        } else if (facebookPages.length === 0) {
          setAutoConnectStatus("no_accounts");
          return;
        }

        const facebookPagesToConnect = facebookPages!.filter(
          (fbPage) => !connectedFacebookPages[fbPage.id]
        );

        setAutoConnectStatus("loading");

        console.log("Starting loop");

        try {
          if (facebookPagesToConnect.length > 0) {
            for (const fbPage of facebookPagesToConnect) {
              console.log(
                `Connect facebook page: ${fbPage.name} (${fbPage.id})`
              );
              await handleConnectFbPage(fbPage);
            }
          }

          setAutoConnectStatus("done");
        } catch (err) {
          setAutoConnectStatus("error");
        }
      }
    })();
  }, [
    opts?.autoConnectAccounts,
    facebookPages,
    handleConnect,
    connectedFacebookPages,
    handleConnectFbPage,
  ]);

  React.useEffect(() => {
    (async () => {
      if (auth.workspaceId) {
        const result = await functions.getFacebookPages(auth.workspaceId);
        setConnectedFacebookPages(
          result.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.fbPageId]: true,
            };
          }, {})
        );
      }
    })();
  }, [auth.workspaceId]);

  React.useEffect(() => {
    if (accessToken) {
      (async () => {
        const resp = await axios.get<FBPage[]>(`${CORE_URL}/facebook-pages`, {
          params: {
            access_token: accessToken,
            fields: "access_token,name,id,picture",
          },
        });

        setLoginLoading(false);
        setFacebookPages(resp.data);
      })();
    }
  }, [accessToken]);

  return {
    loginLoading,
    handleConnect,
    facebookPages,
    messengerConnectionStatuses,
    handleConnectFbPage,
    autoConnectStatus,
    connectedFacebookPages,
    setConnectedFacebookPages,
    setMessengerConnectionStatuses,
  };
}

function MessengerSettings() {
  const {
    loginLoading,
    handleConnect,
    facebookPages,
    messengerConnectionStatuses,
    connectedFacebookPages,
    handleConnectFbPage,
  } = useConnectMessenger();

  return (
    <Wrapper>
      <Heading>
        <Breadcrumbs marginBottom="20px">
          <BreadcrumbLink to="/settings/channels">Canales</BreadcrumbLink>
          <BreadcrumbChevron />
          <BreadcrumbCurrentPage>Facebook Messenger</BreadcrumbCurrentPage>
        </Breadcrumbs>
        <Box flex alignItems="center">
          <MessengerLogo />
          <Box marginLeft="20px" cols={1}>
            <Text size="display_sm" weight="semibold">
              Facebook Messenger
            </Text>
            <Text size="text_md" color="gray600">
              Recibe tus mensajes directos en Zami.
            </Text>
          </Box>
          <Box flex alignItems="center">
            <Button
              isLoading={loginLoading}
              disabled={loginLoading}
              icon="puzzlePiece"
              size="md"
              onClick={handleConnect}
            >
              Conectar Messenger
            </Button>
          </Box>
        </Box>
      </Heading>

      <Box cols={1} style={{ overflowY: "auto", minHeight: 0 }}>
        <MessengerRequirementsHelp>
          <Text size="text_xl" weight="semibold" marginBottom="1.5rem">
            Para conectar Facebook Messenger tienes que cumplir con los
            siguientes requisitos:
          </Text>

          <Box flex alignItems="center" marginBottom="20px">
            <Check2Icon />
            <Text color="gray600" marginLeft="0.75rem">
              Tener al menos una página de Facebook.
            </Text>
          </Box>

          <Box flex alignItems="center">
            <Check2Icon />
            <Text color="gray600" marginLeft="0.75rem">
              Ser el administrador de la página de Facebook.
            </Text>
          </Box>
        </MessengerRequirementsHelp>

        {facebookPages !== undefined && (
          <Box marginTop="2rem">
            <Text size="text_xl" weight="semibold" marginBottom="0.25rem">
              Selecciona la página de Facebook que quieras conectar a Zami.
            </Text>
            <Text size="text_md" color="gray600" marginBottom="1.5rem">
              Encontramos {facebookPages.length} páginas de Facebook
              administradas por ti.
            </Text>
            {facebookPages.map((fbPage) => {
              return (
                <IGWrapper>
                  <Box flex alignItems="center">
                    <Root>
                      <IgPic src={fbPage.picture.data.url} />
                      <IgFallback>
                        <Pic src={igPlaceholderImage} />
                      </IgFallback>
                    </Root>

                    <Text size="text_md" weight="semibold" cols={1}>
                      {fbPage.name}
                    </Text>
                    <Button
                      size="sm"
                      disabled={Boolean(
                        connectedFacebookPages[fbPage.id] ||
                          messengerConnectionStatuses[fbPage.id] === "loading"
                      )}
                      isLoading={
                        messengerConnectionStatuses[fbPage.id] === "loading"
                      }
                      onClick={() => handleConnectFbPage(fbPage)}
                    >
                      {connectedFacebookPages[fbPage.id] !== undefined
                        ? "Connected"
                        : "Conectar"}
                    </Button>
                  </Box>
                </IGWrapper>
              );
            })}
          </Box>
        )}
      </Box>
    </Wrapper>
  );
}

export default MessengerSettings;
