import firebase from "firebase";
import ProfilePic from "components/ProfilePic";
import db, { functions } from "lib/db";
import React, { FormEvent } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import Box from "ui/Box";
import Button from "ui/Button";
import Field from "ui/Field";
import Input from "ui/Input";
import Text from "ui/Text";
import { WorkspaceInvite } from "common/src/types";

const InvitationWrapper = styled.div`
  max-width: 22.5rem;
  margin: 0 auto;
`;

const WorkspaceInvitationPage = ({
  match,
}: RouteComponentProps<{ invitationId: string }>) => {
  const [name, setName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [workspaceInvite, setWorkspaceInvite] =
    React.useState<firebase.firestore.DocumentSnapshot<WorkspaceInvite>>();
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const workspaceInvite = await db.workspaceInvites
        .doc(match.params.invitationId)
        .get();
      setWorkspaceInvite(workspaceInvite);
    })();
  }, [match.params.invitationId]);

  const handleSubmit = React.useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setSubmitting(true);
      try {
        const result = await functions.acceptInvite({
          workspaceInvitationId: match.params.invitationId,
          newPassword: password,
          name,
        });

        if (result.ok) {
          await firebase.auth().signInWithCustomToken(result.authToken!);
        }
      } catch (err) {}
      setSubmitting(false);
    },
    [password, match.params.invitationId, setSubmitting]
  );

  if (workspaceInvite === undefined) {
    // loading
    return null;
  }

  const workspaceInviteData = workspaceInvite.data();

  if (workspaceInviteData === undefined) {
    // invitation not found
    return null;
  }

  return (
    <form onSubmit={handleSubmit}>
      <InvitationWrapper>
        <Box marginTop="6rem">
          <Box
            marginBottom="2rem"
            style={{ textAlign: "center" }}
            flex
            direction="column"
            alignItems="center"
          >
            <Box marginBottom="1rem">
              <ProfilePic size="lg" name={name} id="asdlkfjalwkejf" />
            </Box>
            <Text size="display_sm" weight="semibold">
              Activa tu cuenta
            </Text>
          </Box>

          <Field label="Correo electrónico">
            <Input value={workspaceInviteData.email} disabled />
          </Field>

          <Field label="Nombre y apellido">
            <Input
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Field>

          <Field label="Crea tu contraseña">
            <Input
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Field>

          <Button
            kind="primary"
            size="md"
            block
            type="submit"
            isLoading={submitting}
            disabled={submitting}
          >
            Activar
          </Button>
        </Box>
      </InvitationWrapper>
    </form>
  );
};

export default WorkspaceInvitationPage;
