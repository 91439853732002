import firebase from "firebase";
import { Contact, ContactNote, CustomFieldDefinition } from "common/src/types";
import dayjs from "dayjs";
import parsePhoneNumber from "libphonenumber-js";
import ProfilePic from "components/ProfilePic";
import React from "react";
import styled from "styled-components";
import Box from "ui/Box";
import Icon, { IconKey } from "ui/Icon";
import Text from "ui/Text";
import Button from "ui/Button";
import { useCommandBar } from "components/commandBar";
import { useAppData } from "components/AppData";
import db from "lib/db";
import Textarea from "ui/Textarea";
import { capitalize } from "lib/utils";
import { useAuth } from "lib/auth";
import { useImagePreview } from "lib/imagePreview";
import { InlineInput } from "ui/Input";
import InlineTagsInput from "./InlineTagsInput";

const Wrapper = styled.div`
  width: 20rem;
  border-left: solid 1px ${(props) => props.theme.colors.gray200};
  display: flex;
  flex-direction: column;
`;

const UpperSection = styled(Box)`
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
`;

const NoteComposerWrapper = styled.div`
  position: relative;
`;

const NotesWrapper = styled.div`
  overflow-y: auto;
`;

const SendButtonWrapper = styled.div`
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary600};
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  cursor: pointer;
`;

const CloseNoteIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
`;

const NoteWrapper = styled(Box)`
  position: relative;
  &:hover {
    ${CloseNoteIconWrapper} {
      visibility: visible;
    }
  }
`;

const Attribute = ({
  label,
  icon,
  children,
}: {
  label: string;
  icon?: IconKey;
  children: React.ReactNode;
}) => {
  return (
    <Box
      flex
      alignItems="center"
      marginBottom="0.25rem"
      style={{ minHeight: 40 }}
    >
      <Box flex alignItems="center" cols={2}>
        {icon && <Icon size={16} icon={icon} />}
        <Text
          marginLeft={icon ? "6px" : undefined}
          size="text_sm"
          weight="medium"
          color="gray500"
        >
          {label}
        </Text>
      </Box>

      <Box cols={3}>{children}</Box>
    </Box>
  );
};

const AssignButton = ({
  contact,
}: {
  contact: { id: string; data: Contact };
}) => {
  const commandBar = useCommandBar();
  const appData = useAppData();
  const [contactData, setContactData] = React.useState<Contact>();

  React.useEffect(() => {
    return db.contacts.doc(contact.id).onSnapshot((snap) => {
      setContactData(snap.data());
    });
  }, [contact.id]);

  const realtimeContactData = contactData ?? contact.data;

  if (realtimeContactData.assignee?.startsWith("wm:")) {
    const assigneeId = realtimeContactData.assignee.split(":")[1];
    if (appData.workspaceMembers[assigneeId]) {
      const name = appData.workspaceMembers[assigneeId].data().name;
      return (
        <Button
          kind="secondary"
          size="sm"
          style={{ border: "none", boxShadow: "none", margin: "0" }}
          disabled={!appData.permissions.canAssignContacts}
          onClick={() => {
            commandBar.openAssignContact(contact);
          }}
        >
          <Box flex alignItems="center">
            <ProfilePic size="xxs" id={assigneeId} name={name} />

            <Text size="text_sm" weight="semibold" marginLeft="0.5rem">
              {name}
            </Text>
          </Box>
        </Button>
      );
    }
  }

  if (realtimeContactData.assignee?.startsWith("t:")) {
    const assigneeId = realtimeContactData.assignee.split(":")[1];
    const teamData = appData.teams[assigneeId]?.data();

    if (teamData) {
      return (
        <Button
          kind="secondary"
          size="sm"
          style={{ border: "none", boxShadow: "none", margin: "0" }}
          disabled={!appData.permissions.canAssignContacts}
          onClick={() => commandBar.openAssignContact(contact)}
        >
          <Box flex alignItems="center">
            {teamData.emoji}
            <Text size="text_sm" weight="semibold" marginLeft="0.5rem">
              {teamData.name}
            </Text>
          </Box>
        </Button>
      );
    }
  }

  return (
    <Button
      kind="secondary"
      icon="userFill"
      size="sm"
      style={{ border: "none", boxShadow: "none", margin: "0" }}
      disabled={!appData.permissions.canAssignContacts}
      onClick={() => commandBar.openAssignContact(contact)}
    >
      Sin asignar
    </Button>
  );
};

const Note = ({
  note,
}: {
  note: firebase.firestore.QueryDocumentSnapshot<ContactNote>;
}) => {
  const appData = useAppData();
  const workspaceMemberAuthor = appData.workspaceMembers[note.data().createdBy];

  const lines = React.useMemo(() => note.data().body.split("\n"), [note]);

  const handleDelete = () => {
    note.ref.delete();
  };

  return (
    <NoteWrapper flex marginTop="1.5rem">
      <CloseNoteIconWrapper onClick={handleDelete}>
        <Icon icon="x" size={16} />
      </CloseNoteIconWrapper>
      <ProfilePic
        size="xs"
        id={note.data().createdBy}
        name={workspaceMemberAuthor?.data().name}
      />
      <Box marginLeft="0.75rem">
        <Box flex alignItems="center">
          <Text size="text_sm" weight="semibold" marginRight="6px">
            {workspaceMemberAuthor?.data().name ?? ""}
          </Text>
          <Text size="text_xs" color="gray500">
            {capitalize(
              dayjs.unix(note.data().createdAt).format("MMM D, h:mm A")
            )}
          </Text>
        </Box>
        {lines.map((line) => {
          if (line) {
            return (
              <Text size="text_sm" weight="regular">
                {line || " "}
              </Text>
            );
          }

          return <span>&nbsp;</span>;
        })}
      </Box>
    </NoteWrapper>
  );
};

function CustomField(props: {
  definition: CustomFieldDefinition;
  contact: Contact;
  contactId: string;
}) {
  const [fieldValue, setFieldValue] = React.useState("");

  const handleFieldUpdate = () => {
    db.contacts.doc(props.contactId).update({
      [`customFields.${props.definition.key}`]: fieldValue,
    });
  };

  React.useEffect(() => {
    setFieldValue(props.contact.customFields?.[props.definition.key] ?? "");
  }, [props.contact, props.definition]);

  return (
    <Attribute label={props.definition.label}>
      <InlineInput
        value={fieldValue}
        onFinish={handleFieldUpdate}
        onChange={(e) => setFieldValue(e.target.value)}
      />
    </Attribute>
  );
}

const ContactProfile = ({
  contact,
}: {
  contact: { id: string; data: Contact };
}) => {
  const appData = useAppData();
  const auth = useAuth();
  const [contactName, setContactName] = React.useState(contact.data.name);
  const [noteBody, setNoteBody] = React.useState("");
  const [email, setEmail] = React.useState(contact.data.email);
  const [notes, setNotes] =
    React.useState<firebase.firestore.QueryDocumentSnapshot<ContactNote>[]>();
  const imagePreview = useImagePreview();

  React.useEffect(() => {
    return db.notes
      .where("workspaceId", "==", auth.workspaceId)
      .where("contactId", "==", contact.id)
      .orderBy("createdAt", "desc")
      .onSnapshot((snap) => {
        setNotes(snap.docs);
      });
  }, [auth.workspaceId, contact]);

  const shouldHidePhoneNumber = React.useMemo(() => {
    if (
      auth.workspace?.featureFlags?.hidePhoneNumbers &&
      !appData.permissions.canSeePhoneNumbers
    ) {
      return true;
    }

    return false;
  }, [auth.workspace, appData.permissions]);

  const hasMultipleWhatsappAccounts = React.useMemo(() => {
    return (
      Object.keys(appData.whatsappWebAccounts).filter(
        (key) => appData.whatsappWebAccounts[key].data().status === "active"
      ).length > 1
    );
  }, [appData.whatsappWebAccounts]);

  const handleCreateNote = async () => {
    setNoteBody("");
    await db.notes.add({
      workspaceId: auth.workspaceId!,
      body: noteBody,
      contactId: contact.id,
      createdAt: Math.trunc(Date.now() / 1000),
      createdBy: appData.workspaceMemberSelf.id,
    });
  };

  const handleChangeContactName = async () => {
    await db.contacts.doc(contact.id).update({
      name: contactName,
    });
  };

  const handleChangeContactEmail = async () => {
    await db.contacts.doc(contact.id).update({
      email,
    });
  };

  const handleCreateAndAssignTag = async (tag: {
    input: string;
    color: string;
  }) => {
    const slug = tag.input.toLowerCase();
    const label = await db.labels.add({
      workspaceId: auth.workspaceId!,
      slug,
      color: tag.color,
      createdAt: Math.trunc(Date.now() / 1000),
      label: tag.input,
    });
    await Promise.all([
      db.contacts.doc(contact.id).update({
        labels: firebase.firestore.FieldValue.arrayUnion(label.id),
      }),
      db.conversations.doc(`conv:${contact.id}`).update({
        contact: {
          ...contact.data,
          labels: firebase.firestore.FieldValue.arrayUnion(label.id),
        },
        lastUpdateAt: firebase.firestore.FieldValue.serverTimestamp(),
      }),
    ]);
  };

  React.useEffect(() => {
    setContactName(contact.data.name);
    setEmail(contact.data.email);
  }, [contact]);

  return (
    <Wrapper>
      <UpperSection flex justifyContent="center" padding="2rem 0rem">
        <Box
          flex
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            onClick={() =>
              contact.data.profilePictureLocation &&
              imagePreview.setPreview({
                type: "image",
                imageLocation: contact.data.profilePictureLocation,
                contact,
              })
            }
          >
            <ProfilePic
              id={contact.id}
              name={contact.data.name}
              imageLocation={contact.data.profilePictureLocation}
              size="lgs"
            />
          </Box>
          <InlineInput
            value={contactName}
            onChange={(e) => setContactName(e.target.value)}
            onFinish={handleChangeContactName}
            style={{
              maxWidth: 300,
              textAlign: "center",
              marginTop: "20px",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: "32px",
            }}
          />
        </Box>
      </UpperSection>

      <Box padding="1.5rem" marginBottom="0rem">
        <Attribute label="Asignado a" icon="usersFill">
          <AssignButton contact={contact} />
        </Attribute>
        <Attribute label="Etiquetas" icon="tag">
          <InlineTagsInput
            labels={contact.data.labels ?? []}
            onAssign={async (labelId) => {
              await Promise.all([
                db.contacts.doc(contact.id).update({
                  labels: firebase.firestore.FieldValue.arrayUnion(labelId),
                }),
                db.conversations.doc(`conv:${contact.id}`).update({
                  contact: {
                    ...contact.data,
                    labels: firebase.firestore.FieldValue.arrayUnion(labelId),
                  },
                  lastUpdateAt: firebase.firestore.FieldValue.serverTimestamp(),
                }),
                db.messages.add({
                  channel: "internal",
                  contactId: contact.id,
                  action: "labeled_contact",
                  timestamp: Math.trunc(Date.now() / 1000),
                  workspaceAuthorId: appData.workspaceMemberSelf.id,
                  labelId,
                }),
              ]);
            }}
            onRemove={async (labelId) => {
              await Promise.all([
                db.contacts.doc(contact.id).update({
                  labels: firebase.firestore.FieldValue.arrayRemove(labelId),
                }),
                db.conversations.doc(`conv:${contact.id}`).update({
                  contact: {
                    ...contact.data,
                    labels: firebase.firestore.FieldValue.arrayRemove(labelId),
                  },
                  lastUpdateAt: firebase.firestore.FieldValue.serverTimestamp(),
                }),
                db.messages.add({
                  channel: "internal",
                  contactId: contact.id,
                  action: "unlabeled_contact",
                  timestamp: Math.trunc(Date.now() / 1000),
                  workspaceAuthorId: appData.workspaceMemberSelf.id,
                  labelId,
                }),
              ]);
            }}
            onCreate={async (tag) => {
              await handleCreateAndAssignTag(tag);
            }}
          />
        </Attribute>
        {contact.data.phone && !shouldHidePhoneNumber && (
          <Attribute label="Telefono" icon="phoneFill">
            <Text size="text_sm" color="gray900">
              {parsePhoneNumber(contact.data.phone)?.formatInternational()}
            </Text>
          </Attribute>
        )}
        <Attribute label="Email" icon="atSign">
          <InlineInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onFinish={handleChangeContactEmail}
          />
        </Attribute>

        {hasMultipleWhatsappAccounts &&
          appData.whatsappWebAccounts[
            contact.data.whatsappWebAccountJid ?? ""
          ] && (
            <Attribute label="Asociado a" icon="whatsappGray">
              <Text size="text_sm" color="gray900">
                {
                  appData.whatsappWebAccounts[
                    contact.data.whatsappWebAccountJid ?? ""
                  ]?.data().name
                }
              </Text>
            </Attribute>
          )}

        {auth.workspace?.customFields?.map((customFieldDefinition) => (
          <CustomField
            contactId={contact.id}
            contact={contact.data}
            definition={customFieldDefinition}
          />
        ))}
      </Box>

      <Box
        padding="0rem 1.5rem"
        flex
        direction="column"
        style={{ flex: 1, minHeight: 0 }}
      >
        <Text
          size="text_sm"
          weight="medium"
          color="gray500"
          marginBottom="1rem"
        >
          Notas
        </Text>

        <NoteComposerWrapper>
          <Textarea
            placeholder="Escribe una nota aquí"
            rows={3}
            value={noteBody}
            onChange={(e) => setNoteBody(e.target.value)}
            onKeyDown={async (e) => {
              if (e.key === "Enter" && e.metaKey) {
                e.preventDefault();
                await handleCreateNote();
              }
            }}
          />
          <SendButtonWrapper
            onClick={handleCreateNote}
            style={{ transform: "rotate(180deg)" }}
          >
            <Icon icon="arrowUp" />
          </SendButtonWrapper>
        </NoteComposerWrapper>

        <NotesWrapper>
          {notes?.map((note) => (
            <Note note={note} />
          ))}
        </NotesWrapper>
      </Box>
    </Wrapper>
  );
};

export default ContactProfile;
