import React from "react";
import * as Primitive from "@radix-ui/react-avatar";
import styled, { css } from "styled-components";

import { storage } from "lib/db";

import Text from "ui/Text";
import theme from "./theme";

const COLORS = [
  theme.colors.grayBlue400,
  theme.colors.pink700,
  theme.colors.orange600,
  theme.colors.yellow500,
  theme.colors.cyan500,
  theme.colors.green500,
];

function getColorIndex(uid: string) {
  let hash = 0;
  Array(uid.length)
    .fill(1)
    .forEach((_, i) => {
      hash += uid.charCodeAt(i);
    });

  return hash % 8;
}

function getInitials(name: string | undefined) {
  let initials = "";
  const [firstName, lastName] = (name ?? "").split(" ");
  if (firstName !== undefined && firstName[0] !== undefined)
    initials = firstName[0];
  if (lastName !== undefined && lastName[0] !== undefined)
    initials = `${initials}${lastName[0]}`;
  return (initials ?? "").toUpperCase();
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .label {
    display: block;
    margin-left: 0.75rem;
    text-decoration-line: underline;
  }
`;

const Root = styled(Primitive.Root)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colors.gray200};
`;

const AvatarImage = styled(Primitive.Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`;

const Fallback = styled(Primitive.Fallback)<{ color: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`;

type AvatarProps = {
  id: string;
  location: string | undefined;
  name: string;
  onClick?(): void;
} & Primitive.AvatarProps;

const downloadUrls: Record<string, string | Promise<string>> = {};

const Avatar = ({ id, location, name, onClick, ...props }: AvatarProps) => {
  const [imageUrl, setImageUrl] = React.useState<string>();

  const fallback = getInitials(name);

  React.useEffect(() => {
    (async () => {
      if (!imageUrl && location) {
        if (!downloadUrls[location]) {
          const downloadUrl = storage.ref(location).getDownloadURL();
          downloadUrls[location] = downloadUrl;
        }

        setImageUrl(await downloadUrls[location]);
      }
    })();
  }, [location, imageUrl]);

  return (
    <Wrapper
      style={{
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
      {...props}
    >
      <Root>
        <AvatarImage src={imageUrl} />
        <Fallback color={COLORS[getColorIndex(id)]} delayMs={200}>
          {fallback}
        </Fallback>
      </Root>
      {name && (
        <Text className="label" color="gray900" size="text_sm" weight="medium">
          {name}
        </Text>
      )}
    </Wrapper>
  );
};

export default Avatar;
