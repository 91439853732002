import React from "react";
import TIMEZONE_MAP from "./timezoneMap";
import countries from "./countries.json";
import styled, { useTheme } from "styled-components";
import { AsYouType } from "libphonenumber-js";
import Box from "ui/Box";
import Icon from "ui/Icon";
import Text from "ui/Text";
import OutsideClickHandler from "react-outside-click-handler";

const Wrapper = styled(Box)`
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.gray300};
  border-radius: 8px;
  height: 44px;
`;

const BorderlessInput = styled.input`
  border: none;
  font-size: 16px;
  line-height: 24px;

  &:placeholder {
    color: ${(props) => props.theme.colors.gray500};
    font-size: ;
  }

  &:focus {
    outline: none;
  }
`;

const FlagButtonWrapper = styled(Box)`
  transition-duration: 200ms;
  cursor: pointer;
  &:hover {
    border-radius: 8px 0px 0px 8px;
    background-color: ${(props) => props.theme.colors.gray100};
  }
`;

export function getCountryCode() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (timezone === "" || !timezone) {
    return null;
  }

  const countryCode = TIMEZONE_MAP[timezone]?.c?.[0];
  return countryCode;
}

function CountryFlagButton(props: {
  countryCode: string;
  onClick: () => void;
}) {
  return (
    <FlagButtonWrapper
      flex
      alignItems="center"
      style={{ height: "100%" }}
      paddingLeft="14px"
      onClick={props.onClick}
    >
      <span className={`fi fi-${props.countryCode.toLowerCase()}`}></span>
      <Icon icon="chevronFillDown" />
    </FlagButtonWrapper>
  );
}

const CountryItemWrapper = styled(Box)`
  border-radius: 4px;
  cursor: pointer;
  transition-duration: 200ms;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray100};
  }
`;

function Country(props: { countryCode: string; onClick: () => void }) {
  return (
    <CountryItemWrapper
      flex
      alignItems="center"
      padding="6px"
      onClick={props.onClick}
    >
      <i
        className={`fi fi-${props.countryCode.toLowerCase()}`}
        style={{ fontSize: 14 }}
      ></i>

      <Text size="text_sm" weight="medium" color="gray600" marginLeft="0.5rem">
        {(countries as any)[props.countryCode]}
      </Text>
    </CountryItemWrapper>
  );
}

function CountryPickerDropdown(props: {
  searchQuery: string;
  onCountryPicked: (countryCode: string) => void;
}) {
  const theme = useTheme();
  return (
    <Box
      style={{
        position: "absolute",
        zIndex: 10,
        bottom: 0,
        left: -1,
        right: -0.75,
        transform: "translateY(calc(100% - 8px))",
        backgroundColor: "white",
        border: `solid 1px ${theme.colors.gray300}`,
        borderTop: "none",
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        maxHeight: 300,
        overflow: "auto",
      }}
      padding="0rem 6px"
    >
      <Box padding="0.5rem 0rem">
        {Object.keys(countries)
          .filter((countryCode) =>
            (countries as any)[countryCode]
              .toLowerCase()
              .includes(props.searchQuery)
          )
          .map((countryCode) => (
            <Country
              countryCode={countryCode}
              key={countryCode}
              onClick={() => props.onCountryPicked(countryCode)}
            />
          ))}
      </Box>
    </Box>
  );
}

export default function InternationalPhoneInput(props: {
  value: string;
  onChange: (newVal: string) => void;
  countryCode: string;
  onCountryCodeChange: (newCountryCode: string) => void;
}) {
  const phoneInputRef = React.useRef<HTMLInputElement>(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const shouldAutoFocusPhoneInputRef = React.useRef(false);
  const [mode, setMode] = React.useState<"pickCountry" | "enterNumber">(
    "enterNumber"
  );

  const handleCountryPicked = (countryCode: string) => {
    setSearchQuery("");
    setMode("enterNumber");
    props.onCountryCodeChange(countryCode);
    props.onChange("");
    shouldAutoFocusPhoneInputRef.current = true;
  };

  React.useEffect(() => {
    if (mode === "enterNumber" && shouldAutoFocusPhoneInputRef.current) {
      phoneInputRef.current?.focus();
    }
  }, [mode]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (mode === "pickCountry") {
          setMode("enterNumber");
        }
      }}
    >
      <Wrapper flex alignItems="center">
        {mode === "enterNumber" && (
          <>
            <CountryFlagButton
              countryCode={props.countryCode}
              onClick={() => {
                setMode("pickCountry");
              }}
            />

            <Box cols={1}>
              <BorderlessInput
                value={props.value}
                style={{ width: "100%" }}
                ref={phoneInputRef}
                onChange={(e) => {
                  const newFormattedInput = new AsYouType(
                    props.countryCode as any
                  ).input(e.target.value);
                  if (props.value === newFormattedInput) {
                    props.onChange(e.target.value);
                  } else {
                    props.onChange(newFormattedInput);
                  }
                }}
                placeholder="+1 (555) 000-0000"
              />
            </Box>
          </>
        )}

        {mode === "pickCountry" && (
          <Box style={{ position: "relative", width: "100%" }}>
            <Box
              cols={1}
              style={{ height: 44, width: "100%" }}
              flex
              alignItems="center"
              padding="0rem 14px"
            >
              <Box marginRight="8px">
                <Icon icon="search" size={18} />
              </Box>
              <BorderlessInput
                placeholder="Busca un país"
                autoFocus
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ width: "100%" }}
              />
            </Box>

            <CountryPickerDropdown
              searchQuery={searchQuery}
              onCountryPicked={handleCountryPicked}
            />
          </Box>
        )}
      </Wrapper>
    </OutsideClickHandler>
  );
}
