import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { v4 } from "uuid";
import firebase from "firebase";
import whatsappBackground from "assets/whatsapp-bg.png";
import styled, { useTheme } from "styled-components";
import {
  BorderlessInput,
  CommandBarDialogContent,
  CommandBarHeader,
  MenuItem,
} from "./common";
import Icon from "ui/Icon";
import Box from "ui/Box";
import IconButton from "ui/IconButton";
import Text from "ui/Text";
import Field from "ui/Field";
import Input from "ui/Input";
import Button from "ui/Button";
import db from "lib/db";
import { useAuth } from "lib/auth";
import {
  SavedReply,
  SavedReplyMessage,
  ComposingMessage,
} from "common/src/types";
import FilePickerHandler from "ui/FilePickerHandler";
import { ComposerFile } from "components/chat/composer";
import FBImage from "components/FBImage";
import Modal from "ui/Modal";
import AttachedFile, { ZamiFile } from "components/chat/AttachedFile";
import { useCommandBar } from ".";

const ColumnWrapper = styled.div<{ isColumn: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: solid 1px ${(props) => props.theme.colors.gray200};
`;

const MessageBubble = styled(Box)`
  background-color: white;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 6px;
`;

export const TemplatePreview = (props: {
  savedReply: firebase.firestore.QueryDocumentSnapshot<SavedReply>;
}) => {
  const savedReplyData = props.savedReply.data();

  return (
    <Box
      cols={1}
      style={{ backgroundImage: `url(${whatsappBackground})` }}
      padding="1.5rem"
    >
      {savedReplyData.message.type === "text" && (
        <MessageBubble padding="0.75rem 0.5rem">
          {savedReplyData.message.body}
        </MessageBubble>
      )}

      {savedReplyData.message.type === "image" && (
        <MessageBubble padding="0.75rem 0.5rem">
          <Box marginBottom="0.25rem">
            <FBImage
              location={savedReplyData.message.location}
              style={{ width: "100%", maxHeight: 200, objectFit: "cover" }}
            />
          </Box>
          {savedReplyData.message.caption}
        </MessageBubble>
      )}

      {savedReplyData.message.type === "document" && (
        <MessageBubble padding="0.75rem 0.5rem">
          <ZamiFile
            name={savedReplyData.message.fileName}
            size={savedReplyData.message.fileSize}
            style={{ position: "initial" }}
          />
        </MessageBubble>
      )}
    </Box>
  );
};

const TemplateComposerWrapper = styled(Box)`
  border: solid 1px ${(props) => props.theme.colors.gray300};
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 0.25rem;
  background-color: white;

  textarea {
    font-size: 16px;
    border: none;
    resize: none;
    width: 100%;
    box-sizing: border-box;
    max-height: 300px;

    &:focus-visible {
      outline: none;
    }
  }
`;

const ComposerAttachments = styled.div`
  display: flex;
  align-items: center;
  border-top: solid 1px ${(props) => props.theme.colors.gray200};
  padding: 0.25rem 0rem;
`;

const UploadedImageWrapper = styled(Box)`
  .uploadedImageIconWrapper {
    visibility: hidden;
    cursor: pointer;
  }
  &:hover {
    .uploadedImageIconWrapper {
      visibility: visible;
    }
  }
`;

type TemplateComposerMessage =
  | {
      type: "text";
      body: string;
    }
  | {
      type: "image";
      location: string | undefined;
      file: ComposerFile;
      caption: string | undefined;
    }
  | {
      type: "document";
      location: string | undefined;
      file: ComposerFile;
    };

function TemplateComposer(props: {
  value: TemplateComposerMessage;
  onChange: (message: TemplateComposerMessage) => void;
}) {
  const [file, setFile] = React.useState<File>();
  const auth = useAuth();
  const { value, onChange } = props;
  React.useEffect(() => {
    (async () => {
      if (file && value.type === "image" && value.location === undefined) {
        const location = `workspace-media/${auth.workspaceId}/${v4()}`;
        await firebase.storage().ref().child(location).put(file);
        onChange({
          ...value,
          location,
        });
      }
    })();
  }, [file, value, auth.workspaceId, onChange]);

  return (
    <TemplateComposerWrapper>
      {props.value.type === "text" && (
        <textarea
          rows={10}
          required
          value={props.value.body}
          onChange={(e) =>
            props.onChange({
              type: "text",
              body: e.target.value,
            })
          }
        />
      )}

      {props.value.type === "image" && (
        <Box>
          <div style={{ minHeight: 48 }}>
            <TextareaAutosize
              required
              placeholder="Escribe un mensaje"
              value={props.value.caption ?? ""}
              onChange={(e) => {
                if (props.value.type === "image") {
                  props.onChange({
                    ...props.value,
                    caption: e.target.value,
                  });
                }
              }}
            />
          </div>
          {props.value.location && (
            <UploadedImageWrapper
              style={{ position: "relative", display: "inline-block" }}
            >
              <Box
                className="uploadedImageIconWrapper"
                style={{
                  backgroundColor: "#8293A2",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "0.25rem",
                  right: "0.25rem",
                  padding: "2px",
                }}
                onClick={() =>
                  props.onChange({
                    type: "text",
                    body:
                      props.value.type === "image"
                        ? props.value.caption ?? ""
                        : "",
                  })
                }
              >
                <Icon icon="x" size={20} stroke="white" />
              </Box>
              <FBImage
                location={props.value.location}
                style={{ maxWidth: 200, maxHeight: 200, objectFit: "cover" }}
              />
            </UploadedImageWrapper>
          )}
        </Box>
      )}

      {props.value.type === "document" && file && (
        <Box padding="1rem">
          <Box style={{ position: "relative", height: 72 }}>
            <AttachedFile
              file={file}
              isLoading={
                props.value.type === "document" &&
                props.value.location === undefined
              }
              onUploadSuccess={(location) => {
                if (props.value.type === "document") {
                  props.onChange({
                    type: "document",
                    file: props.value.file,
                    location,
                  });
                }
              }}
            />
          </Box>
        </Box>
      )}

      <ComposerAttachments>
        <FilePickerHandler
          onFile={(file) => {
            const fileSizeInMB = file.size * 1e-6;

            if (props.value.type !== "text") {
              return undefined;
            }

            if (fileSizeInMB >= 100) {
              return alert(
                "El limite de tamaño al enviar archivos es de 100 MB"
              );
            }

            setFile(file);
            props.onChange({
              type: "image",
              caption: props.value.body,
              file: {
                name: file.name,
                size: file.size,
                type: file.type,
              },
              location: undefined,
            });
          }}
        >
          <IconButton size="md" icon="image" type="button" />
        </FilePickerHandler>

        <FilePickerHandler
          onFile={(file) => {
            const fileSizeInMB = file.size * 1e-6;

            if (fileSizeInMB >= 100) {
              return alert(
                "El limite de tamaño al enviar archivos es de 100 MB"
              );
            }

            setFile(file);
            props.onChange({
              type: "document",
              file: {
                name: file.name,
                size: file.size,
                type: file.type,
              },
              location: undefined,
            });

            console.log("got file!");
          }}
        >
          <IconButton size="md" icon="file4Outline" />
        </FilePickerHandler>
      </ComposerAttachments>
    </TemplateComposerWrapper>
  );
}

function CreateSavedReplyCommandWindow(props: { onCancel: () => void }) {
  const auth = useAuth();
  const [name, setName] = React.useState("");
  const [body, setBody] = React.useState("");
  const [message, setMessage] = React.useState<TemplateComposerMessage>({
    type: "text",
    body: "",
  });
  const commandBar = useCommandBar();

  const handleCreate = async () => {
    let theMessage: SavedReplyMessage;
    if (message.type === "image" && message.location !== undefined) {
      theMessage = {
        ...message,
        location: message.location!,
      };
    } else if (message.type === "document" && message.location !== undefined) {
      theMessage = {
        type: "document",
        location: message.location,
        fileName: message.file.name,
        fileMimetype: message.file.type,
        fileSize: message.file.size,
      };
    } else if (message.type === "text") {
      theMessage = {
        ...message,
      };
    } else {
      return;
    }

    await db.savedReplies.add({
      name,
      message: theMessage,
      workspaceId: auth.workspaceId!,
      createdAt: Math.trunc(Date.now() / 1000),
    });

    props.onCancel();
  };

  return (
    <CommandBarDialogContent>
      <CommandBarHeader>
        <Text size="text_lg" weight="semibold" cols={1}>
          Crea un mensaje
        </Text>
        <IconButton icon="x" onClick={() => commandBar.setStage(null)} />
      </CommandBarHeader>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleCreate();
        }}
      >
        <Box padding="2rem">
          <Field label="Títutlo">
            <Input
              placeholder="Agrega un título"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </Field>

          <Field label="Mensaje">
            <TemplateComposer value={message} onChange={setMessage} />
          </Field>
        </Box>

        <Box flex justifyContent="flex-end" padding="2rem" paddingTop="0">
          <Box marginRight="0.75rem">
            <Button kind="secondary" size="lg" onClick={props.onCancel}>
              Cancelar
            </Button>
          </Box>
          <Button kind="primary" size="lg" type="submit">
            Guardar mensaje
          </Button>
        </Box>
      </form>
    </CommandBarDialogContent>
  );
}

function DeleteSavedReplyModal(props: {
  savedReplyToDelete:
    | firebase.firestore.QueryDocumentSnapshot<SavedReply>
    | undefined;
  onClose: () => void;
}) {
  async function handleDelete() {
    await props.savedReplyToDelete?.ref.delete();
    props.onClose();
  }

  return (
    <Modal
      open={props.savedReplyToDelete !== undefined}
      onOpenChange={(open) => {
        if (!open) {
          props.onClose();
        }
      }}
    >
      <Modal.Title>Eliminar mensaje</Modal.Title>

      <Modal.Description>
        ¿Quieres eliminar este mensaje? Esta acción no se puede deshacer.
      </Modal.Description>

      <Modal.Actions>
        <Box flex justifyContent="flex-end">
          <Button
            size="md"
            kind="secondary"
            style={{ marginRight: "0.75rem" }}
            onClick={props.onClose}
          >
            Cancelar
          </Button>
          <Button size="md" kind="danger" onClick={handleDelete}>
            Eliminar
          </Button>
        </Box>
      </Modal.Actions>
    </Modal>
  );
}

export default function SavedRepliesCommandWindow(props: {
  onReplySelected: (message: ComposingMessage) => void;
}) {
  const auth = useAuth();
  const [search, setSearch] = React.useState("");
  const theme = useTheme();
  const [highlightedTemplateIndex, setHighlightedTemplateIndex] =
    React.useState(0);
  const [selectedTemplateIndex, setSelectedTemplateIndex] =
    React.useState<number>();
  const [isCreatingNewSavedReply, setIsCreatingNewSavedReply] =
    React.useState(false);
  const [savedReplies, setSavedReplies] = React.useState<
    firebase.firestore.QueryDocumentSnapshot<SavedReply>[]
  >([]);
  const [deleteTemplateAtIndex, setDeleteTemplateAtIndex] =
    React.useState<number>();
  const previewTemplateIndex =
    selectedTemplateIndex ?? highlightedTemplateIndex;

  React.useEffect(() => {
    return db.savedReplies
      .where("workspaceId", "==", auth.workspaceId)
      .onSnapshot((snap) => {
        setSavedReplies(snap.docs);
      });
  }, [auth.workspaceId]);

  if (isCreatingNewSavedReply) {
    return (
      <CreateSavedReplyCommandWindow
        onCancel={() => setIsCreatingNewSavedReply(false)}
      />
    );
  }

  return (
    <CommandBarDialogContent>
      <DeleteSavedReplyModal
        savedReplyToDelete={
          deleteTemplateAtIndex !== undefined
            ? savedReplies[deleteTemplateAtIndex]
            : undefined
        }
        onClose={() => setDeleteTemplateAtIndex(undefined)}
      />
      <CommandBarHeader>
        <Icon icon="bookmark" />

        <BorderlessInput
          placeholder="Buscar mensaje guardado"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ flex: 1 }}
        />

        <IconButton
          icon="plus"
          kind="secondary"
          iconStroke="gray700"
          onClick={() => setIsCreatingNewSavedReply(true)}
        />
      </CommandBarHeader>

      <Box flex cols={1} style={{ height: 392 }}>
        {savedReplies.length > 0 ? (
          <ColumnWrapper isColumn>
            <Box style={{ overflowY: "auto" }}>
              {savedReplies.map((savedReply, i) => {
                if (
                  !savedReply
                    .data()
                    .name.toLowerCase()
                    .includes(search.toLowerCase())
                ) {
                  return null;
                }

                return (
                  <MenuItem
                    onMouseEnter={() => setHighlightedTemplateIndex(i)}
                    highlighted={highlightedTemplateIndex === i}
                    onClick={() => {
                      setSelectedTemplateIndex(i);
                    }}
                    key={savedReply.id}
                  >
                    <Box flex justifyContent="space-between">
                      {savedReply.data().name}
                      <IconButton
                        icon="trash"
                        iconStroke="gray600"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setDeleteTemplateAtIndex(i);
                        }}
                      />
                    </Box>
                  </MenuItem>
                );
              })}
            </Box>

            {selectedTemplateIndex !== undefined && (
              <Box
                paddingTop="1rem"
                paddingBottom="1.5rem"
                flex
                justifyContent="center"
                style={{ borderTop: `solid 1px ${theme.colors.gray200}` }}
                paddingLeft="1rem"
                paddingRight="1rem"
              >
                <Button
                  size="md"
                  block
                  onClick={async () => {
                    const savedReplyData =
                      savedReplies[selectedTemplateIndex].data();
                    if (savedReplyData.message.type === "text") {
                      props.onReplySelected({
                        type: "text",
                        message: savedReplyData.message.body,
                      });
                    } else if (savedReplyData.message.type === "image") {
                      const file = await firebase
                        .storage()
                        .ref(savedReplyData.message.location)
                        .getDownloadURL()
                        .then((url) => {
                          return fetch(url)
                            .then((r) => r.blob())
                            .then((f) => {
                              return new File([f], "filename", {
                                type: f.type,
                              });
                            });
                        });

                      props.onReplySelected({
                        type: "image",
                        location: savedReplyData.message.location,
                        caption: savedReplyData.message.caption,
                        file,
                      });
                    } else if (savedReplyData.message.type === "document") {
                      const file = await firebase
                        .storage()
                        .ref(savedReplyData.message.location)
                        .getDownloadURL()
                        .then((url) => {
                          return fetch(url)
                            .then((r) => r.blob())
                            .then((f) => {
                              if (savedReplyData.message.type === "document") {
                                return new File(
                                  [f],
                                  savedReplyData.message.fileName,
                                  {
                                    type: f.type,
                                  }
                                );
                              }
                            });
                        });

                      if (file) {
                        props.onReplySelected({
                          type: "document",
                          location: savedReplyData.message.location,
                          file,
                        });
                      }
                    }
                  }}
                >
                  Usar plantilla
                </Button>
              </Box>
            )}
          </ColumnWrapper>
        ) : (
          <Box
            flex
            direction="column"
            alignItems="center"
            style={{ textAlign: "center" }}
            cols={1}
            paddingTop="3rem"
          >
            <Text size="text_md" weight="semibold">
              No tienes mensajes guardados
            </Text>

            <Text
              size="text_md"
              weight="regular"
              color="gray600"
              style={{
                maxWidth: 320,
                margin: "0 auto",
                marginTop: "0.75rem",
                marginBottom: "1rem",
              }}
            >
              Los mensajes guardados pueden ahorrarte tiempo si sueles escribir
              respuestas similares.
            </Text>

            <Button size="md" onClick={() => setIsCreatingNewSavedReply(true)}>
              Crear primer mensaje
            </Button>
          </Box>
        )}

        {savedReplies[previewTemplateIndex] && (
          <TemplatePreview savedReply={savedReplies[previewTemplateIndex]} />
        )}
      </Box>
    </CommandBarDialogContent>
  );
}
