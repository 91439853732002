import { isDevelopmentBuild } from "lib/db";
import { useRESTClient } from "lib/restClient";
import React from "react";
import styled from "styled-components";
import Box from "ui/Box";
import Button from "ui/Button";
import Icon from "ui/Icon";
import IconButton from "ui/IconButton";
import Modal from "ui/Modal";
import Text from "ui/Text";

const PlanCardWrapper = styled.div`
  border-radius: 12px;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  padding: 2rem;
  margin: 0rem 0.5rem;
`;

function PlanCard(props: {
  name: string;
  priceId: string;
  price: string;
  features: string[];
  automatedSequenceId: string;
}) {
  const restClient = useRESTClient();
  const [loading, setLoading] = React.useState(false);

  const onPlanSelected = async () => {
    setLoading(true);
    try {
      const response = await restClient.post<{ url: string }>(
        "/accounts/stripe-checkout-session",
        {
          trialDays: 7,
          priceId: props.priceId,
          redirectUri: `/prompt-composer/${props.automatedSequenceId}`,
        }
      );
      window.location.href = response.data.url;
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <PlanCardWrapper>
      <Box paddingBottom="2rem" marginBottom="2rem" borderBottom>
        <Text
          size="text_xl"
          color="gray500"
          weight="bold"
          marginBottom="0.5rem"
        >
          {props.name}
        </Text>

        <Text size="display_lg" weight="bold" color="primary700">
          {props.price}
        </Text>
      </Box>

      <Text size="text_sm" color="gray500">
        Incluye:
      </Text>

      <Box style={{ minHeight: 200 }} paddingTop="0.5rem">
        {props.features.map((feature, i) => {
          return (
            <Box
              flex
              alignItems="center"
              key={i}
              marginTop="0.5rem"
              marginBottom="0.5rem"
            >
              <Icon icon="check" size={24} stroke="primary600" />
              <Text size="text_sm" color="gray500" marginLeft="1rem">
                {feature}
              </Text>
            </Box>
          );
        })}
      </Box>

      <Button size="md" disabled={loading} block onClick={onPlanSelected}>
        {loading ? "Redireccionando..." : "Seleccionar"}
      </Button>
    </PlanCardWrapper>
  );
}

export default function BotPlanPickerModal(props: {
  open: boolean;
  onOpenChange: () => void;
  automatedSequenceId: string;
}) {
  return (
    <Modal
      open={props.open}
      onOpenChange={props.onOpenChange}
      noClose
      contentStyle={{
        maxWidth: "none",
        width: "initial",
      }}
    >
      <Box flex alignItems="center" justifyContent="space-between">
        <Box>
          <Text size="text_lg" weight="semibold">
            Prueba cualquiera de nuestros planes gratis por 7 días
          </Text>

          <Text size="text_md" weight="regular" color="gray600">
            Con cualquier plan podrás publicar tu bot a WhatsApp, Instagram o
            Messenger.
          </Text>
        </Box>

        <IconButton icon="x" onClick={props.onOpenChange} />
      </Box>

      <Box flex justifyContent="center" marginTop="1.5rem">
        <PlanCard
          name="AI Base"
          price="$99/mes"
          automatedSequenceId={props.automatedSequenceId}
          priceId={
            isDevelopmentBuild
              ? "price_1O8DnGFrZC43FlVrC1QSLEmE"
              : "price_1O8Df8FrZC43FlVrUhJUDjwY"
          }
          features={[
            "300 conversaciones",
            "Acceso completo a Zami",
            "3 usuarios incluidos",
            "US$20 por usuario adicional",
          ]}
        />

        <PlanCard
          name="AI Crecimiento"
          price="$249/mes"
          automatedSequenceId={props.automatedSequenceId}
          priceId={
            isDevelopmentBuild
              ? "price_1O8DnGFrZC43FlVrC1QSLEmE"
              : "price_1O8DfUFrZC43FlVrqLBzvZOf"
          }
          features={[
            "1,000 conversaciones",
            "Acceso completo a Zami",
            "5 usuarios incluidos",
            "US$20 por usuario adicional",
          ]}
        />

        <PlanCard
          name="AI Pro"
          price="$499/mes"
          priceId={
            isDevelopmentBuild
              ? "price_1O8DnGFrZC43FlVrC1QSLEmE"
              : "price_1O8DfhFrZC43FlVrF8f6HIJL"
          }
          automatedSequenceId={props.automatedSequenceId}
          features={[
            "2,500 conversaciones",
            "Acceso completo a Zami",
            "10 usuarios incluidos",
            "US$20 por usuario adicional",
          ]}
        />
      </Box>
    </Modal>
  );
}
