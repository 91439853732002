import React, { ButtonHTMLAttributes } from "react";
import styled, { css, CSSProperties, DefaultTheme } from "styled-components";
import Icon, { IconKey } from "./Icon";

type ButtonType =
  | "primary"
  | "primary-dark"
  | "secondary"
  | "danger"
  | "tertiary";

interface StyledButtonProps {
  kind: ButtonType;
  block?: boolean;
  size?: "lg" | "md" | "sm" | "xs";
}

const StyledButton = styled.button<StyledButtonProps>`
  height: 2.75rem;
  border-radius: 8px;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transition-duration: 150ms;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  ${(props) =>
    props.size === "lg" &&
    css`
      height: 2.75rem;
      padding: 0px 18px;
      font-size: 16px;
      line-height: 24px;
    `}

  ${(props) =>
    props.size === "sm" &&
    css`
      height: 2.25rem;
      padding: 0px 1rem;
    `}

  ${(props: StyledButtonProps) =>
    props.size === "md" &&
    css`
      height: 2.5rem;
      padding: 0px 1rem;
    `}

  ${(props: StyledButtonProps) =>
    props.kind === "primary" &&
    css`
      color: white;
      background-color: ${(props) => props.theme.colors.primary600};

      &:hover {
        background-color: ${(props) => props.theme.colors.primary700};
      }

      &:enabled:active {
        background-color: ${(props) => props.theme.colors.primary600};
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
          0px 0px 0px 4px ${(props) => props.theme.colors.primary100};
      }

      &:disabled {
        background-color: ${(props) => props.theme.colors.primary200};
        cursor: not-allowed;
      }
    `}

    ${(props: StyledButtonProps) =>
    props.kind === "primary-dark" &&
    css`
      color: white;
      background-color: ${(props) => props.theme.colors.gray800};

      &:hover {
        background-color: ${(props) => props.theme.colors.gray700};
      }

      &:enabled:active {
        background-color: ${(props) => props.theme.colors.primary600};
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
          0px 0px 0px 4px ${(props) => props.theme.colors.gray100};
      }

      &:disabled {
        background-color: ${(props) => props.theme.colors.gray300};
        cursor: not-allowed;
      }
    `}
  
  ${(props: StyledButtonProps) =>
    props.kind === "secondary" &&
    css`
      color: ${(props) => props.theme.colors.gray700};
      background-color: ${(props) => props.theme.colors.white};
      border: ${(props) => `1px solid ${props.theme.colors.gray300}`};

      &:hover {
        background-color: ${(props) => props.theme.colors.gray50};
      }

      &:enabled:active {
        background-color: ${(props) => props.theme.colors.white};
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
          0px 0px 0px 4px ${(props) => props.theme.colors.gray100};
      }

      &:disabled {
        background-color: ${(props) => props.theme.colors.white};
        border: ${(props) => `1px solid ${props.theme.colors.gray200}`};
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        cursor: not-allowed;
      }
    `}

  ${(props) =>
    props.kind === "tertiary" &&
    css`
      color: ${(props) => props.theme.colors.primary700};
      background-color: transparent;
      box-shadow: none;
      border: none;

      &:hover {
        background-color: ${(props) => props.theme.colors.primary50};
      }
    `}

  ${(props: StyledButtonProps) =>
    props.kind === "danger" &&
    css`
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.error700};
      border: ${(props) => `1px solid ${props.theme.colors.error700}`};

      &:hover {
        background-color: ${(props) => props.theme.colors.error800};
      }

      &:enabled:active {
        background-color: ${(props) => props.theme.colors.error700};
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
          0px 0px 0px 4px ${(props) => props.theme.colors.error100};
      }

      &:disabled {
        background-color: ${(props) => props.theme.colors.error200};
        border: ${(props) => `1px solid ${props.theme.colors.error200}`};
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
          0px 0px 0px 4px ${(props) => props.theme.colors.error100};
        cursor: not-allowed;
      }
    `}

  ${(props) =>
    props.block &&
    css`
      width: 100%;
    `}
`;

const LeftIconWrapper = styled.div<{ hasText: boolean }>`
  display: "flex";
  align-items: "center";

  ${(props) =>
    props.hasText &&
    css`
      margin-right: 0.5rem;
    `}
`;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  kind?: ButtonType;
  icon?: IconKey;
  iconSize?: number;
  block?: boolean;
  size?: "md" | "sm" | "lg";
  disabled?: boolean;
  isLoading?: boolean;
  iconStroke?: keyof DefaultTheme["colors"];
  children?: React.ReactNode;
  contentStyles?: CSSProperties;
}

const Button = ({
  kind,
  block,
  children,
  disabled,
  iconSize,
  iconStroke,
  size,
  icon,
  isLoading,
  contentStyles,
  ...restProps
}: ButtonProps) => {
  return (
    <StyledButton
      size={size}
      kind={kind ?? "primary"}
      block={block}
      disabled={disabled}
      {...restProps}
    >
      {icon && !isLoading && (
        <LeftIconWrapper
          hasText={children !== undefined}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon icon={icon} size={iconSize ?? 16} stroke={iconStroke} />
        </LeftIconWrapper>
      )}

      {isLoading && (
        <LeftIconWrapper
          style={{ marginRight: "1" }}
          hasText={children !== undefined}
        >
          <Icon icon="oval" size={16} fill="white" />
        </LeftIconWrapper>
      )}

      <div style={{ flex: 1, ...(contentStyles ?? {}) }}>{children}</div>
    </StyledButton>
  );
};

export default Button;
