import React from "react";
import firebase from "firebase";
import styled from "styled-components";
import Box from "ui/Box";
import { ReactComponent as WhatsappLogo } from "assets/channelLogos/whatsapp.svg";
import {
  BreadcrumbChevron,
  BreadcrumbCurrentPage,
  BreadcrumbLink,
  Breadcrumbs,
} from "ui/Breadcrumbs";
import Button from "ui/Button";
import Text from "ui/Text";
import db, { functions } from "lib/db";
import { useAuth } from "lib/auth";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { WhatsappBusinessAccount } from "common/src/types";

const Wrapper = styled.div`
  max-width: 70rem;
  margin: 0 auto;
  flex: 1;
`;

const Heading = styled.div`
  flex: 1;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
  margin-bottom: 2rem;
`;

const SettingsBox = styled.div`
  border-radius: 12px;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  padding: 2rem 1.5rem;
`;

const WhatsappChannelConfigure = (
  props: RouteComponentProps<{ whatsappBusinessAccountId: string }>
) => {
  const history = useHistory();
  const [isDisconnecting, setDisconnecting] = React.useState(false);
  const [whatsappBusinessAccountDoc, setWhatsappBusinessAccountDoc] =
    React.useState<
      firebase.firestore.DocumentSnapshot<WhatsappBusinessAccount>
    >();

  React.useEffect(() => {
    (async () => {
      setWhatsappBusinessAccountDoc(
        await db.whatsappBusinessAccounts
          .doc(props.match.params.whatsappBusinessAccountId)
          .get()
      );
    })();
  }, [props.match.params.whatsappBusinessAccountId]);

  if (whatsappBusinessAccountDoc === undefined) {
    return null;
  }

  return (
    <Wrapper>
      <Heading>
        <Breadcrumbs marginBottom="20px">
          <BreadcrumbLink to="/settings/channels">Canales</BreadcrumbLink>
          <BreadcrumbChevron />
          <BreadcrumbCurrentPage>Whatsapp</BreadcrumbCurrentPage>
        </Breadcrumbs>
        <Box flex alignItems="center">
          <WhatsappLogo />
          <Box marginLeft="20px" cols={1}>
            <Text size="display_xs" weight="semibold">
              Configuración de {whatsappBusinessAccountDoc.data()!.name}
            </Text>
          </Box>
        </Box>
      </Heading>

      <SettingsBox>
        <Text size="text_xl" weight="semibold" marginBottom="1rem">
          Desconectar la cuenta
        </Text>
        <Text
          size="text_md"
          color="gray600"
          marginBottom="1.5rem"
          style={{ maxWidth: "40rem" }}
        >
          Al desconectar la cuenta no podrás recibir ni responder a los mesajes
          directos de whatsapp de esta cuenta. Los contactos o mensajes
          existentes no se borrarán
        </Text>
        <Button
          size="md"
          kind="secondary"
          disabled={isDisconnecting}
          isLoading={isDisconnecting}
          onClick={async () => {
            setDisconnecting(true);
            const result = await functions.disconnectWhatsappBusinessAccount({
              whatsappBusinessAccountId:
                props.match.params.whatsappBusinessAccountId,
            });
            if (result.ok) {
              history.push("/settings/channels");
            }
          }}
        >
          {isDisconnecting ? "Desconectando" : "Desconectar canal"}
        </Button>
      </SettingsBox>
    </Wrapper>
  );
};

export default WhatsappChannelConfigure;
