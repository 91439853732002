import { useAppData } from "components/AppData";
import React from "react";
import styled, { useTheme } from "styled-components";
import Box from "ui/Box";
import Button from "ui/Button";
import Checkbox from "ui/Checkbox";
import Dropdown from "ui/Dropdown";
import Icon from "ui/Icon";
import Text from "ui/Text";

const Input = styled.input`
  border: none;

  &::placeholder {
    color: ${(props) => props.theme.colors.gray500};
  }

  &:focus {
    outline: none;
  }
`;

export default function ContactsLabelFilter(props: {
  labels: undefined | string[];
  onChange: (labels: string[] | undefined) => void;
}) {
  const theme = useTheme();
  const appData = useAppData();
  const [query, setQuery] = React.useState("");

  const filteredLabels = React.useMemo(() => {
    return Object.keys(appData.labels).filter((labelId) => {
      if (!query) return true;

      return appData.labels[labelId]
        .data()
        .label.toLowerCase()
        .includes(query.toLowerCase());
    });
  }, [appData.labels, query]);

  const firstLabel = props.labels?.[0];

  return (
    <Dropdown
      side="bottom"
      align="start"
      trigger={
        <div>
          <Button kind="secondary" size="md" contentStyles={{ height: "100%" }}>
            <Box flex alignItems="center" style={{ height: "100%" }}>
              <Box marginRight="0.5rem">Etiquetas</Box>
              {firstLabel ? (
                <Box
                  flex
                  alignItems="center"
                  style={{
                    alignSelf: "stretch",
                    borderLeft: `solid 1px ${theme.colors.gray300}`,
                  }}
                >
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      backgroundColor: appData.labels[firstLabel].data().color,
                      borderRadius: 4,
                      marginRight: 8,
                      marginLeft: 8,
                    }}
                  />

                  <Text size="text_sm" weight="medium" inline>
                    {appData.labels[firstLabel].data().label}
                  </Text>

                  {props.labels!.length > 1 && (
                    <Text size="text_sm" color="gray500" inline>
                      &nbsp;+{props.labels!.length - 1}
                    </Text>
                  )}
                </Box>
              ) : (
                <Icon icon="chevronDown" />
              )}
            </Box>
          </Button>
        </div>
      }
    >
      <Box paddingBottom="0.5rem">
        <Input
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Buscar etiqueta"
        />
      </Box>

      <Box paddingTop="0.5rem" paddingBottom="0.5rem">
        <Box
          margin="-0.5rem"
          padding="0rem 0.75rem"
          paddingTop="0.5rem"
          style={{
            borderTop: `solid 1px ${theme.colors.gray200}`,
          }}
        >
          {filteredLabels.length === 0 && (
            <Text size="text_xs" weight="medium">
              No has creado etiquetas
            </Text>
          )}
          {filteredLabels.map((labelId: string) => (
            <Box
              flex
              alignItems="center"
              paddingTop="0.50rem"
              paddingBottom="0.50rem"
              key={labelId}
            >
              <Checkbox
                checked={props.labels?.includes(labelId)}
                onChange={(checked) =>
                  props.onChange(
                    checked
                      ? [...(props.labels ?? []), labelId]
                      : props.labels?.filter((id) => labelId !== id)
                  )
                }
              />

              <div
                style={{
                  width: 8,
                  height: 8,
                  backgroundColor: appData.labels[labelId].data().color,
                  borderRadius: 4,
                  marginRight: 8,
                  marginLeft: 8,
                }}
              />

              <Text size="text_sm" weight="medium">
                {appData.labels[labelId].data().label}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
    </Dropdown>
  );
}
