import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import i18n from "@emoji-mart/data/i18n/es.json";
import styled from "styled-components";
import Picker from "@emoji-mart/react";
import { Twemoji } from "react-emoji-render";
import IconButton from "./IconButton";

const isMac = navigator.platform.includes("Mac");

export const EmojiRenderer = ({ children }: { children: string }) => {
  if (isMac) {
    return <>{children}</>;
  }

  if (children.length === 0) {
    return null;
  }

  return <Twemoji text={children} />;
};

export const PickerWrapper = styled.div`
  position: relative;
`;

export const PickerWindow = styled.div`
  position: absolute;
  transform: translateX(-100%) translateY(-100%);
`;

export const EmojiPicker = ({
  onEmojiSelect,
}: {
  onEmojiSelect?: (txt: string) => void;
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <PickerWrapper>
      <IconButton
        size="md"
        icon="faceSmile"
        onClick={() => {
          console.log("setting open");
          setOpen(true);
        }}
      />
      {open && (
        <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
          <PickerWindow>
            <Picker
              onEmojiSelect={(e: any) => {
                setOpen(false);
                onEmojiSelect?.(e.native);
              }}
              set={isMac ? "native" : "twitter"}
              theme="light"
              i18n={i18n}
            />
          </PickerWindow>
        </OutsideClickHandler>
      )}
    </PickerWrapper>
  );
};
