import React from "react";
import firebase from "firebase";
import { v4 } from "uuid";
import styled, { css } from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import Button from "ui/Button";
import Box from "ui/Box";
import IconButton from "ui/IconButton";
import FilePickerHandler from "ui/FilePickerHandler";
import AttachedFile from "../AttachedFile";
import { ComposingMessage, Contact, Message } from "common/src/types";
import { EmojiPicker, EmojiRenderer } from "ui/emojis";
import Text from "ui/Text";
import FileImage from "ui/FileImage";
import { useAuth } from "lib/auth";
import Dropzone from "react-dropzone";
import { useCommandBar } from "components/commandBar";
import { ScheduledMessageComposerIndicator } from "../scheduledMessages";
import { VoiceRecorder, useRecorder } from "./voiceRecorder";

const Wrapper = styled.div`
  margin: 1.5rem 2rem;
  background-color: red;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.gray100};
`;

export const ComposerWrapper = styled.div`
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.gray300};
  padding: 0.5rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const Textarea = styled(TextareaAutosize)`
  color: ${(props) => props.theme.colors.gray900};
  border: none;
  appearance: none;
  resize: none;
  width: 100%;
  font-size: 16px;
  min-height: 66px;
  line-height: 24px;

  &:disabled {
    background-color: transparent;
  }

  &:focus {
    outline: none;
  }

  &:placeholder {
    color: ${(props) => props.theme.colors.gray500};
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const QuotedMessageWrapper = styled.div`
  margin: -0.5rem;
  margin-bottom: 0.5rem;
  padding: 1rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray300};
`;

const ReplyGreenLine = styled.div`
  background-color: ${(props) => props.theme.colors.primary500};
  width: 2px;
  border-radius: 1px;
`;

const AttachedImage = styled(FileImage)<{ location: string | undefined }>`
  max-height: 260px;
  object-fit: contain;
  object-position: left;
  border: solid 2px ${(props) => props.theme.colors.primary500};
  border-radius: 8px;

  ${(props) =>
    props.location === undefined &&
    css`
      opacity: 0;
    `}
`;

interface ComposerBaseMessage {
  quotedMessage?: {
    id: string;
    data: Message;
  };
}

export interface ComposerFile {
  name: string;
  size: number;
  type: string;
}

interface ComposerTextMessage extends ComposerBaseMessage {
  type: "text";
  body: string;
}

interface ComposerFileMessage extends ComposerBaseMessage {
  type: "file";
  file: ComposerFile;
  location: string;
}

interface ComposerImageMessage extends ComposerBaseMessage {
  type: "image";
  file: ComposerFile;
  location: string;
  caption?: string;
}

interface ComposerAudioMessage extends ComposerBaseMessage {
  type: "audio";
  blob: Blob;
  mimeType: string;
  duration: number;
  location: string;
}

export type ComposerMessage =
  | ComposerTextMessage
  | ComposerAudioMessage
  | ComposerFileMessage
  | ComposerImageMessage;

interface ComposerProps {
  onSendMessage: (message: ComposerMessage) => void;
  isSendButtonLoading?: boolean;
  channel: Message["channel"];
  contact: Contact;
  contactId: string;
  onQuotedMessageClear?: () => void;
  quotedMessage?: {
    id: string;
    data: Message;
  };
}

const QuotedMessage = ({
  message,
  contact,
  onClear,
}: {
  message: Message;
  contact: Contact;
  onClear?: () => void;
}) => {
  if (message.channel === "internal") {
    return null;
  }

  let senderName = "Respondiendo al mensaje";

  if (message.direction === "incoming") {
    senderName = `Estás respondiendo a ${contact.name}`;
  }

  return (
    <QuotedMessageWrapper>
      <Box flex>
        <ReplyGreenLine />
        <Box marginLeft="0.5rem" cols={1}>
          <Text size="text_sm" color="gray500" marginBottom="0.25rem">
            {senderName}
          </Text>
          {message.message.type === "text" && (
            <Text size="text_sm">
              <EmojiRenderer>{message.message.body}</EmojiRenderer>
            </Text>
          )}
        </Box>
        <Box>
          <IconButton icon="x" size="sm" onClick={onClear} />
        </Box>
      </Box>
    </QuotedMessageWrapper>
  );
};

export interface ComposerRef {
  focus: () => void;
}

interface UpdateMessageAction {
  type: "update_message";
  newMessage: string;
}

interface AttachDocumentAction {
  type: "attach_document";
  file: File;
}

interface AttachImageAction {
  type: "attach_image";
  file: File;
}

interface ClearAttachmentAction {
  type: "clear_attachment";
}

interface ResetComposingMessageState {
  type: "reset_state";
}

interface FileUploadCompleteAction {
  type: "file_uploaded";
  location: string;
}

interface SavedReplySelectedAction {
  type: "saved_reply_selected";
  message: ComposingMessage;
}

interface VoiceRecordedAction {
  type: "voice_message_recorded";
  audio: {
    blob: Blob;
    mimeType: string;
    duration: number;
  };
  audioLocation: string;
}

type ComposingMessageAction =
  | UpdateMessageAction
  | AttachDocumentAction
  | ClearAttachmentAction
  | AttachImageAction
  | ResetComposingMessageState
  | FileUploadCompleteAction
  | VoiceRecordedAction
  | SavedReplySelectedAction;

const useComposingMessage = () => {
  const initialState = { type: "text", message: "" } as ComposingMessage;
  const reducer = (
    state: ComposingMessage,
    action: ComposingMessageAction
  ): ComposingMessage => {
    if (action.type === "update_message") {
      if (state.type === "text") {
        return {
          type: "text",
          message: action.newMessage,
        };
      } else if (state.type === "image") {
        return {
          type: "image",
          file: state.file,
          location: state.location,
          caption: action.newMessage,
        };
      }
    } else if (action.type === "clear_attachment") {
      return {
        type: "text",
        message: "",
      };
    } else if (action.type === "attach_document") {
      return {
        type: "document",
        file: action.file,
        location: undefined,
      };
    } else if (action.type === "attach_image") {
      return {
        type: "image",
        file: action.file,
        location: undefined,
      };
    } else if (action.type === "reset_state") {
      return {
        type: "text",
        message: "",
      };
    } else if (
      action.type === "file_uploaded" &&
      (state.type === "document" || state.type === "image")
    ) {
      return {
        type: state.type,
        file: state.file,
        location: action.location,
      };
    } else if (action.type === "saved_reply_selected") {
      return action.message;
    } else if (action.type === "voice_message_recorded") {
      return {
        type: "audio",
        blob: action.audio.blob,
        mimeType: action.audio.mimeType,
        duration: action.audio.duration,
        location: action.audioLocation,
      };
    }

    return state;
  };

  return React.useReducer(reducer, initialState);
};

const Composer = React.forwardRef(
  (
    {
      onSendMessage,
      isSendButtonLoading,
      contact,
      contactId,
      channel,
      quotedMessage,
      onQuotedMessageClear,
    }: ComposerProps,
    ref
  ) => {
    const auth = useAuth();
    const textareaRef = React.useRef<HTMLTextAreaElement>(null);
    const commandBar = useCommandBar();
    const voiceRecorder = useRecorder();
    const [composingMessage, dispatchComposingMessageAction] =
      useComposingMessage();

    React.useEffect(() => {
      (async () => {
        if (voiceRecorder.recordedAudio) {
          const location = `workspace-media/${
            auth.workspaceId
          }/recorded-voice-messages/${v4()}-audio`;
          await firebase
            .storage()
            .ref()
            .child(location)
            .put(voiceRecorder.recordedAudio.blob, {
              contentType: voiceRecorder.recordedAudio.mimeType,
            });
          dispatchComposingMessageAction({
            type: "voice_message_recorded",
            audio: {
              ...voiceRecorder.recordedAudio,
              duration: voiceRecorder.duration,
            },
            audioLocation: location,
          });
        }
      })();
    }, [voiceRecorder, dispatchComposingMessageAction, auth.workspaceId]);

    React.useImperativeHandle(ref, () => {
      const composerRef: ComposerRef = {
        focus: () => textareaRef.current?.focus(),
      };
      return composerRef;
    });

    React.useEffect(() => {
      if (
        (composingMessage.type === "document" ||
          composingMessage.type === "image") &&
        quotedMessage
      ) {
        dispatchComposingMessageAction({
          type: "clear_attachment",
        });
      }
    }, [composingMessage, quotedMessage, dispatchComposingMessageAction]);

    const handleSend = React.useCallback(() => {
      voiceRecorder.cancel();
      onQuotedMessageClear?.();
      voiceRecorder.reset();
      dispatchComposingMessageAction({ type: "reset_state" });

      if (composingMessage.type === "document" && composingMessage.location) {
        onSendMessage({
          type: "file",
          quotedMessage,
          file: composingMessage.file,
          location: composingMessage.location,
        });
      } else if (
        composingMessage.type === "image" &&
        composingMessage.location
      ) {
        onSendMessage({
          type: "image",
          file: composingMessage.file,
          location: composingMessage.location,
          caption: composingMessage.caption,
        });
      } else if (
        composingMessage.type === "text" &&
        composingMessage.message.length > 0
      ) {
        onSendMessage({
          type: "text",
          quotedMessage,
          body: composingMessage.message,
        });
      } else if (composingMessage.type === "audio") {
        onSendMessage({
          type: "audio",
          blob: composingMessage.blob,
          mimeType: composingMessage.mimeType,
          duration: composingMessage.duration,
          location: composingMessage.location,
        });
      }
    }, [
      composingMessage,
      onSendMessage,
      voiceRecorder,
      quotedMessage,
      dispatchComposingMessageAction,
      onQuotedMessageClear,
    ]);

    const textareaValue = React.useMemo(() => {
      if (composingMessage.type === "text") {
        return composingMessage.message;
      } else if (composingMessage.type === "image") {
        return composingMessage.caption;
      }
      return "";
    }, [composingMessage]);

    const composingMessageLocation =
      composingMessage.type === "image" && composingMessage.location;
    const composingMessageFile =
      composingMessage.type === "image" && composingMessage.file;

    React.useEffect(() => {
      (async () => {
        if (
          composingMessage.type === "image" &&
          composingMessageLocation === undefined &&
          composingMessageFile
        ) {
          const location = `workspace-media/${auth.workspaceId}/${v4()}/${
            composingMessageFile.name
          }`;
          await firebase
            .storage()
            .ref()
            .child(location)
            .put(composingMessageFile);
          dispatchComposingMessageAction({
            type: "file_uploaded",
            location,
          });
        }
      })();
    }, [
      composingMessage.type,
      composingMessageLocation,
      composingMessageFile,
      dispatchComposingMessageAction,
      auth.workspaceId,
    ]);

    return (
      <Dropzone
        noClick
        onDrop={(acceptedFiles) => {
          if (acceptedFiles.length === 1) {
            const file = acceptedFiles[0];
            const fileSizeInMB = file.size * 1e-6;

            if (file.type.includes("image") && fileSizeInMB < 100) {
              dispatchComposingMessageAction({
                type: "attach_image",
                file,
              });
            } else if (fileSizeInMB < 100) {
              dispatchComposingMessageAction({
                type: "attach_document",
                file,
              });
            }
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <Wrapper>
            <ScheduledMessageComposerIndicator contactId={contactId} />
            <ComposerWrapper {...getRootProps()}>
              <input {...getInputProps()} />
              {quotedMessage && (
                <QuotedMessage
                  message={quotedMessage.data}
                  contact={contact}
                  onClear={onQuotedMessageClear}
                />
              )}

              {composingMessage.type === "image" && (
                <div>
                  <AttachedImage
                    file={composingMessage.file}
                    location={composingMessage.location}
                    style={{
                      maxHeight: 260,
                      objectFit: "contain",
                      objectPosition: "left",
                    }}
                  />
                </div>
              )}

              <Textarea
                minRows={2}
                autoFocus
                ref={textareaRef}
                disabled={
                  voiceRecorder.isRecording ||
                  (composingMessage.type !== "text" &&
                    composingMessage.type !== "image")
                }
                placeholder={
                  ["text", "audio"].includes(composingMessage.type)
                    ? "Escribe aquí"
                    : ""
                }
                onPaste={(e) => {
                  const firstItem = e.clipboardData.items[0];
                  if (firstItem.type.includes("image")) {
                    e.preventDefault();
                    const file = firstItem.getAsFile();
                    if (file !== null) {
                      console.log("what");
                      dispatchComposingMessageAction({
                        type: "attach_image",
                        file,
                      });
                    }
                  }
                }}
                value={textareaValue}
                onChange={(e) =>
                  dispatchComposingMessageAction({
                    type: "update_message",
                    newMessage: e.target.value,
                  })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSend();
                  }

                  if (
                    e.key === "Backspace" &&
                    composingMessage.type === "image" &&
                    (composingMessage.caption?.length ?? 0) === 0
                  ) {
                    e.preventDefault();
                    dispatchComposingMessageAction({
                      type: "clear_attachment",
                    });
                  }
                }}
              />
              {composingMessage.type === "document" && (
                <AttachedFile
                  file={composingMessage.file}
                  isLoading={composingMessage.location === undefined}
                  onClear={() => {
                    dispatchComposingMessageAction({
                      type: "reset_state",
                    });
                  }}
                  onUploadSuccess={(location) =>
                    dispatchComposingMessageAction({
                      type: "file_uploaded",
                      location,
                    })
                  }
                />
              )}
              <Buttons>
                {voiceRecorder.isRecording && (
                  <VoiceRecorder
                    duration={voiceRecorder.duration}
                    onStop={voiceRecorder.stopRecording}
                    finished={voiceRecorder.recordingFinished}
                    onCancel={() => {
                      voiceRecorder.cancel();
                      if (composingMessage.type === "audio") {
                        dispatchComposingMessageAction({
                          type: "reset_state",
                        });
                      }
                    }}
                    audio={voiceRecorder.recordedAudio}
                  />
                )}
                {!voiceRecorder.isRecording && (
                  <Box marginRight="1rem" flex alignItems="center">
                    <IconButton
                      icon="bookmark"
                      size="md"
                      onClick={() => {
                        const onSavedReplySelected = (
                          composerMessage: ComposingMessage
                        ) => {
                          dispatchComposingMessageAction({
                            type: "saved_reply_selected",
                            message: composerMessage,
                          });
                        };
                        commandBar.openSavedReplies(onSavedReplySelected);
                      }}
                    />
                    <EmojiPicker
                      onEmojiSelect={(emoji) => {
                        if (composingMessage.type === "text") {
                          dispatchComposingMessageAction({
                            type: "update_message",
                            newMessage: `${composingMessage.message.slice(
                              0,
                              textareaRef.current?.selectionEnd
                            )}${emoji}${composingMessage.message.slice(
                              textareaRef.current?.selectionEnd
                            )}`,
                          });
                        }
                      }}
                    />
                    {(channel === "whatsapp_web" ||
                      channel === "instagram" ||
                      channel === "page") && (
                      <FilePickerHandler
                        accept={
                          ["instagram", "page"].includes(channel)
                            ? "image/*"
                            : undefined
                        }
                        onFile={(file) => {
                          const fileSizeInMB = file.size * 1e-6;

                          if (fileSizeInMB >= 100) {
                            return alert(
                              "El limite de tamaño al enviar archivos es de 100 MB"
                            );
                          }

                          if (quotedMessage) {
                            onQuotedMessageClear?.();
                          }

                          if (file.type.includes("image")) {
                            dispatchComposingMessageAction({
                              type: "attach_image",
                              file,
                            });
                          } else {
                            dispatchComposingMessageAction({
                              type: "attach_document",
                              file,
                            });
                          }
                        }}
                      >
                        <IconButton size="md" icon="paperclip" />
                      </FilePickerHandler>
                    )}

                    <IconButton
                      icon="clock"
                      size="md"
                      onClick={() => {
                        commandBar.scheduleMessage(
                          composingMessage,
                          contactId,
                          channel,
                          () =>
                            dispatchComposingMessageAction({
                              type: "reset_state",
                            })
                        );
                      }}
                    />

                    <IconButton
                      icon="microphone"
                      size="md"
                      onClick={voiceRecorder.startRecording}
                    />
                  </Box>
                )}
                <Button
                  size="md"
                  kind="primary"
                  onClick={() => handleSend()}
                  disabled={
                    voiceRecorder.isRecording &&
                    composingMessage.type !== "audio"
                  }
                  isLoading={isSendButtonLoading}
                >
                  Enviar
                </Button>
              </Buttons>
            </ComposerWrapper>
          </Wrapper>
        )}
      </Dropzone>
    );
  }
);

export default Composer;
