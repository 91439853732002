import React from "react";
import { Type } from "typescript";
import Typesense, { Client as TypesenseClient } from "typesense";
import { useAuth } from "./auth";

const TypesenseContext = React.createContext<TypesenseClient | undefined>(
  undefined
);

export function TypesenseProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuth();
  const client = React.useMemo(() => {
    if (auth.workspace?.typesenseSearchKey === undefined) {
      return undefined;
    }

    return new Typesense.Client({
      nodes: [
        {
          host: "mojwsfb4rkihuzv3p-1.a1.typesense.net",
          port: 443,
          protocol: "https",
        },
      ],
      apiKey: auth.workspace?.typesenseSearchKey,
      connectionTimeoutSeconds: 10,
    });
  }, [auth.workspace?.typesenseSearchKey]);

  return (
    <TypesenseContext.Provider value={client}>
      {children}
    </TypesenseContext.Provider>
  );
}

export function useTypesense() {
  return React.useContext(TypesenseContext);
}
