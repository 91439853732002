import Box from "ui/Box";
import { ReactComponent as EmptyStateIllustration } from "../../assets/empty-inbox-illustration.svg";
import Text from "ui/Text";

export default function InboxEmptyState() {
  return (
    <Box
      cols={1}
      flex
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <EmptyStateIllustration />

      <Text size="text_md" weight="semibold" marginTop="1.5rem">
        No tienes mensajes
      </Text>
    </Box>
  );
}
