import React from "react";
import Box from "ui/Box";
import Icon from "ui/Icon";
import IconButton from "ui/IconButton";
import Text from "ui/Text";
import { ChannelCard } from ".";
import Button from "ui/Button";
import { ReactComponent as InstagramLogo } from "assets/channelLogos/instagram.svg";
import { ReactComponent as MessengerLogo } from "assets/channelLogos/messenger.svg";
import { ReactComponent as WhatsappLogo } from "assets/channelLogos/whatsapp.svg";
import { useConnectInstagram } from "components/settings/InstagramSettings";
import { useConnectMessenger } from "components/settings/MessengerSettings";
import Spinner from "ui/Spinner";

export default function AddChannelView(props: {
  onBack?: () => void;
  onSelectChannel: (channel: "whatsapp" | "instagram" | "messenger") => void;
}) {
  const {
    handleConnect: handleConnectIG,
    autoConnectStatus: igAutoConnectStatus,
  } = useConnectInstagram({
    autoConnectAccounts: true,
  });

  const {
    handleConnect: handleConnectMessenger,
    autoConnectStatus: fbAutoConnectStatus,
  } = useConnectMessenger({
    autoConnectAccounts: true,
  });

  const { onBack } = props;

  React.useEffect(() => {
    if (["done", "no_accounts", "error"].includes(igAutoConnectStatus)) {
      onBack?.();
    } else if (["done", "no_accounts", "error"].includes(fbAutoConnectStatus)) {
      onBack?.();
    }
  }, [igAutoConnectStatus, fbAutoConnectStatus, onBack]);

  if (igAutoConnectStatus === "loading" || fbAutoConnectStatus === "loading") {
    return (
      <Box
        style={{ height: 400 }}
        flex
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner />
        <Text size="text_lg" weight="medium">
          Agregando canal...
        </Text>
      </Box>
    );
  }

  return (
    <>
      <Box
        alignItems="center"
        justifyContent="space-between"
        padding="1rem 1.5rem"
        borderBottom
        flex
      >
        <Box flex alignItems="center">
          <IconButton icon="arrowLeft" onClick={props.onBack} />
          <Text size="text_lg" weight="semibold" marginLeft="1rem">
            Cuál canal te gustaría agregar?
          </Text>
        </Box>

        <Icon icon="x" />
      </Box>

      <Box padding="1.5rem">
        <ChannelCard>
          <Box flex alignItems="center">
            <WhatsappLogo />

            <Text size="text_md" weight="semibold" marginLeft="0.75rem">
              WhatsApp
            </Text>
          </Box>

          <Button size="sm" onClick={() => props.onSelectChannel("whatsapp")}>
            Seleccionar
          </Button>
        </ChannelCard>

        <ChannelCard>
          <Box flex alignItems="center">
            <InstagramLogo />

            <Text size="text_md" weight="semibold" marginLeft="0.75rem">
              Instagram
            </Text>
          </Box>

          <Button size="sm" onClick={handleConnectIG}>
            Seleccionar
          </Button>
        </ChannelCard>

        <ChannelCard>
          <Box flex alignItems="center">
            <MessengerLogo />

            <Text size="text_md" weight="semibold" marginLeft="0.75rem">
              Messenger
            </Text>
          </Box>

          <Button size="sm" onClick={handleConnectMessenger}>
            Seleccionar
          </Button>
        </ChannelCard>
      </Box>
    </>
  );
}
