import styled, { css } from "styled-components";

const Wrapper = styled.div`
  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  height: 2.5rem;
  display: inline-flex;
  align-items: center;
`;

const Button = styled.button<{ active?: boolean }>`
  padding: 0rem 1rem;
  appearance: none;
  border: none;
  font-size: 14px;
  line-height: 20px;
  height: 100%;
  font-weight: 600;
  color: ${(props) => props.theme.colors.gray700};
  background-color: transparent;
  border-right: solid 1px ${(props) => props.theme.colors.gray200};
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      background-color: ${props.theme.colors.gray50};
    `}

  &:last-child {
    border-right: 0px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.white};
  }
`;

const ButtonGroup = {
  Wrapper,
  Button,
};

export default ButtonGroup;
