import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
`;

const HiddenFileInput = styled.input`
  position: absolute;
  visibility: hidden;
`;

interface FilePickerHandlerProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  children: React.ReactNode;
  onFile?: (file: File) => void;
}

const FilePickerHandler = ({
  children,
  onFile,
  ...inputProps
}: FilePickerHandlerProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Wrapper
      onClick={() => {
        fileInputRef.current?.click();
      }}
    >
      {children}
      <HiddenFileInput
        ref={fileInputRef}
        type="file"
        value={""}
        {...inputProps}
        onChange={(e) => {
          if (e.target.files?.length === 1) {
            const file = e.target.files.item(0);
            if (file) {
              onFile?.(file);
            }
          }
        }}
      />
    </Wrapper>
  );
};

export default FilePickerHandler;
