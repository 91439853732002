import React from "react";
import { Conversation, Message } from "common/src/types";
import Dexie, { Table } from "dexie";
import { useAuth } from "./auth";

export type IDBConversation = {
  id: string;
  lastUpdateAt: number;
  assignee: string;
  status: string;
  lastMessageAt: number;
  lastMessageDirection: string;
  data: Conversation;
};

export type IDBMessage = {
  id: string;
  timestamp: number;
  contactId: string;
  data: Message;
};

export class ZamiDexie extends Dexie {
  conversations!: Table<IDBConversation>;
  messages!: Table<IDBMessage>;

  constructor(workspaceId: string) {
    super(`zami-${workspaceId}`);

    this.version(1).stores({
      conversations:
        "id, [assignee+status], [lastMessageDirection+status], lastUpdateAt",
    });

    this.version(2).stores({
      conversations:
        "id, [assignee+status], [lastMessageDirection+status], [assignee+lastMessageDirection+status], lastUpdateAt",
    });

    this.version(3).stores({
      conversations:
        "id, [assignee+status], [lastMessageDirection+status], [assignee+lastMessageDirection+status], lastUpdateAt",
      messages: "id, contactId",
    });
  }
}

const IDBContext = React.createContext<ZamiDexie>(undefined as any);

export function IDBProvider(props: { children: React.ReactNode }) {
  const auth = useAuth();

  const [idb, setIdb] = React.useState<ZamiDexie>();

  React.useEffect(() => {
    if (auth.workspaceId) {
      const db = new ZamiDexie(auth.workspaceId);
      setIdb(db);
      (window as any).idb = db;
    } else {
      setIdb(undefined);
    }
  }, [auth]);

  return (
    <IDBContext.Provider value={idb as unknown as ZamiDexie}>
      {props.children}
    </IDBContext.Provider>
  );
}

export const useIDB = () => React.useContext(IDBContext);
