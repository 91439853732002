import styled from "styled-components";

import { Message } from "common/src/types";

import Icon, { iconMap } from "ui/Icon";

const Wrapper = styled.div`
  display: flex;

  & .icon {
    &:not(:first-child) {
      margin-left: -8px;
    }
  }
`;

type ChannelBadgesProps = {
  channels: Exclude<Message["channel"], "internal">[];
};

const channelIconMap: Record<
  Exclude<Message["channel"], "internal">,
  keyof typeof iconMap
> = {
  whatsapp: "whatsappBadge",
  instagram: "instagramBadge",
  whatsapp_web: "whatsappBadge",
  page: "messengerBadge",
  email: "emailBadge",
};

const ChannelBadges = ({ channels }: ChannelBadgesProps) => {
  return (
    <Wrapper>
      {channels.map((c) => (
        <Icon className="icon" key={c} icon={channelIconMap[c]} size={24} />
      ))}
    </Wrapper>
  );
};

export default ChannelBadges;
