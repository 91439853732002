import React from "react";
import firebase from "firebase";
import styled from "styled-components";
import { ReactComponent as WhatsappLogo } from "assets/channelLogos/whatsapp.svg";
import { ReactComponent as LogoFull } from "assets/icons/logoFull.svg";
import { ReactComponent as InstagramLogo } from "assets/channelLogos/instagram.svg";
import { ReactComponent as MessengerLogo } from "assets/channelLogos/messenger.svg";
import { ReactComponent as ZamiLogo } from "assets/logo.svg";
import { OnboardingStyles } from "./common";
import Box from "ui/Box";
import Text from "ui/Text";
import Button from "ui/Button";
import { useHistory } from "react-router-dom";
import { isOnMobile } from "lib/responsive";
import ProgressWindow from "./progressWindow";
import Badge from "ui/Badge";
import Icon from "ui/Icon";
import db from "lib/db";
import { useAuth } from "lib/auth";
import {
  FacebookPage,
  InstagramAccount,
  WhatsappWebAccount,
} from "common/src/types";
import Modal from "ui/Modal";
import { useIntercom } from "react-use-intercom";

const ChannelWrapper = styled(Box)`
  padding: 0.75rem 1rem;
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  border-radius: 12px;
  max-width: 100%;
  min-width: 340px;
  margin: 0rem 0.5rem;

  &:first-child {
    margin-left: 0rem;
  }

  &:last-child {
    margin-right: 0rem;
  }
`;

function Channel(props: {
  logo: React.ReactNode;
  name: string;
  onConnect?: () => void;
  badge?: string;
}) {
  return (
    <ChannelWrapper flex alignItems="center">
      <Box>{props.logo}</Box>
      <Box cols={1} marginLeft="0.75rem">
        <Text size="text_md" weight="semibold">
          {props.name}
        </Text>
      </Box>
      {props.onConnect && (
        <Button kind="secondary" size="sm" onClick={props.onConnect}>
          Conectar
        </Button>
      )}

      {props.badge && (
        <Badge
          label={
            <Box flex alignItems="center">
              <Box style={{ marginRight: 4 }}>
                <Icon icon="check" stroke="primary600" size={12} />
              </Box>
              {props.badge}
            </Box>
          }
          size="sm"
          variant="success"
        />
      )}
    </ChannelWrapper>
  );
}

type ChannelList = (
  | {
      type: "whatsapp";
      id: string;
      data: WhatsappWebAccount;
    }
  | {
      type: "instagram";
      id: string;
      data: InstagramAccount;
    }
  | {
      type: "facebook";
      id: string;
      data: FacebookPage;
    }
)[];

export function OnboardingConnectFirstChannel() {
  const history = useHistory();
  const auth = useAuth();
  const intercom = useIntercom();
  const isMobile = React.useMemo(() => isOnMobile(), []);
  const [shouldGoToDesktop, setShouldGoToDesktop] = React.useState(false);
  const [showNextStepModal, setShowNextStepModal] = React.useState(false);
  const [whatsappWebAccounts, setWhatsappWebAccounts] = React.useState<
    firebase.firestore.QueryDocumentSnapshot<WhatsappWebAccount>[]
  >([]);
  const [instagramAccounts, setInstagramAcconts] = React.useState<
    firebase.firestore.QueryDocumentSnapshot<InstagramAccount>[]
  >([]);
  const [facebookPages, setFacebookPages] = React.useState<
    firebase.firestore.QueryDocumentSnapshot<FacebookPage>[]
  >([]);

  const channels = React.useMemo(() => {
    const result: ChannelList = [
      ...whatsappWebAccounts.map((doc) => ({
        type: "whatsapp" as "whatsapp",
        id: doc.id,
        data: doc.data(),
      })),
      ...instagramAccounts.map((doc) => ({
        type: "instagram" as "instagram",
        id: doc.id,
        data: doc.data(),
      })),
      ...facebookPages.map((doc) => ({
        type: "facebook" as "facebook",
        id: doc.id,
        data: doc.data(),
      })),
    ];

    return result;
  }, [whatsappWebAccounts, instagramAccounts, facebookPages]);

  React.useEffect(() => {
    const unsubscribeWhatsappWebAccounts = db.whatsappWebAccounts
      .where("workspaceId", "==", auth.workspaceId)
      .where("status", "==", "active")
      .onSnapshot((snap) => {
        setWhatsappWebAccounts(snap.docs);
      });

    const unsubscribeInstagramAccounts = db.instagramAccounts
      .where("workspaceId", "==", auth.workspaceId)
      .onSnapshot((snap) => {
        setInstagramAcconts(snap.docs);
      });

    const unsubscribeFacebookPages = db.facebookPages
      .where("workspaceId", "==", auth.workspaceId)
      .onSnapshot((snap) => {
        setFacebookPages(snap.docs);
      });

    return () => {
      unsubscribeWhatsappWebAccounts();
      unsubscribeFacebookPages();
      unsubscribeInstagramAccounts();
    };
  }, [auth]);

  if (shouldGoToDesktop) {
    return (
      <div>
        <OnboardingStyles />
        <Box
          flex
          direction="column"
          marginTop="2rem"
          marginBottom="4rem"
          paddingLeft="1rem"
          paddingRight="1rem"
        >
          <ZamiLogo height={48} width={48} />
          <Text
            size="text_lg"
            weight="semibold"
            marginTop="1rem"
            marginBottom="1rem"
          >
            Continúa en la computadora
          </Text>

          <Text
            size="text_md"
            color="gray600"
            style={{ maxWidth: 500 }}
            marginBottom="1rem"
          >
            En tu computadora, dirígete a{" "}
            <Text weight="semibold" inline color="gray900">
              https://app.zamiapp.com/login
            </Text>{" "}
            e inicia sesión para continuar con el proceso de configuración.
          </Text>

          <Box marginTop="0.5rem" onClick={() => setShouldGoToDesktop(false)}>
            <Button block kind="primary">
              Volver atrás
            </Button>
          </Box>
        </Box>
      </div>
    );
  }

  if (isMobile) {
    return (
      <div>
        <OnboardingStyles />
        <Box
          flex
          direction="column"
          marginTop="2rem"
          marginBottom="4rem"
          paddingLeft="1rem"
          paddingRight="1rem"
        >
          <ZamiLogo height={48} width={48} />
          <Text
            size="text_lg"
            weight="semibold"
            marginTop="1rem"
            marginBottom="1rem"
          >
            Tu prueba gratis fue activada!
          </Text>

          <Text
            size="text_md"
            color="gray600"
            style={{ maxWidth: 500 }}
            marginBottom="1rem"
          >
            Es tiempo de configurar Zami. Para continuar necesitarás iniciar
            sesión con tu cuenta desde una computadora diriendote a{" "}
            <Text weight="semibold" inline color="gray900">
              https://app.zamiapp.com/login
            </Text>{" "}
          </Text>

          <Text size="text_md" color="gray600" style={{ maxWidth: 500 }}>
            Si necesitas ayuda o quieres contactarte con nosotros puedes hacer{" "}
            <Text
              inline
              color="primary700"
              style={{ textDecoration: "underline" }}
              weight="semibold"
              onClick={() => {
                intercom.show();
              }}
            >
              click aquí
            </Text>
          </Text>
        </Box>
      </div>
    );
  }

  return (
    <ProgressWindow currentStep={2}>
      <OnboardingStyles />

      <Box style={{ height: "100%" }} flex direction="column">
        <Box
          marginTop="4rem"
          marginBottom="4rem"
          paddingLeft="3rem"
          paddingRight="3rem"
        >
          <Box marginBottom="3rem">
            <LogoFull />
          </Box>
          <Text
            size="display_sm"
            weight="semibold"
            marginTop="1.5rem"
            marginBottom="0.75rem"
            color="primary900"
          >
            Es tiempo de configurar Zami
          </Text>

          <Text size="text_lg" color="gray600">
            Conecta todos los canales de comunicación de tu negocio, te
            ayudaremos a mantenerlos organizados.
          </Text>
        </Box>

        {channels.length > 0 && (
          <>
            <Box padding="0rem 3.5rem">
              <Text size="text_lg" weight="semibold" marginBottom="1rem">
                Canales conectados
              </Text>
            </Box>

            <Box
              flex
              direction="row"
              alignItems="center"
              padding="0rem 3rem"
              marginBottom="3rem"
            >
              {channels.map((channel) => {
                if (channel.type === "whatsapp") {
                  return (
                    <Channel
                      name={channel.data.name ?? "WhatsApp"}
                      logo={<WhatsappLogo />}
                      badge="Conectado"
                    />
                  );
                }

                if (channel.type === "instagram") {
                  return (
                    <Channel
                      name={channel.data.username}
                      logo={<InstagramLogo />}
                      badge="Conectado"
                    />
                  );
                }

                if (channel.type === "facebook") {
                  return (
                    <Channel
                      name={channel.data.name ?? "Messenger"}
                      logo={<MessengerLogo />}
                      badge="Conectado"
                    />
                  );
                }

                return null;
              })}
            </Box>
          </>
        )}

        <Box padding="0rem 3.5rem">
          <Text size="text_lg" weight="semibold" marginBottom="1rem">
            Agrega canales
          </Text>
        </Box>

        <Box flex direction="row" alignItems="center" padding="0rem 3rem">
          <Channel
            name="Whatsapp"
            logo={<WhatsappLogo />}
            onConnect={() => history.push("/onboarding/connect-whatsapp")}
          />

          <Channel
            name="Instagram"
            logo={<InstagramLogo />}
            onConnect={() => history.push("/onboarding/connect-instagram")}
          />

          <Channel
            name="Messenger"
            logo={<MessengerLogo />}
            onConnect={() => history.push("/onboarding/connect-messenger")}
          />
        </Box>

        <Box style={{ flex: 1 }} />
        <Box
          borderTop
          padding="1.5rem 0rem"
          flex
          justifyContent="flex-end"
          paddingRight="1.5rem"
        >
          <Button
            kind="primary"
            size="md"
            title="Ir al próximo paso"
            disabled={channels.length === 0}
            onClick={() => {
              setShowNextStepModal(true);
            }}
          >
            Ir al próximo paso
          </Button>
        </Box>
      </Box>

      <Modal
        open={showNextStepModal}
        contentStyle={{ maxWidth: 500 }}
        onOpenChange={() => setShowNextStepModal(false)}
      >
        <Modal.Title>Ya conectaste todos los canales?</Modal.Title>
        <Modal.Description>
          Para tener una prueba efectiva de Zami recomendamos conectar todos los
          canales de tu negocio. Si ya lo hiciste puedes continuar
        </Modal.Description>

        <Modal.Actions>
          <Box flex justifyContent="flex-end">
            <Box marginRight="0.5rem">
              <Button
                size="md"
                kind="secondary"
                onClick={() => setShowNextStepModal(false)}
              >
                Agregar otro canal
              </Button>
            </Box>
            <Button
              size="md"
              kind="primary"
              onClick={() => {
                history.push("/onboarding/invite-team-members");
              }}
            >
              Continuar
            </Button>
          </Box>
        </Modal.Actions>
      </Modal>
    </ProgressWindow>
  );
}
