import React from "react";
import ProgressWindow from "./progressWindow";
import { ReactComponent as LogoFull } from "assets/icons/logoFull.svg";
import { OnboardingStyles } from "./common";
import Text from "ui/Text";
import mobileApp from "assets/mobile-app.png";
import qrCodeImage from "assets/onelink-zami-qr-code.png";
import Box from "ui/Box";
import Button from "ui/Button";
import { useHistory } from "react-router-dom";
import db from "lib/db";
import { useAuth } from "lib/auth";

export default function DownloadMobileApp() {
  const history = useHistory();
  const auth = useAuth();

  const handleFinish = async () => {
    await db.workspaces.doc(auth.workspaceId).update({
      onboarding: {
        connectedChannels: true,
        finishedOnboarding: true,
      },
    });
  };

  return (
    <ProgressWindow currentStep={4}>
      <OnboardingStyles />

      <Box style={{ height: "100%" }} flex direction="column">
        <Box
          marginTop="4rem"
          marginBottom="4rem"
          paddingLeft="3rem"
          paddingRight="3rem"
          cols={1}
        >
          <Box marginBottom="3rem">
            <LogoFull />
          </Box>
          <Text
            size="display_sm"
            weight="semibold"
            marginTop="1.5rem"
            marginBottom="0.75rem"
            color="primary900"
          >
            Descarga Zami en tu móvil
          </Text>

          <Text size="text_lg" color="gray600" style={{ marginBottom: 40 }}>
            Zami funciona en la computadora y en el móvil para que puedas
            atender a todos tus clientes sin importar donde estes.
          </Text>

          <Box
            style={{
              backgroundColor: "#F0ECE4",
              borderRadius: 24,
              padding: "0rem 64px",
            }}
            flex
            justifyContent="center"
            alignItems="center"
          >
            <img
              alt="asdf"
              src={qrCodeImage}
              style={{
                height: 358,
                objectFit: "contain",
                borderRadius: 24,
                marginRight: "3rem",
              }}
            />
            <img
              alt="Zami app movil"
              src={mobileApp}
              style={{
                height: 358,
                objectFit: "contain",
                padding: "2rem 3rem",
              }}
            />
          </Box>
        </Box>

        <Box
          borderTop
          padding="1.5rem 0rem"
          flex
          justifyContent="flex-end"
          paddingRight="1.5rem"
        >
          <Button kind="primary" size="md" type="submit" onClick={handleFinish}>
            Iniciar
          </Button>
        </Box>
      </Box>
    </ProgressWindow>
  );
}
