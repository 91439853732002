import { createGlobalStyle } from "styled-components";

export const OnboardingStyles = createGlobalStyle`
  html {
    overflow: initial;
  }

  body {
    background-color: ${(props) => props.theme.colors.beige50};
  }
`;
