import React from "react";
import styled from "styled-components";

import Text from "ui/Text";

type HeaderProps = {
  title: string;
  badge: React.ReactNode;
  subtitle?: string;
  actions: React.ReactNode;
  children?: React.ReactNode;
};

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 2rem 0rem;
  padding-bottom: 1.5rem;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: ${(props) => `1px solid ${props.theme.colors.gray200}`};
`;

const LeftContainer = styled.div`
  .title {
    display: flex;
    align-items: center;
  }

  .badge {
    margin-left: 0.75rem;
  }
`;

const RightContainer = styled.div`
  margin-left: 1rem;
`;

const Header = ({ title, badge, subtitle, actions, children }: HeaderProps) => {
  return (
    <>
      <Wrapper>
        <Container>
          <LeftContainer>
            <div className="title">
              <Text size="display_sm" weight="semibold" marginBottom="0.25rem">
                {title}
              </Text>
              <div className="badge">{badge}</div>
            </div>
            {subtitle && (
              <Text color="gray600" size="text_md" weight="regular">
                {subtitle}
              </Text>
            )}
          </LeftContainer>
          <RightContainer>{actions}</RightContainer>
        </Container>
        {children}
      </Wrapper>
    </>
  );
};

export default Header;
