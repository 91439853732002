import { useCachedState } from "lib/cache";
import React, { SetStateAction } from "react";
import { RouteComponentProps } from "react-router-dom";

export type ConversationOrder = "newest_first" | "oldest_first";
export type ConversationStatus = "open" | "closed" | "snoozed";

export const ChatContext = React.createContext<{
  conversationsOrderFilter: ConversationOrder;
  conversationsStatusFilter: ConversationStatus;
  setConversationsOrderFilter: React.Dispatch<
    SetStateAction<ConversationOrder>
  >;
  setConversationsStatusFilter: React.Dispatch<
    SetStateAction<ConversationStatus>
  >;
}>({
  conversationsOrderFilter: undefined as any,
  conversationsStatusFilter: undefined as any,
  setConversationsOrderFilter: undefined as any,
  setConversationsStatusFilter: undefined as any,
});

type ChatProps = {} & RouteComponentProps<{
  inbox: string;
  selectedConversationId?: string;
}>;

export const wrapWithChatContext =
  (Component: React.FC<ChatProps>) => (props: ChatProps) => {
    const [conversationsOrderFilter, setConversationsOrderFilter] =
      useCachedState<ConversationOrder>("conversation_order", "oldest_first");
    const [conversationsStatusFilter, setConversationsStatusFilter] =
      React.useState<ConversationStatus>("open");

    const ctxVal = React.useMemo(() => {
      return {
        conversationsOrderFilter,
        setConversationsOrderFilter,
        conversationsStatusFilter,
        setConversationsStatusFilter,
      };
    }, [
      conversationsOrderFilter,
      setConversationsOrderFilter,
      conversationsStatusFilter,
      setConversationsStatusFilter,
    ]);

    return (
      <ChatContext.Provider value={ctxVal}>
        <Component {...props} />
      </ChatContext.Provider>
    );
  };

export const useChat = () => React.useContext(ChatContext);
