import firebase from "firebase";
import { useAuth } from "lib/auth";
import { ReactComponent as ZamiLogo } from "assets/logo.svg";
import React, { FormEvent } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import Button from "ui/Button";
import Field from "ui/Field";
import Input from "ui/Input";
import Text from "ui/Text";
import Box from "ui/Box";
import { OnboardingStyles } from "./common";
import Link from "ui/link";

const Wrapper = styled.div`
  max-width: 22.5rem;
  margin: 0 auto;
  margin-top: 6rem;
`;

const LoginPage = () => {
  const [loginState, setLoginState] = React.useState<
    "default" | "loading" | "error"
  >("default");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { user, workspace } = useAuth();

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    setLoginState("loading");

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (err) {
      setLoginState("error");
    }
  };

  if (user && workspace) {
    return <Redirect to="/" />;
  }

  if (user && !workspace) {
    // Logged in but still loading some data
    return null;
  }

  return (
    <form onSubmit={handleLogin}>
      <OnboardingStyles />
      <Wrapper>
        <Box flex justifyContent="center" marginBottom="1rem">
          <ZamiLogo height={48} width={48} />
        </Box>
        <Text
          size="display_sm"
          weight="semibold"
          marginBottom="2rem"
          flex
          justifyContent="center"
          color="gray900"
        >
          Ingresa a tu cuenta
        </Text>

        <Field label="Correo electrónico">
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Correo electrónico"
            required
          />
        </Field>

        <Field label="Constraseña">
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Contraseña"
            required
          />
        </Field>

        <Box flex justifyContent="flex-end" marginBottom="1.5rem">
          <Link href="/reset-password">Olvidé mi contraseña</Link>
        </Box>

        {loginState === "error" && (
          <Text size="text_sm" marginBottom="1.25rem" color="error700">
            Correo electrónico o contraseña incorrecta. Porfavor intentalo
            nuevamente.
          </Text>
        )}

        <Button block type="submit" disabled={loginState === "loading"}>
          {loginState === "loading" ? "Iniciando sesión..." : "Iniciar sesión"}
        </Button>
      </Wrapper>
    </form>
  );
};

export default LoginPage;
