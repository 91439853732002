import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
dayjs.extend(relativeTime);

interface TimeAgoOption {
  singular: string;
  plural: string;
}

export interface TimeAgoOptions {
  minuteSuffix: TimeAgoOption;
  hourSuffix: TimeAgoOption;
  daySuffix: TimeAgoOption;
  weekSuffix: TimeAgoOption;
  monthSuffix: TimeAgoOption;
  yearSuffix: TimeAgoOption;
}

const timeAgoStringDefaultOptions: TimeAgoOptions = {
  minuteSuffix: {
    singular: "m",
    plural: "m",
  },
  hourSuffix: {
    singular: "h",
    plural: "h",
  },
  daySuffix: {
    singular: "d",
    plural: "d",
  },
  weekSuffix: {
    singular: "w",
    plural: "w",
  },
  monthSuffix: {
    singular: "mo",
    plural: "mo",
  },
  yearSuffix: {
    singular: "a",
    plural: "a",
  },
};

export const timeAgoLongSuffixOptions: TimeAgoOptions = {
  minuteSuffix: {
    singular: "minuto",
    plural: "minutos",
  },
  hourSuffix: {
    singular: "hora",
    plural: "horas",
  },
  daySuffix: {
    singular: "día",
    plural: "días",
  },
  weekSuffix: {
    singular: "semana",
    plural: "semanas",
  },
  monthSuffix: {
    singular: "mes",
    plural: "meses",
  },
  yearSuffix: {
    singular: "año",
    plural: "años",
  },
};

export const useTimeAgoString = (
  timestamp: number,
  options: TimeAgoOptions = timeAgoStringDefaultOptions
) => {
  const generateTimeAgoString = React.useCallback(
    (timestamp: number) => {
      const messageDate = dayjs.unix(timestamp);
      const minutesFromNow = dayjs().diff(messageDate, "minutes");

      if (minutesFromNow === 0) {
        return "ahora";
      }

      if (minutesFromNow < 60) {
        return `${minutesFromNow} ${
          minutesFromNow > 1
            ? options.minuteSuffix.plural
            : options.minuteSuffix.singular
        }`;
      }

      const hoursFromNow = dayjs().diff(messageDate, "hours");

      if (hoursFromNow < 24) {
        return `${hoursFromNow} ${
          hoursFromNow > 1
            ? options.hourSuffix.plural
            : options.hourSuffix.singular
        }`;
      }

      const daysFromNow = dayjs().diff(messageDate, "days");

      if (daysFromNow < 7) {
        return `${daysFromNow} ${
          daysFromNow > 1
            ? options.daySuffix.plural
            : options.daySuffix.singular
        }`;
      }

      const weeksFromNow = dayjs().diff(messageDate, "weeks");

      if (weeksFromNow < 4) {
        return `${weeksFromNow} ${
          weeksFromNow > 1
            ? options.weekSuffix.plural
            : options.weekSuffix.singular
        }`;
      }

      const monthsFromNow = dayjs().diff(messageDate, "months");

      if (monthsFromNow < 12) {
        return `${monthsFromNow} ${
          monthsFromNow > 1
            ? options.monthSuffix.plural
            : options.monthSuffix.singular
        }`;
      }

      const yearsFromNow = dayjs().diff(messageDate, "years");

      return `${yearsFromNow} ${
        yearsFromNow > 1
          ? options.yearSuffix.plural
          : options.yearSuffix.singular
      }`;
    },
    [options]
  );

  const [timeAgoString, setTimeAgoString] = React.useState(() =>
    generateTimeAgoString(timestamp)
  );

  React.useEffect(() => {
    setTimeAgoString(generateTimeAgoString(timestamp));
  }, [timestamp, generateTimeAgoString]);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    const refreshTimeAgoStringNextTimeNeeded = () => {
      const tsSecondsReplaced = dayjs()
        .second(timestamp % 60)
        .unix();

      const tsNow = dayjs().unix();

      const tsMinuteEnd =
        tsSecondsReplaced > tsNow ? tsSecondsReplaced : tsSecondsReplaced + 60;
      const secondsUntilNextMinute = tsMinuteEnd - tsNow;
      timeout = setTimeout(() => {
        setTimeAgoString(generateTimeAgoString(timestamp));
        refreshTimeAgoStringNextTimeNeeded();
      }, secondsUntilNextMinute * 1000);
    };

    refreshTimeAgoStringNextTimeNeeded();

    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }, [setTimeAgoString, generateTimeAgoString, timestamp, timeAgoString]);

  return timeAgoString;
};

export function TimeAgoString(props: {
  timestamp: number;
  options?: TimeAgoOptions;
}) {
  const timeAgo = useTimeAgoString(props.timestamp, props.options);

  return <span>{timeAgo}</span>;
}
