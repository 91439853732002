import React from "react";
import styled, { css } from "styled-components";
import Box, { BoxProps } from "./Box";

const Wrapper = styled(Box)<{ active?: boolean }>`
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  border: solid 1px ${(props) => props.theme.colors.gray300};
  position: relative;

  ${(props) =>
    props.active &&
    css`
      border: none;
      background-color: ${(props) => props.theme.colors.primary600};

      &:after {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: white;
        top: 50%;
        left: 50%;
        transform: translate(-3px, -3px);
      }
    `}
`;

const Radio = ({ active, ...restProps }: { active?: boolean } & BoxProps) => {
  return <Wrapper active={active} {...restProps} />;
};

export default Radio;
