import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import styled, { css } from "styled-components";
import { FieldMetaProps } from "formik";
import Icon from "ui/Icon";
import Text from "ui/Text";
import { IconKey } from "./Icon";
import Box from "./Box";

type InputProps = {
  iconLeft?: IconKey;
  meta?: FieldMetaProps<"input">;
} & React.InputHTMLAttributes<HTMLInputElement>;

const InputWrapper = styled.div`
  position: relative;
`;

const InputBox = styled.div`
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.gray300};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justifu-content: stretch;
  padding: 0px 0.75rem;
`;

const InputContainer = styled.div<{ error?: boolean; disabled?: boolean }>`
  background-color: white;
  display: flex;
  align-items: center;
  border: solid 1px ${(props) => props.theme.colors.gray300};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 0px 0.75rem;
  height: 40px;
  width: 100%;
  outline: none;
  transition-duration: 150ms;

  &:placeholder {
    color: ${(props) => props.theme.colors.gray500};
  }

  &:focus-within {
    border: solid 1px ${(props) => props.theme.colors.primary300};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px ${(props) => props.theme.colors.primary100};
  }

  ${(props) =>
    props.error &&
    css`
      border: solid 1px ${(props) => props.theme.colors.error600};

      &:focus-within {
        border: solid 1px ${(props) => props.theme.colors.error600};
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
          0px 0px 0px 4px ${(props) => props.theme.colors.error100};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${props.theme.colors.gray50};
      cursor: not-allowed;
      user-select: none;

      input {
        user-select: none;
      }
    `}
`;

const StyledInput = styled.input<React.HTMLProps<HTMLInputElement>>`
  all: unset;
  width: 100%;

  &:placeholder {
    color: ${(props) => props.theme.colors.gray500};
  }
`;

const Helper = styled.div<{ error?: boolean }>`
  position: absolute;
  padding-top: 6px;
  color: ${(props) => props.theme.colors.gray600};

  ${(props) =>
    props.error &&
    css`
      color: ${(props) => props.theme.colors.error700};
    `}
`;

const Input = ({ iconLeft, meta, disabled, ...props }: InputProps) => {
  const hasError = Boolean(meta?.touched && meta?.error);

  return (
    <InputWrapper>
      <InputContainer error={hasError} disabled={disabled}>
        {iconLeft && (
          <div
            style={{
              marginRight: "0.5rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon icon={iconLeft} stroke="gray500" />
          </div>
        )}
        <StyledInput disabled={disabled} {...props} />
      </InputContainer>
      {meta?.touched && meta?.error && (
        <Helper error={hasError}>
          <Text size="text_sm" weight="regular">
            {meta.error}
          </Text>
        </Helper>
      )}
    </InputWrapper>
  );
};

const InlineInputWrapper = styled.input<{ disable: boolean }>`
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 4px 0.75rem;
  border: solid 1px ${(props) => props.theme.colors.gray300};
  outline: none;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.gray900};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  ${(props) =>
    props.disable &&
    css`
      border: solid 1px transparent;
      box-shadow: none;
      cursor: pointer;

      &:hover {
        border: solid 1px ${(props) => props.theme.colors.gray300};
      }
    `}

  &:focus-within {
    border: solid 1px ${(props) => props.theme.colors.primary300};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px ${(props) => props.theme.colors.primary100};
  }
`;

export function InlineInput(
  props: InputProps & { value: string | undefined; onFinish?: () => void }
) {
  const [focused, setFocused] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (focused) {
      console.log("focusing");
      inputRef.current?.focus();
    }
  }, [focused]);

  const handleFinish = () => {
    setFocused(false);
    inputRef.current?.blur();
    props.onFinish?.();
  };

  return (
    <div style={{ position: "relative" }}>
      <InlineInputWrapper
        {...props}
        value={!focused && !props.value ? "Agregar" : props.value ?? ""}
        ref={inputRef}
        disable={!focused}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleFinish();
          }
        }}
        onClick={() => {
          setFocused(true);
        }}
        onBlur={handleFinish}
      />
    </div>
  );
}

export function CardInput() {
  return (
    <InputBox>
      <Box cols={1}>
        <CardElement
          options={{
            style: {
              base: {},
            },
          }}
        />
      </Box>
    </InputBox>
  );
}

export default Input;
