import React, { useMemo, useState } from "react";
import parsePhoneNumber from "libphonenumber-js";
import {
  Formik,
  FormikValues,
  Field as FormikField,
  FieldProps,
  Form,
} from "formik";
import { object, string } from "yup";

import { DialogProps } from "@radix-ui/react-dialog";

import { Contact } from "common/src/types";

import { functions } from "lib/db";

import Button from "ui/Button";
import Field from "ui/Field";
import Input from "ui/Input";
import Modal from "ui/Modal";
import { useAppData } from "components/AppData";
import InternationalPhoneInput, {
  getCountryCode,
} from "components/internationalPhoneInput";

type ContactFormModalProps = {
  contact?: Contact | null;
  contactId?: string;
  workspaceId?: string;
  trigger?: React.ReactNode;
  onSubmitted?: () => void | Promise<void>;
} & DialogProps;

const initialState = { email: "", name: "", phone: "" };

const validationSchema = object().shape({
  name: string().required("Este campo es requerido"),
  email: string().email("Este correo no es válido"),
});

const ContactFormModal = ({
  contact,
  contactId,
  trigger,
  workspaceId,
  onOpenChange,
  open,
  ...props
}: ContactFormModalProps) => {
  const [isOpen, setOpen] = useState(false);
  const initialValues = useMemo(() => contact || initialState, [contact]);
  const [phone, setPhone] = React.useState("");
  const [countryCode, setCountryCode] = React.useState(
    () => getCountryCode() ?? "MX"
  );
  const appData = useAppData();

  const canSeeUnassignedContacts =
    appData.permissions.canSeeAllContacts ||
    appData.permissions.canSeeUnassignedContacts;

  const createContact = async (values: FormikValues) => {
    try {
      if (workspaceId) {
        await functions.createContact({
          lastActivityAt: Math.trunc(Date.now() / 1000),
          workspaceId,
          name: values.name as string,
          phone: phone
            ? parsePhoneNumber(
                phone,
                countryCode as any
              )?.formatInternational() ?? undefined
            : undefined,
          email: values.email as string,
          assignee: canSeeUnassignedContacts
            ? ""
            : `wm:${appData.workspaceMemberSelf.id}`,
        });
        await props.onSubmitted?.();
        onOpenChange && onOpenChange(false);
      }
    } catch {
      // Error track...
    }
  };

  const updateContact = async (values: FormikValues) => {
    try {
      if (workspaceId) {
        await functions.updateContact(contactId as string, {
          lastActivityAt: Math.trunc(Date.now() / 1000),
          workspaceId,
          name: values.name as string,
          phone: values.phone as string,
          email: values.email as string,
        });
        await props.onSubmitted?.();
        onOpenChange && onOpenChange(false);
      }
    } catch {
      // Error track...
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={contactId ? updateContact : createContact}
    >
      {({ handleSubmit, resetForm, isSubmitting }) => (
        <Modal
          trigger={trigger}
          open={open ? open : isOpen}
          onOpenChange={(open) => {
            onOpenChange ? onOpenChange(open) : setOpen(open);
            if (!open) resetForm();
          }}
          {...props}
        >
          <Modal.Title>
            {contactId ? "Editar contacto" : "Crear contacto"}
          </Modal.Title>

          <div style={{ paddingTop: "20px" }}>
            <Form onSubmit={handleSubmit}>
              <FormikField name="name">
                {({ field, meta }: FieldProps) => (
                  <Field label="Nombre">
                    <Input
                      {...field}
                      meta={meta}
                      type="text"
                      placeholder="John Doe"
                      required
                    />
                  </Field>
                )}
              </FormikField>

              <FormikField name="email">
                {({ field, meta }: FieldProps) => (
                  <Field label="Correo electrónico">
                    <Input
                      {...field}
                      meta={meta}
                      type="email"
                      placeholder="zami@example.com"
                    />
                  </Field>
                )}
              </FormikField>

              <FormikField name="phone">
                {({ field, meta }: FieldProps) => (
                  <Field label="Teléfono">
                    <InternationalPhoneInput
                      value={phone}
                      onChange={setPhone}
                      countryCode={countryCode}
                      onCountryCodeChange={setCountryCode}
                    />
                  </Field>
                )}
              </FormikField>

              <Modal.Actions
                style={{
                  display: "flex",
                  gap: "0.75rem",
                  justifyContent: "flex-end",
                }}
              >
                <Modal.Cancel asChild>
                  <Button kind="secondary" size="md">
                    Cancelar
                  </Button>
                </Modal.Cancel>
                <Button isLoading={isSubmitting} size="md" type="submit">
                  {contactId ? "Guardar" : "Crear"}
                </Button>
              </Modal.Actions>
            </Form>
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default ContactFormModal;
