import React from "react";
import { storage } from "lib/db";

interface AudioPlayerProps {
  location: string;
}

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const AudioPlayer = ({ location }: AudioPlayerProps) => {
  const [audioUrl, setAudioUrl] = React.useState<string>();

  React.useEffect(() => {
    (async () => {
      const downloadUrl = await storage.ref(location).getDownloadURL();
      setAudioUrl(downloadUrl);
    })();
  }, [location]);

  if (isSafari) {
    return (
      <audio controls>
        <source src={audioUrl} type="audio/mpeg" />
      </audio>
    );
  }

  return <audio controls src={audioUrl} />;
};

export default AudioPlayer;
