import React from "react";
import styled, { css } from "styled-components";
import Icon from "./Icon";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input<{ checked: boolean }>`
  width: 20px;
  height: 20px;
  margin: 0;
  border-radius: 6px;
  appearance: none;
  cursor: pointer;
  transition-duration: 300ms;

  ${(props) =>
    !props.checked &&
    css`
      background-color: white;
      border: solid 1px ${props.theme.colors.gray300};
      cursor: pointer;

      &:hover {
        border: solid 1px ${props.theme.colors.primary600};
        background-color: ${props.theme.colors.primary100};
      }

      &:focus {
        border: solid 1px ${props.theme.colors.primary300};
      }

      &:focus-visible {
        box-shadow: 0px 0px 0px 4px #e3f1ec;
        border: solid 1px ${props.theme.colors.primary300};
        outline: none;
      }
    `}

  ${(props) =>
    props.checked &&
    css`
      background-color: ${props.theme.colors.primary600};

      &:hover {
        background-color: ${props.theme.colors.primary700};
      }

      &:focus-visible {
        outline: none;
        box-shadow: 0px 0px 0px 4px #e3f1ec;
      }
    `}
`;

const CheckWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

const Checkbox = ({
  checked,
  onChange,
}: {
  checked?: boolean;
  onChange: (newVal: boolean) => void;
}) => {
  return (
    <Wrapper onClick={() => onChange(!checked)}>
      <CheckboxInput checked={Boolean(checked)} type="checkbox" />
      <CheckWrapper>
        <Icon size={16} icon="check" />
      </CheckWrapper>
    </Wrapper>
  );
};

export default Checkbox;
