import React, { PropsWithChildren } from "react";
import { v4 } from "uuid";
import { useAuth } from "./auth";
import firebase from "firebase";
import { useAppData } from "../components/AppData";
import db from "./db";

export const clientId = v4();

export const PresenceTracker = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  const appData = useAppData();

  React.useEffect(() => {
    if (auth.user && appData.workspaceMemberSelf.id) {
      const userStatusDbRef = firebase
        .database()
        .ref(`/status/${appData.workspaceMemberSelf.id}/${clientId}`);

      firebase
        .database()
        .ref(".info/connected")
        .on("value", async (snapshot) => {
          if (snapshot.val() === false) {
            db.workspaceMembers.doc(appData.workspaceMemberSelf.id).update({
              presence: "offline",
            });

            return;
          }

          await userStatusDbRef.onDisconnect().set({
            state: "offline",
            last_changed: firebase.database.ServerValue.TIMESTAMP,
          });

          await userStatusDbRef.set({
            state: "online",
            last_changed: firebase.database.ServerValue.TIMESTAMP,
          });

          db.workspaceMembers.doc(appData.workspaceMemberSelf.id).update({
            presence: "online",
          });
        });

      return () => {
        firebase.database().ref(".info/connected").off("value");
      };
    }
  }, [auth.user, appData.workspaceMemberSelf.id]);

  return <>{children}</>;
};
