import React from "react";
import * as Avatar from "@radix-ui/react-avatar";
import qs from "qs";
import axios from "axios";
import styled from "styled-components";
import { ReactComponent as InstagramLogo } from "assets/channelLogos/instagram.svg";
import { ReactComponent as Check2Icon } from "assets/icons/check2.svg";
import Box from "ui/Box";
import {
  BreadcrumbChevron,
  BreadcrumbCurrentPage,
  BreadcrumbLink,
  Breadcrumbs,
} from "ui/Breadcrumbs";
import Text from "ui/Text";
import Button from "ui/Button";
import Icon from "ui/Icon";
import config from "config";
import igPlaceholderImage from "assets/igplaceholder.jpeg";
import { CORE_URL, functions } from "lib/db";
import { useAuth } from "lib/auth";
import Modal from "ui/Modal";
import { useHistory, useLocation, useParams } from "react-router-dom";

export interface FBInstagramAccount {
  id: string;
  username: string;
  profile_pic: string;
  igId: string;
}

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: 70rem;
  padding: 0rem 2rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
`;

const InstagramRequirementsHelp = styled.div`
  background-color: ${(props) => props.theme.colors.gray50};
  border-radius: 12px;
  padding: 2rem 1.5rem;
  margin-top: 2rem;
`;

const IgPic = styled(Avatar.Image)`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`;

const Pic = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`;

const IgFallback = styled(Avatar.Fallback)`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`;

const Root = styled(Avatar.Root)`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
`;

const DocumentationLinkBox = styled(Box)`
  cursor: pointer;
`;

const IGWrapper = styled.div`
  padding: 1rem 0rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
`;

const LockIconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  border-radius: 10px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface UseConnectInstagramOptions {
  autoConnectAccounts: boolean;
}

export function useConnectInstagram(opts?: UseConnectInstagramOptions) {
  const [loginLoading, setLoginLoading] = React.useState(false);
  const auth = useAuth();
  const [accessToken, setAccessToken] = React.useState<string>();
  const [instagramAccounts, setInstagramAccounts] =
    React.useState<(FBInstagramAccount & { pageId: string })[]>();
  const [connectedInstagramAccounts, setConnectedInstagramAccounts] =
    React.useState<Record<string, boolean>>({});
  const [instagramConnectionStatuses, setInstagramConnectionStatuses] =
    React.useState<Record<string, undefined | "loading">>({});
  const [
    instagramMessagesBlockedModalOpen,
    setInstagramMessagesBlockedModalOpen,
  ] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  const queryParams = qs.parse(location.search.replace("?", ""));

  const [autoConnectStatus, setAutoConnectStatus] = React.useState<
    "new" | "loading" | "error" | "done" | "no_accounts"
  >("new");

  const handleConnect = React.useCallback(() => {
    FB.login(
      (response) => {
        const accessToken = response.authResponse?.accessToken;
        console.log(response);
        if (accessToken && queryParams.debug) {
          history.push(`/settings/channels/instagram/debug/${accessToken}`);
        } else if (accessToken) {
          setLoginLoading(true);
          setAccessToken(accessToken);
        }
      },
      {
        scope:
          "instagram_basic,instagram_manage_messages,pages_manage_metadata,pages_read_engagement,pages_show_list",
      }
    );
  }, [queryParams.debug, history]);

  const handleConnectIgAccount = React.useCallback(
    async (igAccount: NonNullable<typeof instagramAccounts>[number]) => {
      if (auth.workspaceId && accessToken) {
        setInstagramConnectionStatuses((statuses) => ({
          ...statuses,
          [igAccount.id]: "loading",
        }));

        try {
          const result = await functions.connectInstagramAccount({
            fbUserAccessToken: accessToken,
            fbInstagramAccountId: igAccount.id,
            fbPageId: igAccount.pageId,
            workspaceId: auth.workspaceId,
          });

          if (result.ok) {
            setConnectedInstagramAccounts(
              result.instagramAccounts.reduce((acc, curr) => {
                return {
                  ...acc,
                  [curr.instagramId]: true,
                };
              }, {})
            );
          } else {
            if (result.error === "third_party_messages_blocked") {
              setInstagramMessagesBlockedModalOpen(true);
            }
          }
        } catch (err) {}

        setInstagramConnectionStatuses((statuses) => ({
          ...statuses,
          [igAccount.id]: undefined,
        }));
      }
    },
    [accessToken, auth.workspaceId]
  );

  React.useEffect(() => {
    if (accessToken) {
      (async () => {
        const resp = await axios.get<
          {
            access_token: string;
            name: string;
            id: string;
            instagram_business_account?: {
              id: string;
            };
          }[]
        >(`${CORE_URL}/facebook-pages`, {
          params: {
            access_token: accessToken,
          },
        });

        let instagramAccounts: (FBInstagramAccount & { pageId: string })[] = [];

        for (const page of resp.data) {
          if (
            page.instagram_business_account !== undefined &&
            page.access_token
          ) {
            const result = await axios.get<{
              data: FBInstagramAccount[];
            }>(
              `https://graph.facebook.com/v16.0/${page.id}/instagram_accounts`,
              {
                params: {
                  access_token: page.access_token,
                  fields: "id,username,profile_pic",
                },
              }
            );

            instagramAccounts = [
              ...instagramAccounts,
              ...result.data.data.map((ig) => ({
                ...ig,
                igId: page.instagram_business_account!.id,
                pageId: page.id,
              })),
            ];
          }
        }

        setInstagramAccounts(instagramAccounts);
        setLoginLoading(false);
      })();
    }
  }, [accessToken]);

  React.useEffect(() => {
    (async () => {
      if (opts?.autoConnectAccounts) {
        if (instagramAccounts === undefined) {
          return;
        } else if (instagramAccounts.length === 0) {
          setAutoConnectStatus("no_accounts");
          return;
        }

        const instagramAccountsToConnect = instagramAccounts!.filter(
          (igAccount) => !connectedInstagramAccounts[igAccount.igId]
        );

        setAutoConnectStatus("loading");

        try {
          if (instagramAccountsToConnect.length > 0) {
            for (const igAccount of instagramAccountsToConnect) {
              await handleConnectIgAccount(igAccount);
            }
          }

          setAutoConnectStatus("done");
        } catch (err) {
          setAutoConnectStatus("error");
        }
      }
    })();
  }, [
    opts?.autoConnectAccounts,
    instagramAccounts,
    handleConnectIgAccount,
    connectedInstagramAccounts,
  ]);

  React.useEffect(() => {
    (async () => {
      if (auth.workspaceId) {
        const result = await functions.getInstagramAccounts(auth.workspaceId);
        setConnectedInstagramAccounts(
          result.data.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.instagramId]: true,
            };
          }, {})
        );
      }
    })();
  }, [auth.workspaceId]);

  React.useEffect(() => {
    FB.init({
      appId: config.fbAppId,
      cookie: true,
      xfbml: true,
      version: "v16.0",
    });
  }, []);

  return {
    instagramMessagesBlockedModalOpen,
    setInstagramMessagesBlockedModalOpen,
    loginLoading,
    instagramAccounts,
    handleConnect,
    instagramConnectionStatuses,
    connectedInstagramAccounts,
    handleConnectIgAccount,
    autoConnectStatus,
  };
}

export function InstagramThirdPartyMessagesErrorModal(props: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  return (
    <Modal open={props.open} onOpenChange={props.onOpenChange}>
      <Modal.FeaturedIcon icon="lock" />
      <Modal.Title>No podemos acceder a tus mensajes de Instagram.</Modal.Title>

      <Text
        size="text_sm"
        color="gray600"
        marginTop="0.25rem"
        marginBottom="2rem"
      >
        Para otorgar el permiso debes abrir tu configuración de Instagram, ve a{" "}
        <Text inline size="text_sm" weight="semibold">
          Privacidad &gt; Mensajes
        </Text>{" "}
        y luego activa la opción{" "}
        <Text inline size="text_sm" weight="semibold">
          “Permitir acceso a mensajes”
        </Text>
        .
      </Text>

      <Modal.Close style={{ width: "100%" }}>
        <Button block onClick={() => props.onOpenChange(false)}>
          Cerrar
        </Button>
      </Modal.Close>
    </Modal>
  );
}

const InstagramSettings = () => {
  const {
    loginLoading,
    handleConnect,
    instagramMessagesBlockedModalOpen,
    setInstagramMessagesBlockedModalOpen,
    instagramAccounts,
    instagramConnectionStatuses,
    handleConnectIgAccount,
    connectedInstagramAccounts,
  } = useConnectInstagram();

  return (
    <Wrapper>
      <InstagramThirdPartyMessagesErrorModal
        open={instagramMessagesBlockedModalOpen}
        onOpenChange={setInstagramMessagesBlockedModalOpen}
      />
      <Heading>
        <Breadcrumbs marginBottom="20px">
          <BreadcrumbLink to="/settings/channels">Canales</BreadcrumbLink>
          <BreadcrumbChevron />
          <BreadcrumbCurrentPage>Instagram</BreadcrumbCurrentPage>
        </Breadcrumbs>
        <Box flex alignItems="center">
          <InstagramLogo />
          <Box marginLeft="20px" cols={1}>
            <Text size="display_sm" weight="semibold">
              Instagram
            </Text>
            <Text size="text_md" color="gray600">
              Recibe tus mensajes directos en Zami.
            </Text>
          </Box>
          <Box flex alignItems="center">
            <Button
              isLoading={loginLoading}
              icon="puzzlePiece"
              size="md"
              onClick={handleConnect}
            >
              Conectar Instagram
            </Button>
          </Box>
        </Box>
      </Heading>
      <Box cols={1} style={{ minHeight: 0, overflowY: "auto" }}>
        <InstagramRequirementsHelp>
          <Text size="text_xl" weight="semibold" marginBottom="1.5rem">
            Para conectar una cuenta de instagram tienes que cumplir con los
            siguientes requisitos:
          </Text>

          <Box flex alignItems="center" marginBottom="20px">
            <Check2Icon />
            <Text color="gray600" marginLeft="0.75rem">
              Tener una cuenta de instagram profesional asociada a una página de
              Facebook.
            </Text>
          </Box>

          <Box flex alignItems="center">
            <Check2Icon />
            <Text color="gray600" marginLeft="0.75rem">
              Permitir mensajes de terceros desde configuraciones de privacidad
              en instagram.
            </Text>
          </Box>

          <DocumentationLinkBox marginTop="2rem" flex alignItems="center">
            <Text size="text_md" weight="semibold" marginRight="0.5rem">
              Ver pasos
            </Text>
            <Icon icon="arrowRight" />
          </DocumentationLinkBox>
        </InstagramRequirementsHelp>
        {instagramAccounts !== undefined && (
          <Box marginTop="2rem">
            <Text size="text_xl" weight="semibold" marginBottom="0.25rem">
              Selecciona la cuenta de Instagram que quieras conectar a Zami.
            </Text>
            <Text size="text_md" color="gray600" marginBottom="1.5rem">
              Encontramos {instagramAccounts.length} cuentas de Instagram
              vinculadas con páginas de Facebook administradas por ti.
            </Text>
            {instagramAccounts.map((igAccount) => {
              return (
                <IGWrapper>
                  <Box flex alignItems="center">
                    <Root>
                      <IgPic src={igAccount.profile_pic} />
                      <IgFallback>
                        <Pic src={igPlaceholderImage} />
                      </IgFallback>
                    </Root>

                    <Text size="text_md" weight="semibold" cols={1}>
                      {igAccount.username}
                    </Text>
                    <Button
                      size="sm"
                      disabled={
                        instagramConnectionStatuses[igAccount.id] ===
                          "loading" ||
                        connectedInstagramAccounts[igAccount.igId]
                      }
                      isLoading={
                        instagramConnectionStatuses[igAccount.id] === "loading"
                      }
                      onClick={() => handleConnectIgAccount(igAccount)}
                    >
                      {connectedInstagramAccounts[igAccount.igId]
                        ? "Conectado"
                        : "Conectar"}
                    </Button>
                  </Box>
                </IGWrapper>
              );
            })}
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};

export default InstagramSettings;
