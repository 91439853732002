import React from "react";
import Box from "ui/Box";
import styled from "styled-components";
import { ReactComponent as WhatsappLogo } from "assets/channelLogos/whatsapp.svg";
import { OnboardingStyles } from "./common";
import Text from "ui/Text";
import Icon from "ui/Icon";
import { useConnectWhatsappWebAccount } from "components/settings/ConnectWhatsappWebAccount";
import QRCode from "react-qr-code";
import Button from "ui/Button";
import { useAuth } from "lib/auth";
import db from "lib/db";
import ProgressWindow from "./progressWindow";
import {
  BreadcrumbChevron,
  BreadcrumbCurrentPage,
  BreadcrumbLink,
  Breadcrumbs,
} from "ui/Breadcrumbs";
import { useHistory } from "react-router-dom";

const GrayBox = styled(Box)`
  background-color: ${(props) => props.theme.colors.gray50};
  border: solid 1px ${(props) => props.theme.colors.gray200};
  margin-right: 1.5rem;
  padding: 2rem 1.5rem;
  border-radius: 12px;

  &:last-child {
    margin-right: 0;
  }
`;

export default function OnboardingConnectWhatsapp() {
  const auth = useAuth();

  const history = useHistory();

  const { whatsappWebAccountDoc, handleRefreshQR, qrCode } =
    useConnectWhatsappWebAccount();

  const connectedWhatsappAccount =
    whatsappWebAccountDoc?.data()?.status === "active";

  React.useEffect(() => {
    if (connectedWhatsappAccount && auth.workspace?.onboarding !== undefined) {
      (async () => {
        console.log("Updating onboarding");
        await db.workspaces.doc(auth.workspaceId!).update({
          onboarding: {
            ...auth.workspace!.onboarding,
            connectedChannels: true,
          },
        });
      })();
    }
  }, [auth.workspace, auth.workspaceId, connectedWhatsappAccount]);

  return (
    <ProgressWindow currentStep={2}>
      <OnboardingStyles />
      <Box marginTop="4rem" padding="0rem 3rem">
        <Box
          flex
          alignItems="center"
          marginBottom="0.5rem"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/onboarding/connect-first-channel")}
        >
          <Icon icon="arrowLeft" />
          <Text size="text_md" marginLeft="0.5rem">
            Atras
          </Text>
        </Box>

        <Box flex paddingBottom="1.5rem" borderBottom marginBottom="4rem">
          <Box marginRight="1rem">
            <WhatsappLogo />
          </Box>
          <Box>
            <Text size="display_xs" weight="semibold">
              WhatsApp
            </Text>

            <Text size="text_md" weight="regular" color="gray600">
              Conecta tu número y podrás recibir y contestar los mensajes de
              WhatsApp desde Zami.
            </Text>
          </Box>
        </Box>

        {!connectedWhatsappAccount ? (
          <Box flex>
            <GrayBox cols={1}>
              <Text size="text_xl" weight="semibold" marginBottom="1.5rem">
                Pasos para conectar WhatsApp
              </Text>
              <Text size="text_md" color="gray600" marginBottom="1.25rem">
                1. Abre WhatsApp en tu teléfono.
              </Text>
              <Box marginBottom="1.25rem">
                <Text size="text_md" color="gray600" inline>
                  2. Toca Menú
                </Text>{" "}
                <Icon
                  icon="whatsappDots"
                  style={{ display: "inline", verticalAlign: "middle" }}
                />{" "}
                <Text
                  size="text_md"
                  color="gray600"
                  marginBottom="1.25rem"
                  inline
                >
                  o Configuración
                </Text>{" "}
                <Icon
                  icon="whatsappSettings"
                  style={{ display: "inline", verticalAlign: "middle" }}
                />{" "}
                <Text
                  size="text_md"
                  color="gray600"
                  marginBottom="1.25rem"
                  inline
                >
                  y selecciona Dispositivos vinculados.
                </Text>
              </Box>
              <Text size="text_md" color="gray600" marginBottom="1.25rem">
                3. Toca Vincular un dispositivo
              </Text>
              <Text size="text_md" color="gray600">
                4. Cuando se active la cámara, apunta tu teléfono hacia esta
                pantalla para escanear el código.
              </Text>
            </GrayBox>

            <GrayBox cols={1} flex alignItems="center" justifyContent="center">
              {(whatsappWebAccountDoc === undefined ||
                whatsappWebAccountDoc.data()?.status === "new") &&
                !qrCode && <Icon icon="oval" fill="gray900" />}
              {qrCode && <QRCode value={qrCode} />}
              {whatsappWebAccountDoc?.data()?.status === "timed_out" && (
                <Button size="sm" icon="refresh" onClick={handleRefreshQR}>
                  Actualizar código QR
                </Button>
              )}
            </GrayBox>
          </Box>
        ) : (
          <Box alignItems="center" flex direction="column">
            <Text size="text_xl" weight="semibold" marginBottom="0.75rem">
              ¡WhatsApp fue conectado correctamente!
            </Text>

            <Text size="text_md" weight="regular" marginBottom="1.5rem">
              A partir de este momento, recibirás los mensajes en Zami
            </Text>

            <Button
              size="sm"
              onClick={() => {
                history.push("/onboarding/connect-first-channel");
              }}
            >
              Volver a canales
            </Button>
          </Box>
        )}
      </Box>
    </ProgressWindow>
  );
}
