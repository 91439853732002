import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import Icon, { IconKey } from "ui/Icon";

const NavItemWrapper = styled.div<{ active: boolean }>`
  padding: 0.75rem;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.gray900};
  text-decoration: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.colors.gray50};
    `}
`;

const NavLink = styled(Link)`
  text-decoration: none;
`;

const NavItem = ({
  icon,
  active,
  className,
  text,
  iconStroke,
  href,
}: {
  icon?: IconKey;
  active?: boolean;
  text?: string;
  className?: string;
  iconStroke?: string;
  href: string;
}) => {
  return (
    <NavLink to={href}>
      <NavItemWrapper active={Boolean(active)} className={className}>
        {icon && <Icon icon={icon} stroke={active ? "gray700" : "gray500"} />}
        {text}
      </NavItemWrapper>
    </NavLink>
  );
};

export default NavItem;
