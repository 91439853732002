import { useAppData } from "components/AppData";
import { useAuth } from "lib/auth";
import db from "lib/db";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Box from "ui/Box";
import Button from "ui/Button";
import Icon from "ui/Icon";
import IconButton from "ui/IconButton";
import Modal from "ui/Modal";
import Text from "ui/Text";

const TemplateWrapper = styled.div`
  border-radius: 16px;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  overflow: hidden;
  min-height: 0;

  img {
    width: 100%;
    object-fit: contain;
  }

  ${(props) => props.theme.mediaQueries.mobile} {
    margin-bottom: 1rem;
  }
`;

const TempaltesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 1rem;
  min-height: 0;
  overflow: auto;

  ${(props) => props.theme.mediaQueries.mobile} {
    display: block;
  }
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary50};
  border: solid 1px ${(props) => props.theme.colors.primary200};
  padding: 0.75rem;
  margin-bottom: 1rem;
`;

const VerticalFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

interface BotTemplate {
  name: string;
  prompt: string;
}

const BOT_TEMPLATES: BotTemplate[] = [
  {
    name: "Jarivs",
    prompt: "Yo",
  },
  {
    name: "Jarivs",
    prompt: "Yo",
  },
  {
    name: "Jarivs",
    prompt: "Yo",
  },
  {
    name: "Jarivs",
    prompt: "Yo",
  },
  {
    name: "Jarivs",
    prompt: "Yo",
  },
];

export default function BotTemplatePicker(props: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  const auth = useAuth();
  const history = useHistory();

  const handleTemplateSelected = async (template: BotTemplate) => {
    const automatedMessagingSequence = await db.automatedMessagingSequences.add(
      {
        name: template.name,
        createdAt: Math.trunc(Date.now() / 1000),
        emoji: "",
        lastUpdateAt: Math.trunc(Date.now() / 1000),
        steps: [
          {
            type: "gpt",
            promptMessages: [
              {
                role: "system",
                content: template.prompt,
              },
            ],
          },
        ],
        workspaceId: auth.workspaceId!,
      }
    );

    history.push(`/ai/${automatedMessagingSequence.id}/prompt-composer`);
  };

  return (
    <Modal
      open={props.open}
      noClose
      onOpenChange={props.onOpenChange}
      contentStyle={{ padding: 0, maxWidth: 832 }}
    >
      <VerticalFlexWrapper>
        <Box
          flex
          justifyContent="space-between"
          borderBottom
          padding="0.5rem 1.5rem"
        >
          <Box>
            <Text size="text_lg" weight="semibold">
              Crea tu primer bot
            </Text>
            <Text size="text_sm" color="gray600">
              Selecciona una plantilla para empezar
            </Text>
          </Box>

          <IconButton icon="x" onClick={() => props.onOpenChange(false)} />
        </Box>

        <TempaltesGrid>
          {BOT_TEMPLATES.map((template) => (
            <TemplateWrapper>
              <img alt="yo" src="https://placehold.co/600x400" />

              <Box padding="1rem 1.5rem">
                <Text size="text_xl" weight="semibold" marginBottom="0.5rem">
                  Jarvis
                </Text>

                <Text size="text_md" color="gray600">
                  Explain briefly what Jarvis does in this space.
                </Text>
              </Box>

              <Box padding="0rem 1rem" paddingBottom="1rem">
                <Button
                  block
                  size="md"
                  onClick={() => handleTemplateSelected(template)}
                >
                  Seleccionar
                </Button>
              </Box>
            </TemplateWrapper>
          ))}

          <TemplateWrapper>
            <Box
              flex
              direction="column"
              style={{ height: "100%" }}
              padding="1rem"
            >
              <Box
                cols={1}
                flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                padding="1rem 0rem"
                style={{ textAlign: "center" }}
              >
                <IconWrapper>
                  <Icon icon="edit5" />
                </IconWrapper>

                <Text size="text_xl" weight="semibold">
                  Crear bot desde cero
                </Text>

                <Text size="text_md" color="gray600">
                  Hi there what is up wit hyouu ma niggak
                </Text>
              </Box>

              <Button size="md">Seleccionar</Button>
            </Box>
          </TemplateWrapper>
        </TempaltesGrid>
      </VerticalFlexWrapper>
    </Modal>
  );
}
