export const formatSeconds = (
  seconds: number,
  prefix = "",
  parts = 0
): string => {
  const secondsInOneMinute = 60;
  const secondsInOneHour = secondsInOneMinute * 60;
  const secondsInOneDay = secondsInOneHour * 24;

  if (parts >= 2) {
    return prefix;
  }

  if (seconds / secondsInOneDay >= 1) {
    const numberOfDays = Math.trunc(seconds / secondsInOneDay);
    return formatSeconds(
      seconds % secondsInOneDay,
      `${prefix ? `${prefix} ` : ``}${numberOfDays}d`,
      parts + 1
    );
  } else if (seconds / secondsInOneHour >= 1) {
    const numberOfHours = Math.trunc(seconds / secondsInOneHour);
    return formatSeconds(
      seconds % secondsInOneHour,
      `${prefix ? `${prefix} ` : ``}${numberOfHours}h`,
      parts + 1
    );
  } else if (seconds / secondsInOneMinute >= 1) {
    const numberOfMinutes = Math.trunc(seconds / secondsInOneMinute);
    return formatSeconds(
      seconds % secondsInOneMinute,
      `${prefix ? `${prefix} ` : ``}${numberOfMinutes}m`,
      parts + 1
    );
  } else if (seconds >= 1) {
    return `${prefix ? `${prefix} ` : ``}${Math.trunc(seconds)}s`;
  } else {
    return "—";
  }
};
