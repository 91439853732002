import React from "react";
import dayjs from "dayjs";
import {
  VerticalBarSeries,
  XAxis,
  YAxis,
  HorizontalGridLines,
  XYPlot,
  Hint,
} from "react-vis";
import styled, { useTheme, keyframes } from "styled-components";
import Box from "./Box";
import Text from "./Text";
import Icon from "./Icon";

const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Wrapper = styled(Box)`
  border: solid 1px ${(props) => props.theme.colors.gray200};
  border-radius: 12px;
  padding: 1.5rem;
`;

const PlotWrapper = styled.div`
  height: 272px;
  position: relative;
`;

const HintWrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 8px 12px;
  margin-bottom: 0.75rem;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  position: relative;
  animation-duration: 400ms;
  animation-name: ${slideDownAndFade};
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  display: flex;
  flex-direction: column;
  align-items: center;

  will-change: opacity, transform;
  white-space: nowrap;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: white;
    width: 12px;
    height: 12px;
    transform: translateY(6px) translateX(-50%) rotate(45deg);
  }
`;

const FeaturedIcon = styled.div`
  background-color: ${(props) => props.theme.colors.primary100};
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const EmptyStateWrapper = styled.div`
  height: 272px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BarChart = ({
  label,
  data,
  renderHint,
  value,
  yTickFormat,
  xTickFormat,
}: {
  label: string;
  value: string;
  yTickFormat?: (entry: any) => string;
  xTickFormat?: (entry: any) => string;
  data: ({ x: number; y: number } & Record<string, unknown>)[];
  renderHint?: (entry: any) => React.ReactNode;
}) => {
  const plotWrapperRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const [val, setHoveredValue] = React.useState<any>();
  const [plotDimensions, setPlotDimensions] = React.useState<{
    width: number;
    height: number;
  }>();

  React.useEffect(() => {
    if (plotWrapperRef.current) {
      setPlotDimensions({
        width: plotWrapperRef.current.clientWidth,
        height: plotWrapperRef.current.clientHeight,
      });
    }
  }, []);

  const localXTickFormat = (v: any) => {
    const day = dayjs.unix(v);
    return `${day.format("D MMM")}`;
  };

  const isEmptyData = data.filter((entry) => entry.y > 0).length === 0;

  return (
    <Wrapper>
      <Box marginBottom="1rem">
        <Text size="text_lg" weight="semibold">
          {label}
        </Text>
      </Box>

      {!isEmptyData ? (
        <>
          <Box flex alignItems="center" marginBottom="1rem">
            <Text size="display_md" weight="semibold" marginRight="1rem">
              {value}
            </Text>
            {/* <Badge size="lg" label="7.4%" type="pill" variant="success" /> */}
          </Box>

          <PlotWrapper ref={plotWrapperRef}>
            {plotDimensions && (
              <XYPlot
                width={plotDimensions.width}
                height={plotDimensions.height}
              >
                <HorizontalGridLines style={{ stroke: theme.colors.gray100 }} />
                <VerticalBarSeries
                  barWidth={0.5}
                  onValueMouseOver={(value) => setHoveredValue(value)}
                  onValueMouseOut={() => setHoveredValue(undefined)}
                  style={{
                    borderRadius: 8,
                    fill: theme.colors.primary500,
                    stroke: theme.colors.primary500,
                  }}
                  data={data}
                />
                {val && (
                  <Hint
                    value={val}
                    align={{ vertical: "top", horizontal: "right" }}
                    style={{ transform: "translateX(-50%)" }}
                  >
                    <HintWrapper>{renderHint?.(val) ?? null}</HintWrapper>
                  </Hint>
                )}
                <YAxis
                  style={{ fill: theme.colors.gray600, fontSize: 12 }}
                  tickFormat={yTickFormat}
                />
                <XAxis
                  style={{ fill: theme.colors.gray600, fontSize: 12 }}
                  tickTotal={Math.min(14, data.length)}
                  tickFormat={xTickFormat ?? localXTickFormat}
                />
              </XYPlot>
            )}
          </PlotWrapper>
        </>
      ) : (
        <EmptyStateWrapper>
          <FeaturedIcon>
            <Icon icon="barChart" />
          </FeaturedIcon>

          <Text size="text_md" weight="semibold" marginBottom="0.25rem">
            No hay datos para mostrar
          </Text>

          <Text size="text_sm" color="gray600">
            Intenta cambiar los filtros en la parte superior de la página.
          </Text>
        </EmptyStateWrapper>
      )}
    </Wrapper>
  );
};

export default BarChart;
