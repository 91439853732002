import React from "react";
import { v4 } from "uuid";
import firebase from "firebase";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import Box from "ui/Box";
import Icon from "ui/Icon";
import Text from "ui/Text";
import { useAuth } from "lib/auth";
import prettyBytes from "pretty-bytes";

const ClearIconWrapper = styled.div`
  position: absolute;
  padding: 2px;
  right: -8px;
  top: -8px;
  background-color: ${(props) => props.theme.colors.gray100};
  border-radius: 50%;
  border: solid 2px white;
  opacity: 0;
  cursor: pointer;
`;

const AttachedFileWrapper = styled.div`
  border: solid 1px ${(props) => props.theme.colors.gray200};
  border-radius: 12px;
  align-self: flex-start;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  position: absolute;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray100};
    ${ClearIconWrapper} {
      opacity: 1;
    }
  }
`;

const FileIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.primary100};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
`;

interface AttachedFileProps {
  file: File;
  onUploadSuccess?: (fileLocation: string) => void;
  isLoading?: boolean;
  onClear?: () => void;
}

export function ZamiFile(props: {
  name: string;
  size: number;
  style?: React.HTMLAttributes<HTMLDivElement>["style"];
  onClear?: () => void;
}) {
  return (
    <AttachedFileWrapper style={props.style}>
      <ClearIconWrapper onClick={props.onClear}>
        <Icon icon="x" size={14} />
      </ClearIconWrapper>
      <FileIconWrapper>
        <Icon icon="file4" />
      </FileIconWrapper>

      <Box>
        <Text size="text_sm" weight="semibold">
          {props.name}
        </Text>
        <Text size="text_sm" weight="regular" color="gray600">
          {prettyBytes(props.size)}
        </Text>
      </Box>
    </AttachedFileWrapper>
  );
}

const AttachedFile = ({
  file,
  isLoading,
  onUploadSuccess,
  onClear,
}: AttachedFileProps) => {
  const auth = useAuth();

  React.useEffect(() => {
    (async () => {
      const location = `workspace-media/${auth.workspaceId}/${v4()}/${
        file.name
      }`;
      await firebase.storage().ref().child(location).put(file);
      onUploadSuccess?.(location);
    })();
  }, [auth.workspaceId, file, onUploadSuccess]);

  if (isLoading) {
    return (
      <AttachedFileWrapper>
        <ClearIconWrapper onClick={onClear}>
          <Icon icon="x" />
        </ClearIconWrapper>
        <ContentLoader height={40} viewBox="0 0 40 40">
          <rect x="0" y="0" rx="8" width="40" height="40" />
        </ContentLoader>
        <Box marginLeft="0.75rem">
          <Box flex marginBottom="0.25rem">
            <ContentLoader height={17} viewBox="0 0 183 18">
              <rect x="0" y="0" rx="4" width={183} height={18} />
            </ContentLoader>
          </Box>

          <Box flex marginBottom="0px">
            <ContentLoader height={17} viewBox="0 0 183 18">
              <rect x="0" y="0" rx="4" width={183} height={18} />
            </ContentLoader>
          </Box>
        </Box>
      </AttachedFileWrapper>
    );
  }

  return <ZamiFile onClear={onClear} name={file.name} size={file.size} />;
};

export default AttachedFile;
