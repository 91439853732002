import React from "react";
import styled from "styled-components";
import Header from "ui/Header";
import Button from "ui/Button";
import { Table, TBody, Td, Th, THead, Tr } from "ui/Table";
import Box from "ui/Box";
import IconButton from "ui/IconButton";
import { useAppData } from "components/AppData";
import { useHistory } from "react-router-dom";
import Dropdown from "ui/Dropdown";
import Icon from "ui/Icon";

const Wrapper = styled.div`
  flex: 1;
  padding: 0rem 2rem;
`;

const DropdownTrigger = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 40px;
  width: 40px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }
`;

const RolesSettings = () => {
  const appData = useAppData();
  const history = useHistory();

  const roles = Object.values(appData.roles);

  return (
    <Wrapper>
      <Header
        title="Roles"
        subtitle="Crea y administra roles. Los roles contienen una colección de permisos y pueden ser asignados a los usuarios."
        badge={null}
        actions={
          <Button
            size="sm"
            icon="plus"
            onClick={() => history.push("/settings/roles/form")}
          >
            Nuevo rol
          </Button>
        }
      />

      <Table>
        <THead>
          <Tr>
            <Th>Rol</Th>
            <Th></Th>
          </Tr>
        </THead>

        <TBody>
          {roles.map((role) => (
            <Tr key={role.id}>
              <Td>{role.data().name}</Td>
              <Td>
                <Box flex justifyContent="flex-end">
                  <Dropdown
                    trigger={
                      <DropdownTrigger>
                        <IconButton size="sm" icon="dotsVertical" />
                      </DropdownTrigger>
                    }
                  >
                    <Dropdown.Item
                      onClick={() =>
                        history.push(`/settings/roles/${role.id}/form`)
                      }
                    >
                      <div className="LeftSlot">
                        <Icon icon="edit" stroke="gray700" />
                      </div>
                      Editar rol
                    </Dropdown.Item>
                  </Dropdown>
                </Box>
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    </Wrapper>
  );
};

export default RolesSettings;
