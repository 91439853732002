import React from "react";
import firebase from "firebase";
import DatePicker from "react-datepicker";
import "assets/datepicker.css";
import * as chrono from "chrono-node";
import styled from "styled-components";
import {
  CommandBarDialogContent,
  CommandBarHeader,
  BorderlessInput,
  MenuItem,
} from "./common";
import Icon from "ui/Icon";
import Text from "ui/Text";
import dayjs, { Dayjs } from "dayjs";
import { capitalize } from "lib/utils";
import Box from "ui/Box";
import { Conversation } from "common/src/types";
import db from "lib/db";
import { useAppData } from "../AppData";
import { updateConversation } from "lib/conversations";
import { useIDB } from "lib/idb";

const Wrapper = styled.div`
  min-height: 320px;
  display: flex;
  flex-direction: column;
`;

const ScrollWindow = styled.div`
  max-height: 480px;
`;

const DatePickerWrapper = styled(Box)`
  border-right: solid 1px ${(props) => props.theme.colors.gray200};
`;

export const customOptionRegex = /(\d+)/i;

const anchorDate = dayjs().set("hour", 9).set("minute", 0).set("second", 0);

type Option = { label: string; value: Dayjs };

const TimeMenuItem = styled(MenuItem)`
  width: 100%;
  color: ${(props) => props.theme.colors.gray700};
`;

function pluralize(text: string, number: number) {
  return number === 1 ? text : `${text}s`;
}

export function generateCustomOptionsWithNumber(number: number) {
  return [
    {
      label: pluralize(`En ${number} minuto`, number),
      value: dayjs().add(number, "minutes"),
    },
    {
      label: pluralize(`En ${number} hora`, number),
      value: dayjs().add(number, "hours"),
    },
    {
      label: pluralize(`En ${number} día`, number),
      value: dayjs().add(number, "days"),
    },
    {
      label: pluralize(`En ${number} semana`, number),
      value: dayjs().add(number, "weeks"),
    },
  ];
}

export const SnoozeDateOption = ({
  date,
  label,
  onClick,
}: {
  label: string;
  date: Dayjs;
  onClick: () => void;
}) => {
  let formattedDate = capitalize(date.format("dddd, D [de] MMMM[,] h:mm A"));

  if (date.isSame(dayjs(), "day")) {
    formattedDate = date.format("h:mm A");
  } else if (date.isSame(dayjs(), "week")) {
    formattedDate = date.format("dddd, h:mm A");
  }

  return (
    <MenuItem flex justifyContent="space-between" onClick={onClick}>
      <Text size="text_sm" weight="medium">
        {label}
      </Text>
      <Text size="text_sm" color="gray600">
        {formattedDate}
      </Text>
    </MenuItem>
  );
};

export default function SnoozeConversationCommandWindow(props: {
  conversation: { id: string; data: Conversation };
  onSnooze: () => void;
}) {
  const appData = useAppData();
  const idb = useIDB();
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [mode, setMode] = React.useState<"options" | "datepicker">("options");
  const [customDate, setCustomDate] = React.useState<Dayjs>(
    dayjs().startOf("day")
  );

  const defaultOptions = React.useMemo(
    () => [
      {
        label: "En 2 horas",
        value: dayjs().add(2, "h"),
      },
      {
        label: "Mañana",
        value: anchorDate.add(1, "d"),
      },
      {
        label: "Lunes",
        value: anchorDate.add(1, "week").set("day", 1),
      },
      {
        label: "En una semana",
        value: anchorDate.add(1, "week"),
      },
      {
        label: "En un mes",
        value: anchorDate.add(1, "month"),
      },
    ],
    []
  );

  const { conversation, onSnooze } = props;

  const handleSnoozeUntil = React.useCallback(
    async (date: Dayjs) => {
      const snoozeUntil = date.unix();
      await updateConversation(idb, conversation.id, {
        snoozeUntil,
      });

      db.messages.add({
        channel: "internal",
        contactId: conversation.data.contactId,
        action: "snoozed_conversation",
        timestamp: Math.trunc(Date.now() / 1000),
        workspaceAuthorId: appData.workspaceMemberSelf.id,
        snoozeUntil,
      });

      onSnooze();
    },
    [conversation, onSnooze, appData.workspaceMemberSelf, idb]
  );

  const parsedDate = React.useMemo(() => {
    if (searchQuery) {
      const parsed = chrono.parseDate("mañana");
      if (parsed) {
        return dayjs(parsed);
      }
    }
  }, [searchQuery]);

  const filteredOptions = React.useMemo(() => {
    const filterFn = (option: Option): boolean => {
      return option.label.toLowerCase().includes(searchQuery.toLowerCase());
    };
    const filteredDefaultOptions = defaultOptions.filter(filterFn);

    if (filteredDefaultOptions.length > 0) {
      return filteredDefaultOptions;
    }

    const customMatch = searchQuery.match(customOptionRegex);

    let customOptions: Option[] = [];

    if (customMatch) {
      const theNumber = parseInt(customMatch[1], 10);
      customOptions = generateCustomOptionsWithNumber(theNumber);
    }

    return customOptions.filter(filterFn);
  }, [searchQuery, defaultOptions]);

  const customHourOptions = React.useMemo(() => {
    const startDate = customDate.startOf("day");

    return Array(48)
      .fill(true)
      .map((_, i) => startDate.add(30 * i, "minutes"));
  }, [customDate]);

  return (
    <CommandBarDialogContent
      style={{ width: mode === "datepicker" ? "initial" : undefined }}
    >
      <Wrapper>
        {mode === "options" ? (
          <CommandBarHeader>
            <Icon icon="search" />
            <BorderlessInput
              autoFocus
              placeholder="¿Cuándo quieres reabrir la conversación?"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ flex: 1 }}
            />
          </CommandBarHeader>
        ) : (
          <CommandBarHeader
            onClick={() => setMode("options")}
            style={{ cursor: "pointer" }}
          >
            <Icon icon="arrowLeft" />
            <Text size="text_md" color="gray700" marginLeft="0.5rem">
              Atrás
            </Text>
          </CommandBarHeader>
        )}

        <ScrollWindow>
          {mode === "options" ? (
            <>
              {filteredOptions.map((option, i) => (
                <SnoozeDateOption
                  label={option.label}
                  key={i}
                  date={option.value}
                  onClick={() => handleSnoozeUntil(option.value)}
                />
              ))}
              <MenuItem onClick={() => setMode("datepicker")}>
                <Text size="text_sm" weight="medium">
                  Personalizar
                </Text>
              </MenuItem>
            </>
          ) : (
            <Box flex justifyContent="center" style={{ height: 400 }}>
              <DatePickerWrapper
                paddingTop="2rem"
                paddingBottom="2rem"
                paddingRight="3rem"
                paddingLeft="3rem"
                flex
                justifyContent="center"
              >
                <DatePicker
                  inline
                  selected={customDate.toDate()}
                  onChange={(date: Date) => setCustomDate(dayjs(date))}
                />
              </DatePickerWrapper>

              <Box
                style={{ width: 200, overflowY: "auto", overflowX: "hidden" }}
                padding="1rem"
              >
                {customHourOptions.map((date, i) => (
                  <TimeMenuItem onClick={() => handleSnoozeUntil(date)}>
                    {date.format("h:mm a")}
                  </TimeMenuItem>
                ))}
              </Box>
            </Box>
          )}
        </ScrollWindow>
      </Wrapper>
    </CommandBarDialogContent>
  );
}
