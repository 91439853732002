import React from "react";
import styled from "styled-components";
import { Link as RRLink } from "react-router-dom";
import Text from "./Text";

const A = styled(RRLink)`
  cursor: pointer;
  color: initial;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const AA = styled.a`
  cursor: pointer;
  color: initial;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export default function Link(props: {
  href: string;
  children: React.ReactNode;
}) {
  if (props.href.includes("http")) {
    return (
      <AA href={props.href}>
        <Text size="text_sm" weight="semibold" color="primary700" inline>
          {props.children}
        </Text>
      </AA>
    );
  }
  return (
    <A to={props.href}>
      <Text size="text_sm" weight="semibold" color="primary700" inline>
        {props.children}
      </Text>
    </A>
  );
}
