import {
  AccountAssignee,
  FacebookPage,
  InstagramAccount,
  WhatsappWebAccount,
} from "common/src/types";
import db from "lib/db";
import React from "react";
import { AssigneePicker } from "./assignConversation";

export type ChannelAccount =
  | {
      type: "whatsappWeb";
      whatsappWebAccount: { id: string; data: WhatsappWebAccount };
      multiple?: boolean;
    }
  | {
      type: "instagram";
      instagramAccount: { id: string; data: InstagramAccount };
      multiple?: boolean;
    }
  | {
      type: "page";
      facebookPage: { id: string; data: FacebookPage };
      multiple?: boolean;
    };

const AssignChannelAccount = ({
  channelAccount,
  multiple,
  onAccountAssigned,
}: {
  channelAccount: ChannelAccount;
  multiple?: boolean;
  onAccountAssigned: () => void;
}) => {
  const assignAccount = React.useCallback(
    async (assignee: string[]) => {
      let finalAssignee: AccountAssignee | undefined;

      if (multiple) {
        assignee.forEach((assigneeId, index) => {
          if (
            typeof finalAssignee === "string" ||
            finalAssignee?.type === "simple"
          )
            return;

          if (index === 0) {
            finalAssignee = {
              type: "round_robin",
              rule: "always",
              assignees: [assigneeId],
            };
          }

          if (finalAssignee!.type === "round_robin" && index !== 0) {
            finalAssignee!.assignees.push(assigneeId);
          }
        });
      }

      if (!multiple && assignee[0] !== "") {
        finalAssignee = {
          type: "simple",
          rule: "always",
          assignee: assignee[0],
        };
      }

      if (!multiple && assignee[0] === "") {
        finalAssignee = "";
      }

      if (channelAccount.type === "whatsappWeb") {
        await db.whatsappWebAccounts
          .doc(channelAccount.whatsappWebAccount.id)
          .update({
            assignee: finalAssignee,
          });
      } else if (channelAccount.type === "instagram") {
        await db.instagramAccounts
          .doc(channelAccount.instagramAccount.id)
          .update({
            assignee: finalAssignee,
          });
      } else if (channelAccount.type === "page") {
        await db.facebookPages.doc(channelAccount.facebookPage.id).update({
          assignee: finalAssignee,
        });
      }

      onAccountAssigned();
    },
    [channelAccount, onAccountAssigned]
  );

  const assignee = React.useMemo(() => {
    if (channelAccount.type === "whatsappWeb") {
      return channelAccount.whatsappWebAccount.data.assignee;
    } else if (channelAccount.type === "instagram") {
      return channelAccount.instagramAccount.data.assignee;
    } else if (channelAccount.type === "page") {
      return channelAccount.facebookPage.data.assignee;
    }

    return "";
  }, [channelAccount]);

  return (
    <AssigneePicker
      assignee={assignee}
      multiple={multiple}
      onAssigneePicked={assignAccount}
    />
  );
};

export default AssignChannelAccount;
