import React from "react";
import { storage } from "lib/db";

interface FBImageProps {
  location: string;
}

const downloadUrls: Record<string, string> = {};

const FBImage = ({
  location,
  ...restProps
}: FBImageProps & React.ImgHTMLAttributes<HTMLImageElement>) => {
  const [imageUrl, setImageUrl] = React.useState<string>();

  React.useEffect(() => {
    (async () => {
      if (!downloadUrls[location]) {
        const downloadUrl = await storage.ref(location).getDownloadURL();
        downloadUrls[location] = downloadUrl;
      }

      if (imageUrl !== downloadUrls[location]) {
        setImageUrl(downloadUrls[location]);
      }
    })();
  }, [location, imageUrl]);

  return <img alt="" src={imageUrl} {...restProps} />;
};

export const FBVideo = ({
  location,
  contentType,
  width,
  height,
  controls,
  onClick,
  style,
  ...restProps
}: { contentType: string; width?: number; height?: number } & FBImageProps &
  React.VideoHTMLAttributes<HTMLVideoElement>) => {
  const [videoUrl, setVideoUrl] = React.useState<string>();

  React.useEffect(() => {
    (async () => {
      if (!downloadUrls[location]) {
        const downloadUrl = await storage.ref(location).getDownloadURL();
        downloadUrls[location] = downloadUrl;
      }

      if (videoUrl !== downloadUrls[location]) {
        setVideoUrl(downloadUrls[location]);
      }
    })();
  }, [location, videoUrl]);

  if (!videoUrl) {
    return null;
  }

  return (
    <video
      width={width?.toString() ?? "112"}
      height={height?.toString() ?? "199"}
      controls={controls ?? true}
      style={style}
      onClick={onClick}
      {...restProps}
    >
      <source src={videoUrl} type={contentType} />
    </video>
  );
};

export default FBImage;
