import React from "react";
import Box from "ui/Box";
import Button from "ui/Button";
import Text from "ui/Text";

import ZamiOpenAI from "assets/zami-openai.png";
import styled from "styled-components";
import BotTemplatePicker from "./botTemplatePicker";
import { useAppData } from "components/AppData";
import { TBody, THead, Table, TableWrapper, Td, Th, Tr } from "ui/Table";
import Badge from "ui/Badge";
import { TimeAgoString, timeAgoLongSuffixOptions } from "lib/time";
import { useHistory } from "react-router-dom";

const EmptyStateWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 0rem;

  img {
    height: 56px;
  }
`;

const NameColumn = styled(Td)`
  cursor: pointer;
`;

export default function AutomatedSequencesList() {
  const appData = useAppData();
  const history = useHistory();
  const [templatePickerOpen, setTemplatePickerOpen] = React.useState(false);

  return (
    <>
      <Box padding="0rem 2rem" cols={1}>
        <Box
          flex
          alignItems="center"
          justifyContent="space-between"
          borderBottom
          padding="1.5rem 0rem"
        >
          <Text size="display_xs" weight="semibold">
            Mis bots
          </Text>

          <Button size="sm" onClick={() => setTemplatePickerOpen(true)}>
            Crear bot
          </Button>
        </Box>

        {Object.keys(appData.automatedMessagingSequences).length === 0 && (
          <EmptyStateWrapper>
            <img alt="Zami + OpenAI" src={ZamiOpenAI} />

            <Text
              size="display_sm"
              weight="semibold"
              style={{ maxWidth: 600, textAlign: "center" }}
              marginTop="1.5rem"
              marginBottom="1.5rem"
            >
              Zami es la forma más fácil de crear bots con inteligencia
              artificial
            </Text>

            <Button
              size="md"
              kind="primary"
              onClick={() => setTemplatePickerOpen(true)}
            >
              Empezar
            </Button>
          </EmptyStateWrapper>
        )}

        <Box marginTop="2rem">
          <TableWrapper>
            <Table>
              <THead>
                <Tr>
                  <Th colSpan={4}>Bot</Th>
                  <Th>Estado</Th>
                  <Th>Última Edición</Th>
                </Tr>
              </THead>
              <TBody>
                {Object.values(appData.automatedMessagingSequences).map(
                  (sequence) => {
                    const sequenceData = sequence.data();

                    if (sequenceData.steps[0].type !== "gpt") {
                      return null;
                    }

                    return (
                      <Tr key={sequence.id} style={{ cursor: "pointer" }}>
                        <NameColumn
                          colSpan={4}
                          onClick={() => {
                            console.log(
                              `redirect to ${`/ai/${sequence.id}/prompt-composer`}`
                            );
                            history.push(`/ai/${sequence.id}/prompt-composer`);
                          }}
                        >
                          {sequenceData.name}
                        </NameColumn>
                        <Td>
                          <Box flex>
                            <Badge
                              backgroundColor="gray100"
                              label="Borrador"
                              size="sm"
                            />
                          </Box>
                        </Td>
                        <Td>
                          <TimeAgoString
                            timestamp={sequenceData.lastUpdateAt}
                            options={timeAgoLongSuffixOptions}
                          />
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </TBody>
            </Table>
          </TableWrapper>
        </Box>
      </Box>
      <BotTemplatePicker
        open={templatePickerOpen}
        onOpenChange={() => setTemplatePickerOpen(false)}
      />
    </>
  );
}
