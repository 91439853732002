import React from "react";
import firebase from "firebase";
import * as Sentry from "@sentry/react";
import db from "./db";
import { Workspace } from "common/src/types";
import posthog from "posthog-js";
import { clientId } from "./presence";

const AuthContext = React.createContext<{
  user?: firebase.User | null;
  workspaceId?: string;
  workspace?: Workspace;
  setWorkspace?: React.Dispatch<React.SetStateAction<Workspace>>;
  logOut?: () => Promise<void>;
}>({});

export const AuthenticationProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [user, setUser] = React.useState<firebase.User | null>();
  const [workspaceId, setWorkspaceId] = React.useState<string>();
  const [workspace, setWorkspace] = React.useState<Workspace>();

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });

    return unsubscribe;
  }, []);

  React.useEffect(() => {
    if (user?.uid) {
      (async () => {
        const members = await db.workspaceMembers
          .where("userId", "==", user.uid)
          .get();
        const workspaceMemberDoc = members.docs[0];
        const workspaceId = workspaceMemberDoc?.data().workspaceId;
        const workspaceDoc = await db.workspaces.doc(workspaceId).get();

        if (workspaceMemberDoc) {
          posthog.identify(workspaceMemberDoc.id, {
            name: workspaceMemberDoc.data().name,
            email: user.email,
            workspaceId: workspaceDoc.id,
            workspace: workspaceDoc.data()?.name,
          });
        }

        if (workspaceDoc.exists) {
          setWorkspaceId(workspaceId);
          setWorkspace(workspaceDoc.data());
        }
      })();
    }
  }, [user]);

  React.useEffect(() => {
    if (workspaceId) {
      return db.workspaces.doc(workspaceId).onSnapshot((snap) => {
        console.log("setting");
        setWorkspace(snap.data());
      });
    }
  }, [workspaceId, setWorkspace]);

  const logOut = React.useCallback(async () => {
    if (user?.uid) {
      try {
        const results = await db.workspaceMembers
          .where("userId", "==", user.uid)
          .get();

        if (!results.docs.length) return;

        const currentWorkspaceMember = results.docs[0];

        await firebase
          .database()
          .ref(`/status/${currentWorkspaceMember.id}/${clientId}`)
          .update({
            state: "offline",
            last_changed: firebase.database.ServerValue.TIMESTAMP,
          });
      } catch (error) {
        Sentry.captureException(error);
      }
    }
    await firebase.auth().signOut();
  }, [user]);

  if (user === undefined) {
    // auth is still loading
    return null;
  }

  return (
    <AuthContext.Provider
      value={{ logOut, user, workspace, workspaceId, setWorkspace }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const useSelf = () => React.useContext(AuthContext).user;

const defaultObject = {
  AuthenticationProvider,
};

export default defaultObject;
