import React from "react";
import styled from "styled-components";
import Text from "ui/Text";

import Button from "ui/Button";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  text-align: center;
`;

const Actions = styled.div`
  margin-top: 32px;
`;

type EmptyContactsViewProps = {
  setContactModalOpen(v: boolean): void;
};

const EmptyContactsView = ({ setContactModalOpen }: EmptyContactsViewProps) => {
  return (
    <Wrapper>
      <Text size="text_xl" weight="semibold">
        Agrega tu primer contacto
      </Text>
      <Text
        color="gray600"
        size="text_md"
        style={{ paddingTop: "0.5rem", maxWidth: "352px" }}
      >
        También agregaremos automáticamente a las personas que intenten
        comunicarse contigo.
      </Text>
      <Actions>
        <Button
          size="md"
          kind="primary"
          icon="plus"
          onClick={() => setContactModalOpen(true)}
        >
          Agregar contacto
        </Button>
      </Actions>
    </Wrapper>
  );
};

export default EmptyContactsView;
