import React from "react";
import firebase from "firebase";
import { Conversation, FirestoreTimestamp } from "common/src/types";
import db from "./db";
import { IDBConversation, ZamiDexie, useIDB } from "./idb";
import Dexie, { IndexableType } from "dexie";
import { useAuth } from "./auth";
import notificationSound from "assets/sfx/notification.aac";
import { ConversationOrder, ConversationStatus } from "components/chat/context";
import { useAppData } from "components/AppData";
import { useLiveQuery } from "dexie-react-hooks";
import useSound from "use-sound";
import { getPendingResponseIDBCollection } from "./chat";

export const assignConversationToMember = async ({
  conversationId,
  authorId,
  assigneeId,
  contactId,
  idb,
}: {
  conversationId: string;
  authorId: string;
  assigneeId: string;
  contactId: string;
  idb: ZamiDexie;
}) => {
  if (assigneeId === "") {
    await updateConversation(idb, conversationId, {
      assignee: ``,
    });
  } else {
    await updateConversation(idb, conversationId, {
      assignee: assigneeId,
    });
  }

  if (assigneeId === "") {
    db.messages.add({
      channel: "internal",
      contactId,
      action: "unassigned_conversation",
      timestamp: Math.trunc(Date.now() / 1000),
      workspaceAuthorId: authorId,
      workspaceMemberAssigneeId: assigneeId,
    });

    return;
  }

  db.messages.add({
    channel: "internal",
    contactId,
    action: "assigned_conversation",
    timestamp: Math.trunc(Date.now() / 1000),
    workspaceAuthorId: authorId,
    workspaceMemberAssigneeId: assigneeId,
  });
};

function mapConversationDocToIDBConversation(
  doc: firebase.firestore.QueryDocumentSnapshot<Conversation>
) {
  let status: ConversationStatus = "open";

  if (doc.data().closed) {
    status = "closed";
  } else if (doc.data().snoozeUntil) {
    status = "snoozed";
  }

  return {
    id: doc.id,
    lastUpdateAt: doc.data().lastUpdateAt.seconds,
    assignee: doc.data().assignee,
    lastMessageAt: doc.data().lastMessageAt,
    lastMessageDirection: doc.data().lastMessage.direction!,
    status,
    data: doc.data(),
  };
}

export function useIDBConversationLoader() {
  const auth = useAuth();
  const idb = useIDB();
  const appData = useAppData();
  const [lastSyncedConversationDoc, setLastSyncedConversationDoc] =
    React.useState<firebase.firestore.DocumentSnapshot<Conversation>>();
  const [initialSyncCompleted, setInitialSyncCompleted] = React.useState(false);
  const [playNotificationSound] = useSound(notificationSound);

  const initialFirestoreToIDBSync = React.useCallback(
    async (
      lastUpdatedConversation: undefined | IDBConversation
    ): Promise<IDBConversation> => {
      let conversationsQuery = db.conversations
        .where("workspaceId", "==", auth.workspaceId!)
        .orderBy("lastUpdateAt", "asc");

      if (lastUpdatedConversation) {
        const conversationDoc = await db.conversations
          .doc(lastUpdatedConversation.id)
          .get();
        if (conversationDoc.exists) {
          conversationsQuery = conversationsQuery.startAfter(conversationDoc);
        }
      }

      const conversations = await conversationsQuery.limit(2000).get();

      const mappedConversations: IDBConversation[] = [];

      for (const conv of conversations.docs) {
        if (conv.data().deleted) {
          await idb.conversations.delete(conv.id).catch((err) => false);
          continue;
        }

        const mappedConversation = mapConversationDocToIDBConversation(conv);

        if (mappedConversation) {
          mappedConversations.push(mappedConversation);
        }
      }

      await idb.conversations.bulkPut(mappedConversations);

      if (conversations.docs.length === 100) {
        return initialFirestoreToIDBSync(
          mappedConversations[mappedConversations.length - 1]
        );
      }

      return mappedConversations[mappedConversations.length - 1];
    },
    [auth.workspaceId, idb]
  );

  React.useEffect(() => {
    (async () => {
      const lastUpdatedConversation = (
        await idb.conversations.orderBy("lastUpdateAt").limit(1).toArray()
      )[0];
      const lastSyncedConversation = await initialFirestoreToIDBSync(
        lastUpdatedConversation
      );
      const lastSyncedConversationDoc = await db.conversations
        .doc(lastSyncedConversation.id)
        .get();

      setLastSyncedConversationDoc(lastSyncedConversationDoc);
      setInitialSyncCompleted(true);
    })();
  }, [initialFirestoreToIDBSync, idb]);

  React.useEffect(() => {
    if (!initialSyncCompleted) {
      return;
    }

    let conversationsQuery = db.conversations
      .where("workspaceId", "==", auth.workspaceId!)
      .orderBy("lastUpdateAt", "asc");

    if (lastSyncedConversationDoc) {
      conversationsQuery = conversationsQuery.startAfter(
        lastSyncedConversationDoc
      );
    }

    return conversationsQuery.onSnapshot(async (snapshot) => {
      // check if there's a new conversation
      // that should trigger a notification sound
      for (const doc of snapshot.docs) {
        const conversationData = doc.data();

        if (
          conversationData.lastMessage.channel === "internal" ||
          conversationData.lastMessage.direction === "outgoing"
        ) {
          continue;
        }

        const assigneesForNotifications = [
          `wm:${appData.workspaceMemberSelf?.id}`,
        ];

        if (appData.permissions.canSeeUnassignedConversations) {
          assigneesForNotifications.push("");
        }

        if (!assigneesForNotifications.includes(conversationData.assignee)) {
          continue;
        }

        const existingConversation = await idb.conversations.get({
          id: doc.id,
        });

        if (!existingConversation) {
          // should play notification because it's a new conversation
          console.log("playing sound");
          playNotificationSound();
          break;
        }

        if (
          existingConversation.data.lastMessageId !==
          conversationData.lastMessageId
        ) {
          // should play notification because there's a new message in existing conversation
          console.log("playing sound");
          playNotificationSound();
          break;
        }
      }

      const mappedConversations: IDBConversation[] = [];

      for (const conv of snapshot.docs) {
        if (conv.data().deleted) {
          await idb.conversations.delete(conv.id).catch((err) => false);
          continue;
        }

        const mappedConversation = mapConversationDocToIDBConversation(conv);

        if (mappedConversation) {
          mappedConversations.push(mappedConversation);
        }
      }

      if (mappedConversations.length > 0) {
        await idb.conversations.bulkPut(mappedConversations);
        setLastSyncedConversationDoc(snapshot.docs[snapshot.docs.length - 1]);
      }
    });
  }, [
    initialSyncCompleted,
    lastSyncedConversationDoc,
    auth.workspaceId,
    idb,
    playNotificationSound,
    appData.permissions,
    appData.workspaceMemberSelf,
  ]);

  return {
    initialSyncCompleted,
    lastSyncedConversationDoc,
  };
}

export async function updateConversation(
  idb: ZamiDexie,
  conversationId: string,
  data: Partial<Conversation>
) {
  const existingConversation = await idb.conversations.get({
    id: conversationId,
  });

  if (!existingConversation) {
    throw new Error("Conversation does not exist");
  }

  const oldConversationData = existingConversation.data;
  const newConversationData = {
    ...oldConversationData,
    ...data,
  };

  let status: ConversationStatus = "open";

  if (newConversationData.closed) {
    status = "closed";
  } else if (newConversationData.snoozeUntil) {
    status = "snoozed";
  }

  await Promise.all([
    idb.conversations.put({
      id: conversationId,
      lastUpdateAt: 0,
      assignee: newConversationData.assignee,
      lastMessageAt: newConversationData.lastMessageAt,
      lastMessageDirection: newConversationData.lastMessage.direction!,
      status,
      data: newConversationData,
    }),
    db.conversations.doc(conversationId).update({
      ...data,
      lastUpdateAt: firebase.firestore.FieldValue.serverTimestamp(),
    }),
  ]);
}

export async function createConversation(
  idb: ZamiDexie,
  conversationId: string,
  data: Omit<Conversation, "lastUpdateAt">
) {
  let status: ConversationStatus = "open";
  if (data.closed) {
    status = "closed";
  } else if (data.snoozeUntil) {
    status = "snoozed";
  }

  await Promise.all([
    idb.conversations.put({
      id: conversationId,
      lastUpdateAt: 0,
      assignee: data.assignee,
      lastMessageAt: data.lastMessageAt,
      lastMessageDirection: data.lastMessage.direction!,
      status,
      data: {
        ...data,
        lastUpdateAt: {
          seconds: 0,
          nanoseconds: 0,
        },
      },
    }),
    db.conversations.doc(conversationId).set({
      ...data,
      lastUpdateAt:
        firebase.firestore.FieldValue.serverTimestamp() as unknown as FirestoreTimestamp,
    }),
  ]);
}

export function IDBConversationLoader(props: { children: React.ReactNode }) {
  useIDBConversationLoader();

  return <>{props.children}</>;
}

export function useConversationList(
  inboxName: string,
  opts: {
    order: ConversationOrder;
    status: ConversationStatus;
  }
) {
  const appData = useAppData();
  const idb = useIDB();

  const conversations = useLiveQuery(async () => {
    let conversationCollection:
      | undefined
      | Dexie.Collection<IDBConversation, IndexableType> = undefined;

    if (inboxName === "inbox") {
      const possibleAssignees = [`wm:${appData.workspaceMemberSelf?.id}`];

      if (appData.permissions.canSeeUnassignedConversations) {
        possibleAssignees.push("");
      }
      conversationCollection = idb.conversations
        .where("[assignee+status]")
        .anyOf(possibleAssignees.map((assignee) => [assignee, opts.status]));
    } else if (inboxName === "unassigned") {
      conversationCollection = idb.conversations
        .where("[assignee+status]")
        .equals(["", opts.status]);
    } else if (inboxName.startsWith("assigned-")) {
      const assigneeId = inboxName.split("assigned-")[1];
      conversationCollection = idb.conversations
        .where("[assignee+status]")
        .equals([`wm:${assigneeId}`, opts.status]);
    } else if (inboxName.startsWith("team-")) {
      const assigneeId = inboxName.split("team-")[1];
      conversationCollection = idb.conversations
        .where("[assignee+status]")
        .equals([`t:${assigneeId}`, opts.status]);
    } else if (inboxName.startsWith("sequence-")) {
      const sequenceId = inboxName.split("sequence-")[1];
      conversationCollection = idb.conversations
        .where("[assignee+status]")
        .equals([`b:${sequenceId}`, opts.status]);
    } else if (inboxName === "pendingResponse") {
      conversationCollection = getPendingResponseIDBCollection(idb, appData);
    }

    if (conversationCollection) {
      if (opts.order === "newest_first") {
        return await conversationCollection
          .reverse()
          .sortBy("data.lastMessageAt");
      } else {
        return await conversationCollection.sortBy("data.lastMessageAt");
      }
    }
  }, [
    appData.permissions,
    appData.workspaceMemberSelf,
    inboxName,
    opts.order,
    opts.status,
  ]);

  return {
    conversations: conversations ?? [],
  };
}
