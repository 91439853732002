import React from "react";
import { useAppData } from "components/AppData";
import firebase from "firebase";
import ProfilePic from "components/ProfilePic";
import styled from "styled-components";
import Box from "ui/Box";
import Icon from "ui/Icon";
import Text from "ui/Text";
import {
  BorderlessInput,
  CommandBarFooter,
  CommandBarHeader,
  MenuItem,
} from "./common";
import { AccountAssignee, Conversation } from "common/src/types";
import { assignConversationToMember } from "lib/conversations";
import { debounce } from "lodash";
import Checkbox from "ui/Checkbox";
import Button from "ui/Button";
import { useIDB } from "lib/idb";

const AssignConversationCommandBarWindowWrapper = styled.div`
  min-height: 320px;
  display: flex;
  flex-direction: column;
`;

export const AssigneePicker = ({
  assignee,
  multiple,
  onAssigneePicked,
}: {
  assignee: AccountAssignee | undefined;
  multiple?: boolean;
  onAssigneePicked: (assignee: string[]) => Promise<void>;
}) => {
  const appData = useAppData();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = React.useState("");

  let currentAssignees: string[] = React.useMemo(() => {
    if (!assignee) return [];

    if (typeof assignee === "string") return [assignee];

    if (assignee.type === "simple") return [assignee.assignee];

    return assignee.assignees;
  }, [assignee]);

  const [selectedAssignees, setSelectedAssignees] =
    React.useState<string[]>(currentAssignees);

  const updateSearchQueryDebounced = React.useMemo(
    () => debounce(setDebouncedSearchQuery, 500),
    [setDebouncedSearchQuery]
  );

  const members = React.useMemo(() => {
    const workspaceMembers = Object.values(appData.workspaceMembers);
    if (debouncedSearchQuery) {
      const foundMembers = workspaceMembers.filter((member) =>
        member
          .data()
          .name.toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase())
      );

      return foundMembers;
    }

    return workspaceMembers;
  }, [appData.workspaceMembers, debouncedSearchQuery]);

  const teams = React.useMemo(() => {
    const teamsList = Object.values(appData.teams);
    if (debouncedSearchQuery) {
      const foundTeams = teamsList.filter((team) =>
        team
          .data()
          .name.toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase())
      );

      return foundTeams;
    }

    return teamsList;
  }, [appData.teams, debouncedSearchQuery]);

  const sequences = React.useMemo(() => {
    const messagingSequences = Object.values(
      appData.automatedMessagingSequences
    );
    if (debouncedSearchQuery) {
      const foundMessagingSequences = messagingSequences.filter((sequence) =>
        sequence
          .data()
          .name.toLowerCase()
          .includes(debouncedSearchQuery.toLowerCase())
      );
      return foundMessagingSequences;
    }

    return messagingSequences;
  }, [appData.automatedMessagingSequences, debouncedSearchQuery]);

  React.useEffect(() => {
    updateSearchQueryDebounced(searchQuery);
  }, [searchQuery]);

  const handleAssigneeSelection = (assigneeId: string) => {
    if (!multiple) {
      onAssigneePicked([assigneeId]);
    }

    setSelectedAssignees((prevSelectedAssignees) => {
      let newSelectedAssignees = prevSelectedAssignees;

      if (multiple) {
        newSelectedAssignees = newSelectedAssignees.filter(
          (selectedAssignee) =>
            !selectedAssignee.startsWith("t:") &&
            !selectedAssignee.startsWith("b:")
        );
      }

      if (newSelectedAssignees.includes(assigneeId)) {
        return newSelectedAssignees.filter(
          (selectedAssignee) => selectedAssignee !== assigneeId
        );
      }

      return newSelectedAssignees.concat(assigneeId);
    });
  };

  const handleSaveAssigneeSelection = () => {
    onAssigneePicked(selectedAssignees);
  };

  return (
    <AssignConversationCommandBarWindowWrapper>
      <CommandBarHeader>
        <Icon icon="search" />
        <BorderlessInput
          placeholder="Buscar"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </CommandBarHeader>

      <Box style={{ overflowY: "scroll", maxHeight: "70vh" }}>
        {members.map((workspaceMemberDoc) => {
          const workspaceMember = workspaceMemberDoc.data();

          if (assignee === `wm:${workspaceMemberDoc.id}` && !multiple) {
            return null;
          }

          return (
            <MenuItem
              onClick={() =>
                handleAssigneeSelection(`wm:${workspaceMemberDoc.id}`)
              }
              key={workspaceMember.userId}
            >
              <Text
                size="text_sm"
                weight="medium"
                flex
                alignItems="center"
                style={{ gap: 8 }}
              >
                {multiple && (
                  <Checkbox
                    checked={selectedAssignees.includes(
                      `wm:${workspaceMemberDoc.id}`
                    )}
                    onChange={() => {}}
                  />
                )}
                <Box flex alignItems="center">
                  <span>{"Asignar a ->"}</span>
                  <Box marginLeft="0.5rem" marginRight="0.5rem">
                    <ProfilePic
                      size="xxs"
                      name={workspaceMember.name}
                      imageLocation={undefined}
                      id={workspaceMemberDoc.id}
                      showStatusIcon
                      status={workspaceMember.presence}
                    />
                  </Box>
                  <span>{workspaceMember.name}</span>
                </Box>
              </Text>
            </MenuItem>
          );
        })}
        {!multiple &&
          teams.map((teamDoc) => {
            if (assignee === `t:${teamDoc.id}` && !multiple) {
              return null;
            }

            return (
              <MenuItem
                onClick={() => handleAssigneeSelection(`t:${teamDoc.id}`)}
                key={teamDoc.id}
              >
                <Text
                  size="text_sm"
                  weight="medium"
                  flex
                  alignItems="center"
                  style={{ gap: 8 }}
                >
                  {multiple && (
                    <Checkbox
                      checked={selectedAssignees.includes(`t:${teamDoc.id}`)}
                      onChange={() => {}}
                    />
                  )}

                  <Box flex alignItems="center">
                    <span>{"Asignar a ->"}</span>
                    <Box marginLeft="0.5rem" marginRight="0.5rem">
                      {teamDoc.data().emoji}
                    </Box>
                    <span>{teamDoc.data().name}</span>
                  </Box>
                </Text>
              </MenuItem>
            );
          })}
        {!multiple &&
          sequences.map((sequenceDoc) => {
            if (assignee === `b:${sequenceDoc.id}` && !multiple) {
              return null;
            }

            return (
              <MenuItem
                onClick={() => handleAssigneeSelection(`b:${sequenceDoc.id}`)}
                key={sequenceDoc.id}
              >
                <Text
                  size="text_sm"
                  weight="medium"
                  flex
                  alignItems="center"
                  style={{ gap: 8 }}
                >
                  {multiple && (
                    <Checkbox
                      checked={selectedAssignees.includes(
                        `b:${sequenceDoc.id}`
                      )}
                      onChange={() => {}}
                    />
                  )}
                  <Box flex alignItems="center">
                    <span>{"Asignar a ->"}</span>
                    <Box marginLeft="0.5rem" marginRight="0.5rem">
                      {sequenceDoc.data().emoji}
                    </Box>
                    <span>{sequenceDoc.data().name}</span>
                  </Box>
                </Text>
              </MenuItem>
            );
          })}
        {assignee !== "" && !multiple && (
          <MenuItem onClick={() => onAssigneePicked([""])}>
            <Text size="text_sm" weight="medium" flex alignItems="center">
              <span>Quitar asignado</span>
            </Text>
          </MenuItem>
        )}
      </Box>

      {multiple && (
        <CommandBarFooter
          style={{
            marginTop: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={handleSaveAssigneeSelection}
            disabled={!selectedAssignees.length}
            style={{ padding: "10px 16px" }}
          >
            Asignar
          </Button>
        </CommandBarFooter>
      )}
    </AssignConversationCommandBarWindowWrapper>
  );
};

const AssignConversationsCommandWindow = ({
  conversation,
  onConversationAssigned,
}: {
  conversation: {
    id: string;
    data: Conversation;
  };
  onConversationAssigned: () => void;
}) => {
  const appData = useAppData();
  const idb = useIDB();

  const assignConversation = async (newMemberId: string[]) => {
    newMemberId.forEach((newMember) =>
      assignConversationToMember({
        idb,
        conversationId: conversation.id,
        authorId: appData.workspaceMemberSelf.id,
        assigneeId: newMember,
        contactId: conversationData.contactId,
      })
    );

    onConversationAssigned();
  };

  const conversationData = conversation.data;

  return (
    <AssigneePicker
      assignee={conversationData.assignee}
      onAssigneePicked={assignConversation}
    />
  );

  // return (
  //   <AssignConversationCommandBarWindowWrapper>
  //     <CommandBarHeader>
  //       <Icon icon="search" />
  //       <BorderlessInput placeholder="Buscar" />
  //     </CommandBarHeader>

  //     <Box>
  //       {Object.values(appData.workspaceMembers).map((workspaceMemberDoc) => {
  //         const workspaceMember = workspaceMemberDoc.data();

  //         if (conversationData.assignee === `wm:${workspaceMemberDoc.id}`) {
  //           return null;
  //         }

  //         return (
  //           <MenuItem
  //             onClick={() => assignConversation(workspaceMemberDoc.id)}
  //             key={workspaceMember.userId}
  //           >
  //             <Text size="text_sm" weight="medium" flex alignItems="center">
  //               <span>{"Asignar a ->"}</span>
  //               <Box marginLeft="0.5rem" marginRight="0.5rem">
  //                 <ProfilePic
  //                   size="xxs"
  //                   name={workspaceMember.name}
  //                   imageLocation={undefined}
  //                   id={workspaceMemberDoc.id}
  //                 />
  //               </Box>
  //               <span>{workspaceMember.name}</span>
  //             </Text>
  //           </MenuItem>
  //         );
  //       })}
  //       {conversationData.assignee !== "" && (
  //         <MenuItem onClick={() => assignConversation("")}>
  //           <Text size="text_sm" weight="medium" flex alignItems="center">
  //             <span>Quitar asignado</span>
  //           </Text>
  //         </MenuItem>
  //       )}
  //     </Box>
  //   </AssignConversationCommandBarWindowWrapper>
  // );
};

export default AssignConversationsCommandWindow;
