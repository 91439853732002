import React, { ButtonHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import Icon, { IconKey, IconProps } from "./Icon";

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconKey;
  kind?: "primary-dark" | "transparent" | "secondary";
  size?: "sm" | "md";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  iconStroke?: IconProps["stroke"];
}

const Wrapper = styled.button<{
  kind: IconButtonProps["kind"];
  size: IconButtonProps["size"];
}>`
  padding: 0.25rem;
  all: unset;
  border-radius: 6px;
  cursor: pointer;
  transition-duration: 200ms;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }

  ${(props) =>
    props.size === "md" &&
    css`
      padding: 10px;
    `}

  ${(props) =>
    props.size === "sm" &&
    css`
      padding: 8px;
    `}

  ${(props) =>
    props.kind === "primary-dark" &&
    css`
      color: white;
      background-color: ${(props) => props.theme.colors.gray800};

      &:hover {
        background-color: ${(props) => props.theme.colors.gray700};
      }

      &:enabled:active {
        background-color: ${(props) => props.theme.colors.gray800};
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05),
          0px 0px 0px 4px ${(props) => props.theme.colors.gray100};
      }

      &:disabled {
        background-color: ${(props) => props.theme.colors.gray300};
        cursor: not-allowed;
      }
    `}

  ${(props) =>
    props.kind === "transparent" &&
    css`
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    `}

  ${(props) =>
    props.kind === "secondary" &&
    css`
      background-color: transparent;
      border: 1px solid ${props.theme.colors.gray300};
    `}
`;

const IconButton = ({
  icon,
  onClick,
  kind,
  size,
  iconStroke,
  ...restProps
}: IconButtonProps) => {
  let iconSize = undefined;

  if (size === "md" || size === "sm") {
    iconSize = 20;
  }

  return (
    <Wrapper size={size} kind={kind} onClick={onClick} {...restProps}>
      <Icon icon={icon} size={iconSize} stroke={iconStroke} />
    </Wrapper>
  );
};

export default IconButton;
