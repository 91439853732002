import React from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function StripeCheckoutSuccess() {
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    (window as any).fbq?.("track", "StartTrial");

    const searchParams = new URLSearchParams(location.search);

    let redirectPath = "/";

    if (searchParams) {
      const redirectUri = searchParams.get("redirect_uri");
      if (redirectUri) {
        redirectPath = redirectUri;
      }
    }
    history.push(redirectPath);
  }, [history, location.search]);

  return null;
}
